"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _transactionSubMenu = require("./subMenus/transaction-sub-menu");
var _ledgerSubMenu = require("./subMenus/ledger-sub-menu");
const accountManagementRouter = {
  path: '/account',
  component: _index.default,
  redirect: 'noredirect',
  name: 'accountManagement.title',
  meta: {
    roles: ['Super Admin', 'Admin', 'Agent', 'Read Only', 'Account Manager', 'Invoice Manager', 'Sales Manager'],
    title: 'accountManagement.title',
    icon: 'shopping'
  },
  children: [..._transactionSubMenu.transactionsSubMenu, ..._ledgerSubMenu.ledgersSubMenu
  // ...ordersTicketsSubMenu,
  // ...couponsSubMenu
  ]
};
var _default = exports.default = accountManagementRouter;