"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _ordersDispatches = require("@/api/orders-dispatches");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _router = _interopRequireDefault(require("@/router"));
var _admin = require("@/store/modules/admin");
var _dealersTransporters = require("@/api/dealers-transporters");
var _utils = require("@/utils");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.formData = Object.assign({}, _ordersDispatches.defaultOrdersDispatchData);
    this.loading = false;
    this.rules = {
      transporterId: {
        required: true,
        trigger: 'blur',
        message: 'This field is required'
      }
    };
    this.loggedInAdmin = null;
    this.ordersDispatchId = 0;
    this.transporterListLoading = false;
    this.transporterList = [];
    this.transporterId = null;
    this.dealerId = 0;
    this.ewayUpdateFromData = {};
  }
  get lang() {
    return _app.AppModule.language;
  }
  activated() {
    this.loggedInAdmin = _admin.AdminModule;
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.ordersDispatchId = +id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, _ordersDispatches.defaultOrdersDispatchData);
    }
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _ordersDispatches.getOrdersDispathById)(id);
      this.formData = data;
      this.dealerId = data.dealerId;
      // this.ewayUpdateFromData.transporterId = data.dealersTransporter.transporter.id;
      if (this.dealerId) {
        await this.fetchTransporterList(this.dealerId);
      }
      // Just for test
      const title = 'Update E-way - #' + this.formData.id;
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {} // eslint-disable-line  no-empty
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title}`;
  }
  submitForm() {
    this.$refs.ewayUpdateFromData.validate(valid => {
      if (valid) {
        this.updateEWay();
      } else {
        return false;
      }
    });
  }
  async saveForm() {
    try {
      this.loading = true;
      // format date
      this.formData.lrDate = this.$moment(this.formData.lrDate).format('YYYY-MM-DD');
      let response = {
        status: 'info',
        message: 'Try again!'
      };
      if (this.isEdit) {
        const dataToUpdate = {
          lrNumber: this.formData.lrNumber,
          lrDate: this.formData.lrDate
        };
        response = await (0, _ordersDispatches.updateOrdersDispath)(this.formData.id, dataToUpdate);
      }
      this.loading = false;
      this.$notify({
        title: response.status,
        message: response.message,
        type: response.status,
        duration: 2000
      });
      _router.default.push('/orders-dispatches/list');
    } catch (err) {
      this.loading = false;
    }
  }
  async fetchTransporterList(dealerId = 0) {
    // fetch dealers transporters
    this.transporterList = await (0, _dealersTransporters.getDealersTransporters)((0, _utils.getQuery)({
      enabled: true,
      dealerId,
      sort: 'isPrimary,ASC',
      filter: {
        enabled: '$eq',
        dealerId: '$eq'
      }
    }));
  }
  async updateEWay() {
    // update E-way
    try {
      this.loading = true;
      this.$confirm('Do you want to update E-way?', 'Update E-way', {
        confirmButtonText: 'Yes',
        confirmButtonClass: 'el-button--success',
        cancelButtonText: 'No',
        cancelButtonClass: 'el-button--danger',
        type: 'warning'
      }).then(async () => {
        const updateEWayRequest = {
          id: this.ordersDispatchId,
          transporterId: this.ewayUpdateFromData.transporterId
        };
        const updateEWayResponse = await (0, _ordersDispatches.updateEway)(updateEWayRequest);
        if (updateEWayResponse && updateEWayResponse.status === 'success') {
          this.$message({
            message: 'E-way updated successfully. Kindly upload the updated the E-way PDF.',
            type: 'success'
          });
          _router.default.push('/orders-dispatches/upload-eway-pdf/' + this.ordersDispatchId);
        } else {
          this.$message({
            message: 'Failed to update E-way',
            type: 'error'
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Request Cancel'
        });
      });
      this.loading = false;
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'OrdersDispatchDetail',
  components: {}
})], default_1);
var _default = exports.default = default_1;