"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _dealers = require("@/api/dealers");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _dealersRoles = require("@/api/dealers-roles");
var _agents = require("@/api/agents");
var _utils = require("@/utils");
var _index = _interopRequireDefault(require("@/components/FileUpload/index.vue"));
var _admin = require("@/store/modules/admin");
var _branches = require("@/api/locations/branches");
var _schemes = require("@/api/schemes");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.formData = Object.assign({}, _dealers.defaultDealerData);
    this.loading = false;
    this.roleList = [];
    this.agentList = [];
    this.branchList = [];
    this.schemesList = [];
    this.selectedSchemes = [];
    this.logo = [];
    this.loggedInAdmin = null;
    this.isSuperAdmin = false;
    this.rules = {
      name: [{
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      }, {
        min: 3,
        max: 150,
        message: 'Length should be 3 to 150 characters',
        trigger: 'blur'
      }],
      dealersRoleId: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      },
      code: [{
        min: 2,
        max: 25,
        message: 'Length should be 2 to 25 characters',
        trigger: ['blur']
      }],
      holdReason: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      },
      privateMarka: [{
        max: 20,
        message: 'Maximum allowed length is 20',
        trigger: 'blur'
      }]
    };
  }
  get lang() {
    return _app.AppModule.language;
  }
  activated() {
    if (_admin.AdminModule.roles.includes('Read Only')) {
      this.isSuperAdmin = true;
    }
    this.loggedInAdmin = _admin.AdminModule;
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, _dealers.defaultDealerData);
    }
    // fetch dealer roles
    this.fetchDealersRoles();
    // fetch agents
    this.fetchAgents();
    // fetch branch
    this.fetchBranches();
    this.fetchSchemes();
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      this.logo = [];
      const data = await (0, _dealers.getDealerById)(id);
      this.formData = data;
      // set logo
      this.logo.push({
        name: data.name,
        url: data.logo
      });
      if (this.formData.scheme && this.formData.scheme.length) {
        this.selectedSchemes = this.formData.scheme.map(scheme => scheme.id);
        const schemesList = [];
        this.formData.scheme.forEach(value => {
          schemesList.push({
            id: value.id,
            name: value.name
          });
        });
        this.schemesList = schemesList;
      }
      // Just for test
      const title = this.formData.name;
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async fetchDealersRoles() {
    this.roleList = await (0, _dealersRoles.getDealersRoles)((0, _utils.getQuery)({
      enabled: true,
      sort: 'name,ASC',
      filter: {
        enabled: 'eq'
      }
    }));
  }
  async fetchAgents() {
    this.agentList = await (0, _agents.getAgents)((0, _utils.getQuery)({
      enabled: true,
      sort: 'name,ASC',
      filter: {
        enabled: 'eq'
      }
    }));
  }
  async fetchBranches() {
    this.branchList = await (0, _branches.getBranches)((0, _utils.getQuery)({
      enabled: true,
      sort: 'name,ASC',
      filter: {
        enabled: 'eq'
      }
    }));
  }
  async fetchSchemes() {
    this.schemesList = await (0, _schemes.getSchemes)((0, _utils.getQuery)({
      enabled: true,
      sort: 'name,ASC',
      filter: {
        enabled: 'eq'
      }
    }));
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  fileUploaded(file) {
    this.formData.logo = file.response;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      this.formData.agent = {
        id: this.formData.agentId
      };
      this.formData.dealersRole = {
        id: this.formData.dealersRoleId
      };
      this.formData.branch = {
        id: this.formData.branchId
      };
      this.formData.holdBy = this.loggedInAdmin.id;
      this.formData.scheme = [];
      if (this.selectedSchemes.length > 0) {
        this.selectedSchemes.forEach(value => {
          this.formData.scheme.push({
            id: value
          });
        });
      }
      if (this.isEdit) {
        await (0, _dealers.updateDealer)(this.formData.id, this.formData);
      } else {
        await (0, _dealers.createDealer)(this.formData);
      }
      this.loading = false;
      this.$notify({
        title: 'Success',
        message: 'Dealer saved successfully',
        type: 'success',
        duration: 2000
      });
      _router.default.push('/dealers/list');
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'DealerDetail',
  components: {
    FileUpload: _index.default
  }
})], default_1);
var _default = exports.default = default_1;