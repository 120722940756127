"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _admin = require("@/store/modules/admin");
var _validate = require("@/utils/validate");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.validateMobileNumber = (rule, value, callback) => {
      if (!(0, _validate.isValidMobile)(value)) {
        callback(new Error("Please enter valid mobile number"));
      } else {
        callback();
      }
    };
    this.loginForm = {
      mobileNumber: ""
    };
    this.loginRules = {
      mobileNumber: [{
        validator: this.validateMobileNumber,
        trigger: "blur"
      }]
    };
    this.loading = false;
  }
  requestOtp() {
    this.$refs.loginForm.validate(async valid => {
      if (valid) {
        this.loading = true;
        try {
          // Call the API to request OTP
          const response = await _admin.AdminModule.requestOtp(this.loginForm.mobileNumber);
          const {
            hash,
            mobileNumber,
            otpSent
          } = response.otp;
          console.log("response", response.otp);
          if (otpSent) {
            console.log("inside");
            this.$router.push({
              path: "/admin-verify-otp",
              query: {
                hash,
                mobileNumber
              }
            });
          } else {
            return false;
          }
        } catch (error) {
          this.loading = false;
        }
      } else {
        return false;
      }
    });
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "Login"
})], default_1);
var _default = exports.default = default_1;