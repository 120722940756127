"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.reduce.js");
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _ordersDispatches = require("@/api/orders-dispatches");
var _ordersProductsDispatches = require("@/api/orders-products-dispatches");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _index3 = _interopRequireDefault(require("@/components/Inr/index.vue"));
var _DealerDetails = _interopRequireDefault(require("./components/DealerDetails.vue"));
var _dealers = require("@/api/dealers");
var _admin = require("@/store/modules/admin");
var _states = require("@/api/locations/states");
var _cities = require("@/api/locations/cities");
var _index4 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _products = require("@/api/products");
var _agents = require("@/api/agents");
var _ranges = require("@/api/ranges");
var _categories = require("@/api/categories");
var _branches = require("@/api/locations/branches");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.isReadOnly = false;
    this.baseUrl = process.env.VUE_APP_BASE_API;
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = false;
    this.downloadLoading = false;
    this.dateRange = '';
    this.dialogVisible = false;
    this.isAgent = false;
    this.dispatchId = 0;
    this.unitManagers = [];
    this.unitList = [];
    this.dealerList = [];
    this.agentList = [];
    this.branchListLoading = false;
    this.branchList = [];
    this.stateList = [];
    this.cityList = [];
    this.rangeList = [];
    this.categoryList = [];
    this.subCategoryList = [];
    this.categoryId = null;
    this.subCategoryId = null;
    this.products = [];
    this.selectedVariationId = null;
    this.dealerListLoading = false;
    this.stateListLoading = false;
    this.cityListLoading = false;
    this.productListLoading = false;
    this.selectedProduct = null;
    this.statusOptions = ['Pending', 'Request Approval', 'Approved', 'Rejected'];
    this.statusColorMap = {
      Pending: 'warning',
      'Request Approval': 'primary',
      Approved: 'success',
      Rejected: 'danger'
    };
    this.listQuery = {
      page: 1,
      limit: 10,
      dealerId: undefined,
      selectedVariationId: undefined,
      adminId: undefined,
      agentId: undefined,
      productId: undefined,
      productName: undefined,
      categoryId: undefined,
      subCategoryId: undefined,
      productVariationId: undefined,
      cityId: undefined,
      stateId: undefined,
      status: 'Approved',
      startDate: undefined,
      endDate: undefined,
      branchId: undefined,
      sort: 'id,ASC'
    };
    this.sortOptions = [{
      label: 'ID Ascending',
      key: 'id,ASC'
    }, {
      label: 'ID Descending',
      key: 'id,DESC'
    }];
    this.loggedInAdmin = null;
  }
  activated() {
    if (_admin.AdminModule.roles.includes('Read Only')) {
      this.isReadOnly = true;
    }
    this.loggedInAdmin = _admin.AdminModule;
    // check if logged in user is Agent/Admin
    if (this.loggedInAdmin.userType === 'Agent') {
      this.isAgent = true;
      this.listQuery.agentId = this.loggedInAdmin.agentId;
    }
    if (!this.dateRange) {
      this.getCurrentFinancialYear();
    }
    this.listQuery = {
      ...this.listQuery,
      ...this.$route.query
    };
    // get dispatch list
    this.getList();
    this.fetchAgentList();
    this.fetchBranches();
    this.fetchRanges();
    this.getStateList();
  }
  async getList() {
    try {
      if (this.dateRange) {
        this.listQuery.startDate = this.dateRange.start;
        this.listQuery.endDate = this.dateRange.end;
      }
      this.listQuery.categoryId = undefined;
      if (this.categoryId) {
        this.listQuery.categoryId = this.categoryId;
      }
      if (this.subCategoryId) {
        this.listQuery.subCategoryId = this.subCategoryId;
      }
      const data = await (0, _ordersProductsDispatches.getNewItemWiseReport)((0, _index2.getQuery)(this.listQuery));
      console.log(data);
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async fetchBranches() {
    this.branchListLoading = true;
    this.branchList = await (0, _branches.getBranches)((0, _index2.getQuery)({
      enabled: true,
      sort: 'name,ASC',
      filter: {
        enabled: 'eq'
      }
    }));
    this.branchListLoading = false;
  }
  async getCurrentFinancialYear() {
    const dateData = await (0, _ordersDispatches.getFinancialYear)();
    this.dateRange = {
      start: dateData.startDate,
      end: dateData.endDate
    };
    this.listQuery.startDate = this.dateRange.start;
    this.listQuery.endDate - this.dateRange.end;
    this.getList();
  }
  async getListWithoutPagination() {
    const queryWithoutPagination = {
      ...this.listQuery
    };
    delete queryWithoutPagination.page;
    delete queryWithoutPagination.limit;
    const data = await (0, _ordersProductsDispatches.getAllData)((0, _index2.getQuery)(queryWithoutPagination));
    return data;
  }
  formatDiscount(value) {
    return value === null ? 0 : value;
  }
  async getDealerList(query = null) {
    try {
      const isNumeric = /^[0-9]+$/.test(query);
      // search only if length is greater than 1 if query is id else 2
      if (!query || (isNumeric ? query.length < 1 : query.length < 2)) {
        this.dealerList = [];
        return;
      }
      const searchParams = {
        keyword: query,
        searchBy: isNumeric ? 'dealerId' : 'name',
        branchId: this.listQuery.branchId !== undefined ? this.listQuery.branchId : _admin.AdminModule.branchIds
      };
      this.dealerList = await (0, _dealers.searchDealers)(searchParams);
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async fetchAgentList() {
    try {
      this.agentList = await (0, _agents.getAgents)((0, _index2.getQuery)({
        enable: true,
        sort: 'name,ASC',
        filter: {
          enabled: '$eq'
        }
      }));
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getStateList(query = null) {
    try {
      this.stateList = await (0, _states.getStates)((0, _index2.getQuery)({
        enabled: true,
        sort: 'name,ASC',
        filter: {
          enabled: 'eq'
        }
      }));
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async getCityList(stateId = 0) {
    try {
      this.cityListLoading = true;
      this.cityList = await (0, _cities.getCities)((0, _index2.getQuery)({
        enabled: true,
        stateId,
        sort: 'name,ASC',
        filter: {
          enabled: '$eq',
          stateId: '$eq'
        }
      }));
      this.cityListLoading = false;
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async getProductList(query) {
    try {
      this.productListLoading = true;
      if (query && query.length > 2) {
        this.products = await (0, _products.searchProducts)((0, _index2.getQuery)({
          keyword: query
        }));
      }
      this.productListLoading = false;
    } catch (err) {
      this.productListLoading = false;
      throw err;
    }
  }
  handleCategoryFilter() {
    this.categoryId = undefined;
    this.subCategoryId = undefined;
    this.fetchCategories();
    this.getList();
  }
  handleSubCategoryFilter() {
    this.subCategoryId = undefined;
    if (this.categoryId) {
      this.fetchSubCategories();
    } else {
      this.categoryId = undefined;
    }
    this.getList();
  }
  async fetchRanges() {
    this.rangeList = await (0, _ranges.getRanges)((0, _index2.getQuery)({
      enabled: true,
      sort: 'name,ASC',
      filter: {
        enabled: '$eq'
      }
    }));
  }
  async fetchCategories() {
    if (this.listQuery.rangeId) {
      this.categoryList = await (0, _categories.getCategories)((0, _index2.getQuery)({
        enabled: true,
        parentId: true,
        rangeId: this.listQuery.rangeId,
        sort: 'name,ASC',
        filter: {
          enabled: '$eq',
          parentId: '$isnull',
          rangeId: '$eq'
        }
      }));
      if (this.categoryList.length === 0) {
        this.categoryId = undefined;
      }
    } else {
      this.categoryList = [];
    }
  }
  async fetchSubCategories() {
    if (this.categoryId) {
      this.subCategoryList = await (0, _categories.getCategories)((0, _index2.getQuery)({
        enabled: true,
        parentId: this.categoryId,
        rangeId: this.listQuery.rangeId,
        sort: 'name,ASC',
        filter: {
          enabled: '$eq',
          parentId: '$eq',
          rangeId: '$eq'
        }
      }));
      if (this.subCategoryList.length === 0) {
        this.subCategoryId = undefined;
      }
    } else {
      this.subCategoryList = [];
    }
  }
  handleProductSelection(value) {
    if (value) {
      if (value.startsWith('product-')) {
        this.listQuery.productId = +value.replace('product-', '');
        this.listQuery.productVariationId = undefined;
      } else if (value.startsWith('variation-')) {
        this.listQuery.productId = undefined;
        this.listQuery.productVariationId = +value.replace('variation-', '');
      }
    } else {
      // Clear the product filters when value is null or empty
      this.listQuery.productId = undefined;
      this.listQuery.productVariationId = undefined;
    }
    this.handleFilter();
  }
  handleFilter() {
    if (this.listQuery.stateId) {
      this.getCityList(this.listQuery.stateId);
    } else {
      // empty city
      this.cityList = [];
      this.listQuery.cityId = undefined;
      this.listQuery.stateId = undefined;
    }
    if (!this.listQuery.cityId) {
      this.listQuery.cityId = undefined;
    }
    if (this.dateRange && this.dateRange.start && this.dateRange.end) {
      this.listQuery.startDate = this.dateRange.start;
      this.listQuery.endDate = this.dateRange.end;
    } else {
      this.listQuery.startDate = undefined;
      this.listQuery.endDate = undefined;
    }
    this.listQuery.page = 1;
    this.getList();
  }
  toggleExpand(row) {
    const eltable = this.$refs.eltable;
    eltable.toggleRowExpansion(row);
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === 'ascending' ? 'ASC' : 'DESC');
      this.handleFilter();
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? 'ascending' : sort === `${key},DESC` ? 'descending' : '';
  }
  toggleDialog(id = 0) {
    this.dialogVisible = true;
    this.dispatchId = id;
  }
  async downloadInvoice(id = 0) {
    // get invoice url
    this.listLoading = true;
    const url = await (0, _ordersDispatches.generateInvoice)(id);
    if (url) {
      window.open(url, '_blank');
      // const filename = "invoice_" + id + ".pdf";
      // this.downloadFile(data.url, filename);
    } else {
      this.$message({
        message: 'Failed to generate invoice',
        type: 'error'
      });
    }
    this.listLoading = false;
  }
  async handleExcelDownload() {
    this.downloadLoading = false;
    try {
      const query = this.listQuery;
      let startDate = '';
      let endDate = '';
      if (this.listQuery.startDate && this.listQuery.endDate) {
        startDate = this.listQuery.startDate;
        endDate = this.listQuery.endDate;
      }
      // let datas: any = await this.getListWithoutPagination();
      // let data = datas.data;
      const blob = await (0, _ordersProductsDispatches.downloadExcelNewItemWise)({
        query,
        startDate,
        endDate
      });
      // Create a blob URL for the Excel file
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'item-wise-report.xlsx');
      document.body.appendChild(link);
      // Simulate click to trigger download
      link.click();
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading Excel:', error);
      this.$message.error('Error downloading Excel');
    } finally {
      // Reset loading state
      this.downloadLoading = false;
    }
  }
  getVisibleColumns() {
    const table = this.$refs.table;
    const visibleColumns = table.columns.filter(column => !column.hidden);
    return visibleColumns;
  }
  async handleCSVDownload() {
    this.downloadLoading = true;
    try {
      // Call the downloadCSV function to get the Blob object
      const blob = await (0, _ordersProductsDispatches.downloadCSV)(this.list);
      // Create URL for the Blob
      const url = window.URL.createObjectURL(blob);
      // Create download link
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'item-wise-report.csv');
      document.body.appendChild(link);
      // Simulate click to trigger download
      link.click();
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading CSV:', error);
      this.$message.error('Error downloading CSV');
    } finally {
      this.downloadLoading = false;
    }
  }
  getSummaries(param) {
    const {
      columns,
      data
    } = param;
    const sums = [];
    columns.forEach((column, index) => {
      // first column
      if (index === 0) {
        sums[index] = 'Total';
        return;
      }
      // Grand Total column
      if (index === 3) {
        // get all values
        const values = data.map(item => Number(item[column.property]));
        // loop over & null check
        if (!values.every(value => isNaN(value))) {
          // sum  them using reduce
          sums[index] = values.reduce((prev, curr) => {
            // value conversion (do check with Sandesh if toFixed something else required for decimal precision)
            const value = Number(curr);
            // null check
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0); // initial value
        } // loop if ends
      } // index check ends
    }); // columm loop ends
    // return sums
    if (sums && sums.length) {
      sums[3] = sums[3].toFixed(2);
    }
    return sums;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'NewItemWiseReport',
  components: {
    Pagination: _index.default,
    Inr: _index3.default,
    Enabled: _index4.default,
    DealerDetails: _DealerDetails.default
  }
})], default_1);
var _default = exports.default = default_1;