"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrdersProductsDispatch = exports.modifyOrdersProductsDispatch = exports.listOrdersProductsDispatches = exports.listByOrdersProductId = exports.getOrdersProductsDispatches = exports.getOrdersProductsDispatchByOrderId = exports.getOrdersProductsDispatchById = exports.getNewItemWiseReportRow = exports.getNewItemWiseReport = exports.getItemWiseReports = exports.getAllData = exports.getAllCartonData = exports.downloadExcelNewItemWise = exports.downloadExcel = exports.downloadCSV = exports.defaultOrdersProductsDispatchData = exports.createOrdersProductsDispatch = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultOrdersProductsDispatchData = exports.defaultOrdersProductsDispatchData = {
  id: 0,
  enabled: true,
  orderId: undefined,
  ordersDispatchId: undefined,
  quantity: 0,
  comments: null,
  isDisabled: undefined
};
const getOrdersProductsDispatches = params => (0, _request.default)({
  url: '/orders-products-dispatches',
  method: 'get',
  params
});
exports.getOrdersProductsDispatches = getOrdersProductsDispatches;
const createOrdersProductsDispatch = data => (0, _request.default)({
  url: '/orders-products-dispatches',
  method: 'post',
  data
});
exports.createOrdersProductsDispatch = createOrdersProductsDispatch;
const getOrdersProductsDispatchById = id => (0, _request.default)({
  url: `/orders-products-dispatches/${id}`,
  method: 'get'
});
exports.getOrdersProductsDispatchById = getOrdersProductsDispatchById;
const updateOrdersProductsDispatch = (id, data) => (0, _request.default)({
  url: `/orders-products-dispatches/${id}`,
  method: 'patch',
  data
});
exports.updateOrdersProductsDispatch = updateOrdersProductsDispatch;
const modifyOrdersProductsDispatch = (id, data) => (0, _request.default)({
  url: `/orders-products-dispatches/modify/${id}`,
  method: 'patch',
  data
});
exports.modifyOrdersProductsDispatch = modifyOrdersProductsDispatch;
const listOrdersProductsDispatches = ordersDispatchId => (0, _request.default)({
  url: `/orders-products-dispatches/list/${ordersDispatchId}`,
  method: 'get'
});
exports.listOrdersProductsDispatches = listOrdersProductsDispatches;
const getOrdersProductsDispatchByOrderId = orderId => (0, _request.default)({
  url: `/orders-products-dispatches/list-by-order/${orderId}`,
  method: 'get'
});
exports.getOrdersProductsDispatchByOrderId = getOrdersProductsDispatchByOrderId;
const listByOrdersProductId = ordersProductId => (0, _request.default)({
  url: `/orders-products-dispatches/list-by-product/${ordersProductId}`,
  method: 'get'
});
exports.listByOrdersProductId = listByOrdersProductId;
const getItemWiseReports = params => (0, _request.default)({
  url: '/orders-products-dispatches/item-wise-report',
  method: 'get',
  params
});
exports.getItemWiseReports = getItemWiseReports;
const getAllData = params => (0, _request.default)({
  url: '/orders-products-dispatches/item-wise-report/all',
  method: 'get',
  params
});
exports.getAllData = getAllData;
const getAllCartonData = params => (0, _request.default)({
  url: '/orders-products-dispatches/item-wise-report/carton-total',
  method: 'get',
  params
});
exports.getAllCartonData = getAllCartonData;
const downloadCSV = data => (0, _request.default)({
  url: '/orders-products-dispatches/download-csv',
  method: 'post',
  data,
  responseType: 'blob'
});
exports.downloadCSV = downloadCSV;
const getNewItemWiseReport = params => (0, _request.default)({
  url: '/reports/item-wise-report',
  method: 'get',
  params
});
exports.getNewItemWiseReport = getNewItemWiseReport;
const getNewItemWiseReportRow = params => (0, _request.default)({
  url: '/reports/item-wise-report-row',
  method: 'get',
  params
});
exports.getNewItemWiseReportRow = getNewItemWiseReportRow;
const downloadExcel = data => (0, _request.default)({
  url: '/orders-products-dispatches/download-excel',
  method: 'post',
  data,
  responseType: 'blob'
});
exports.downloadExcel = downloadExcel;
const downloadExcelNewItemWise = data => (0, _request.default)({
  url: '/reports/download-excel-item-wise',
  method: 'post',
  data,
  responseType: 'blob'
});
exports.downloadExcelNewItemWise = downloadExcelNewItemWise;