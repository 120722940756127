"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("el-select", {
    staticClass: "filter-item",
    attrs: {
      disabled: _vm.loggedInAdmin.adminsRoleIds.includes(3),
      filterable: "",
      clearable: "",
      placeholder: "Select Unit Manager"
    },
    on: {
      change: _vm.fetchUnitList
    },
    model: {
      value: _vm.listQuery.adminId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "adminId", $$v);
      },
      expression: "listQuery.adminId"
    }
  }, _vm._l(_vm.unitManagers, function (admin) {
    return _c("el-option", {
      key: admin.id,
      attrs: {
        label: admin.fullName,
        value: admin.id
      }
    });
  }), 1), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      clearable: "",
      filterable: "",
      disabled: !_vm.listQuery.adminId,
      placeholder: "Select Unit"
    },
    on: {
      change: _vm.getList
    },
    model: {
      value: _vm.listQuery.unitId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "unitId", $$v);
      },
      expression: "listQuery.unitId"
    }
  }, _vm._l(_vm.unitList, function (unit) {
    return _c("el-option", {
      key: unit.id,
      attrs: {
        label: unit.name,
        value: unit.id
      }
    }, [_vm._v(" " + _vm._s(unit.name) + " - " + _vm._s(unit.code) + " ")]);
  }), 1), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      placeholder: "Search Product",
      disabled: !_vm.listQuery.unitId,
      clearable: "",
      remote: "",
      "reserve-keyword": "",
      "remote-method": _vm.fetchProductList
    },
    on: {
      change: _vm.getList
    },
    model: {
      value: _vm.listQuery.productId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "productId", $$v);
      },
      expression: "listQuery.productId"
    }
  }, _vm._l(_vm.productList, function (product) {
    return _c("el-option", {
      key: product.id,
      attrs: {
        label: product.name,
        value: product.id
      }
    }, [_vm._v(" " + _vm._s(product.catalogueCode) + " - " + _vm._s(product.name) + " ")]);
  }), 1), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "info",
      size: "mini",
      icon: "el-icon-search"
    },
    on: {
      change: _vm.handleFilter
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")]), _c("router-link", {
    staticClass: "margin-horizontal",
    attrs: {
      to: "/inventory-inwards/add/"
    }
  }, [!_vm.isReadOnly ? _c("el-button", {
    staticClass: "filter-item",
    attrs: {
      type: "warning",
      size: "mini",
      icon: "el-icon-plus"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.add")) + " ")]) : _vm._e()], 1), !_vm.isReadOnly ? _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-document"
    },
    on: {
      click: _vm.downloadData
    }
  }, [_vm._v(" Download ")]) : _vm._e()], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "highlight-current-row": "",
      "span-method": _vm.doProductRowSpan
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Product",
      prop: "productId",
      width: "350"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(`${scope.row.product.catalogueCode} - ${scope.row.product.name}`) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "100",
      align: "center",
      label: "Color",
      prop: "productsVariationId"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.productsVariation.name) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "150",
      align: "center",
      label: "Stock",
      prop: "currentStock"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "100",
      align: "center",
      label: "Action"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [!_vm.isReadOnly ? _c("el-button", {
          attrs: {
            type: "primary",
            icon: "el-icon-remove",
            size: "mini"
          },
          on: {
            click: function ($event) {
              return _vm.toggleDialog(scope.row);
            }
          }
        }) : _vm._e()];
      }
    }])
  })], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  }), _c("el-dialog", {
    attrs: {
      title: "Inventory Remove",
      width: "80%",
      visible: _vm.dialogVisible,
      center: "",
      "destroy-on-close": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("inventory-remove", {
    key: _vm.inventoryInwardId,
    attrs: {
      data: _vm.inventoryInwardData,
      "dialog-visible": _vm.dialogVisible
    },
    on: {
      "form-submit": _vm.handleDialog
    }
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;