"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.reduce.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _ordersDispatches = require("@/api/orders-dispatches");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _dealers = require("@/api/dealers");
var _products = require("@/api/products");
var _ranges = require("@/api/ranges");
var _admin = require("@/store/modules/admin");
var _branchAgentService = require("@/services/branch-agent-service");
var _common = require("@/common");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.isReadOnly = false;
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.dealerList = [];
    this.dealerListLoading = false;
    this.productList = [];
    this.branchListLoading = false;
    this.branchList = [];
    this.productListLoading = false;
    this.rangeList = [];
    this.dateRange = null;
    this.grandTotalCartons = null;
    this.grandTotal = null;
    this.listQuery = {
      page: 1,
      limit: 10,
      dealerId: undefined,
      productId: undefined,
      rangeId: undefined,
      startDate: undefined,
      endDate: undefined,
      branchId: undefined
    };
  }
  async activated() {
    try {
      if (_admin.AdminModule.roles.includes("Read Only")) {
        this.isReadOnly = true;
      }
      // Assign branchId using BranchAgentService
      this.listQuery.branchId = await _branchAgentService.BranchAgentService.assignBranchId();
      // Check if branchId is null and throw an error
      if (this.listQuery.branchId === null) {
        throw new Error("You are not assigned to a branch.");
      }
      // Fetch branches for non-super admin users
      this.branchList = await _branchAgentService.BranchAgentService.fetchBranches(this.listQuery.branchId);
      this.getList();
    } catch (err) {
      if (err instanceof Error) {
        this.$notify.error({
          title: "Error",
          message: err.message
        });
      }
      this.listLoading = false;
    }
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _ordersDispatches.getPendingOrdersProducts)(this.listQuery);
      // get ranges
      this.rangeList = await (0, _ranges.getRanges)((0, _index2.getQuery)({
        enabled: true,
        sort: "name,ASC",
        filter: {
          enabled: "$eq"
        }
      }));
      // add cartons to the data
      const updatedData = data.data.map(item => {
        // Convert 'total' to a number and perform the division
        const cartons = (Number(item.total) / item.masterCarton).toFixed(2);
        // Return a new object with the 'cartons' field added
        return {
          ...item,
          cartons: cartons
        };
      });
      this.list = updatedData;
      this.total = data.total;
      this.grandTotalCartons = data.grandTotal.grandTotalCartons;
      this.grandTotal = data.grandTotal.grandTotal;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getDealerList(query = null) {
    try {
      if (query && query.length > 2) {
        this.dealerListLoading = true;
        this.dealerList = await (0, _dealers.getDealers)((0, _index2.getQuery)({
          name: query,
          branchId: this.listQuery.branchId !== undefined ? this.listQuery.branchId : _admin.AdminModule.branchIds,
          filter: {
            name: "$contL"
          }
        }));
      }
      this.dealerListLoading = false;
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async fetchProductList(query) {
    try {
      if (query.length > 2) {
        this.productListLoading = true;
        this.productList = await (0, _products.searchProducts)({
          keyword: query
        });
      }
      this.productListLoading = false;
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async handleFilter() {
    var _AdminModule$branchId;
    if (this.dateRange) {
      this.listQuery.startDate = this.dateRange.start;
      this.listQuery.endDate = this.dateRange.end;
    } else {
      this.listQuery.startDate = null;
      this.listQuery.endDate = null;
    }
    this.listQuery.page = 1;
    // handle branch and agent code
    // Fetch agents for a single branch
    if ((!(0, _common.isSuperAdmin)() || !(0, _common.isAdmin)()) && ((_AdminModule$branchId = _admin.AdminModule.branchIds) === null || _AdminModule$branchId === void 0 ? void 0 : _AdminModule$branchId.length) === 1) {
      this.listQuery.branchId = _admin.AdminModule.branchIds[0];
    }
    if (this.listQuery.branchId) {
      // Fetch agents and branches for non-superadmin/admin users
      await _branchAgentService.BranchAgentService.fetchBranches(this.listQuery.branchId);
      // Fetch the list after agents and branches are loaded
      this.getList();
    } else {
      this.getList();
    }
  }
  sortChange(data) {
    this.handleFilter();
  }
  handleDownload() {
    this.downloadLoading = true;
    const tHeader = ["id", "fullname", "email", "contact", "role"];
    const filterVal = ["id", "fullName"];
    const data = (0, _index2.formatJson)(filterVal, this.list);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc("route.userManagement.users.title"));
    this.downloadLoading = false;
  }
  async downloadData() {
    this.listLoading = true;
    try {
      const params = {};
      if (this.listQuery.dealerId) {
        params.dealerId = this.listQuery.dealerId;
      }
      if (this.listQuery.productId) {
        params.productId = this.listQuery.productId;
      }
      if (this.listQuery.rangeId) {
        params.rangeId = this.listQuery.rangeId;
      }
      if (this.dateRange) {
        params.startDate = this.dateRange.start;
        params.endDate = this.dateRange.end;
      }
      const data = await (0, _ordersDispatches.downloadPendingOrdersProducts)((0, _index2.getQuery)(params));
      window.open(data.url, "_blank");
      // if (Object.keys(params).length) {
      //   const data: any = await downloadPendingOrdersProducts(getQuery(params))
      //   window.open(data.url, '_blank')
      // } else {
      //   this.$message({
      //     message: 'Please select atleast one filter',
      //     type: 'warning'
      //   })
      // }
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  getSummaries(param) {
    const {
      columns,
      data
    } = param;
    const sums = [];
    const sumsCarton = [];
    const grandTotal = [];
    const totalCarton = [];
    columns.forEach((column, index) => {
      // if (index === 0) {
      //   sums[index] = "Total:  ";
      //   return;
      // }
      if (index === 2) {
        const values = data.map(item => Number(item[column.property]));
        // loop over & null check
        if (!values.every(value => isNaN(value))) {
          // sum them using reduce
          sums[index] = values.reduce((prev, curr) => {
            // value conversion (do check with Sandesh if toFixed something else required for decimal precision)
            const value = Number(curr);
            // null check
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0); // initial value
        } // loop if ends
      }
      if (index === 3) {
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sumsCarton[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        }
      }
    });
    if (sums && sums.length) {
      sumsCarton[3] = `${sumsCarton[3].toFixed(2)}/${this.grandTotalCartons.toFixed(2)}`;
      sums[2] = `${sums[2].toFixed(2)}/${this.grandTotal.toFixed(2)}`;
    }
    return [,, sums, sumsCarton];
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "PendingOrdersProductList",
  components: {
    Pagination: _index.default
  }
})], default_1);
var _default = exports.default = default_1;