"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findRoleCurrentlyLoggedIn = findRoleCurrentlyLoggedIn;
exports.isAccountManager = isAccountManager;
exports.isAdmin = isAdmin;
exports.isExcludedToAccess = isExcludedToAccess;
exports.isInvoiceManager = isInvoiceManager;
exports.isOrderManager = isOrderManager;
exports.isReadOnly = isReadOnly;
exports.isSuperAdmin = isSuperAdmin;
exports.isUnitManager = isUnitManager;
exports.isValidPhoneNumber = isValidPhoneNumber;
var _admin = require("@/store/modules/admin");
var _roles = require("./constants/roles.constants");
function isExcludedToAccess(requiredRoles) {
  // const requiredRoles = [3, 4]; // 3-> unit manager , 4-> Invoice manager
  return !requiredRoles.some(roleId => _admin.AdminModule.adminsRoleIds.includes(roleId));
}
function findRoleCurrentlyLoggedIn(requiredRoles) {
  return requiredRoles.some(roleId => _admin.AdminModule.adminsRoleIds.includes(roleId));
}
function isSuperAdmin() {
  return findRoleCurrentlyLoggedIn([_roles.SUPER_ADMIN]);
}
function isAdmin() {
  return findRoleCurrentlyLoggedIn([_roles.ADMIN]);
}
function isUnitManager() {
  return findRoleCurrentlyLoggedIn([_roles.UNIT_MANAGER]);
}
function isInvoiceManager() {
  return findRoleCurrentlyLoggedIn([_roles.INVOICE_MANAGER]);
}
function isReadOnly() {
  return findRoleCurrentlyLoggedIn([_roles.READ_ONLY]);
}
function isAccountManager() {
  return findRoleCurrentlyLoggedIn([_roles.ACCOUNT_MANAGER]);
}
function isOrderManager() {
  return findRoleCurrentlyLoggedIn([_roles.ORDER_MANAGER]);
}
function isValidPhoneNumber(phoneNumber) {
  const phoneRegex = /^[0-9]{10}$/;
  return phoneRegex.test(phoneNumber);
}