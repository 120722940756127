"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _schemesCategories = require("@/api/schemes-categories");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _admin = require("@/store/modules/admin");
var _utils = require("@/utils");
var _products = require("@/api/products");
var _schemes = require("@/api/schemes");
var _dealersRoles = require("@/api/dealers-roles");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.image = [];
    this.icon = [];
    this.schemes = [];
    this.dealersRoles = [];
    this.isReadOnly = false;
    this.formData = Object.assign({}, _schemesCategories.defaultSchemesCategoryData);
    this.products = [];
    this.selectedProducts = [];
    this.selectedDealersRole = "";
    this.loading = false;
    this.rules = {
      name: [{
        required: true,
        message: "This field is required",
        trigger: "blur"
      }, {
        min: 3,
        max: 150,
        message: "Length should be 3 to 150",
        trigger: "blur"
      }]
    };
  }
  get lang() {
    return _app.AppModule.language;
  }
  async activated() {
    if (_admin.AdminModule.roles.includes("Read Only")) {
      this.isReadOnly = true;
    }
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      await this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, _schemesCategories.defaultSchemesCategoryData);
    }
    await this.getSchemes();
    await this.getDealersRoles();
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _schemesCategories.getSchemesCategoryById)(id);
      this.formData = data;
      // check if products are saved
      if (this.formData.product && this.formData.product.length) {
        // Set selected products
        this.selectedProducts = this.formData.product.map(product => product.id);
        const productsList = [];
        this.formData.product.forEach(value => {
          productsList.push({
            id: value.id,
            name: value.name
          });
        });
        this.products = productsList;
      }
      if (this.formData.dealersRole && this.formData.dealersRole.length) {
        this.selectedDealersRole = this.formData.dealersRole.map(dealersRole => dealersRole.id);
        const dealersRoleList = [];
        this.formData.dealersRole.forEach(value => {
          dealersRoleList.push({
            id: value.id,
            name: value.name
          });
        });
        this.dealersRoles = dealersRoleList;
      }
      // Just for test
      const title = this.formData.name;
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {} // eslint-disable-line  no-empty
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  async getProducts(query = "") {
    try {
      if (query.length > 2) {
        this.products = await (0, _products.getProducts)((0, _utils.getQuery)({
          limit: 10,
          enabled: true,
          name: query,
          isGiftItem: false,
          filter: {
            enabled: "eq",
            name: "$contL",
            isGiftItem: "eq"
          }
        }));
      }
    } catch (error) {
      this.$notify({
        title: "Error",
        type: "error",
        message: error.toString()
      });
    }
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      if (this.isEdit) {
        await (0, _schemesCategories.updateSchemesCategory)(this.formData.id, this.formData);
      } else {
        await (0, _schemesCategories.createSchemesCategory)(this.formData);
      }
      this.loading = false;
      this.$notify({
        title: "Success",
        message: "Schemes Category saved",
        type: "success",
        duration: 2000
      });
      _router.default.push("/schemes-categories/list");
    } catch (err) {
      this.loading = false;
    }
  }
  handleProductChange(product) {
    // assign selected products to form data
    this.formData.product = [];
    // loop over selected products and push to form data
    this.selectedProducts.forEach(productId => {
      this.formData.product.push({
        id: productId
      });
    });
  }
  handleDealersRoleChange(dealersRoleId) {
    if (this.formData.dealersRole.length > 0) {
      this.formData.dealersRole.pop();
      this.formData.dealersRole.push({
        id: this.selectedDealersRole
      });
    } else {
      this.formData.dealersRole.push({
        id: this.selectedDealersRole
      });
    }
  }
  async getSchemes() {
    this.schemes = await (0, _schemes.getSchemes)((0, _utils.getQuery)({
      id: this.formData.schemeId || undefined,
      enabled: true,
      filter: {
        id: "eq",
        enabled: "eq"
      }
    }));
  }
  async getDealersRoles() {
    this.dealersRoles = await (0, _dealersRoles.getDealersRoles)((0, _utils.getQuery)({
      id: this.formData.dealersRoleId || undefined,
      enabled: true,
      filter: {
        id: "eq",
        enabled: "eq"
      }
    }));
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "SchemesCategoriesDetail",
  components: {}
})], default_1);
var _default = exports.default = default_1;