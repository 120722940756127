"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setToken = exports.setSize = exports.setSidebarStatus = exports.setLanguage = exports.removeToken = exports.getToken = exports.getSize = exports.getSidebarStatus = exports.getLanguage = void 0;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
// App
const sidebarStatusKey = 'sidebar_status';
const getSidebarStatus = () => _jsCookie.default.get(sidebarStatusKey);
exports.getSidebarStatus = getSidebarStatus;
const setSidebarStatus = sidebarStatus => _jsCookie.default.set(sidebarStatusKey, sidebarStatus);
exports.setSidebarStatus = setSidebarStatus;
const languageKey = 'language';
const getLanguage = () => _jsCookie.default.get(languageKey);
exports.getLanguage = getLanguage;
const setLanguage = language => _jsCookie.default.set(languageKey, language);
exports.setLanguage = setLanguage;
const sizeKey = 'size';
const getSize = () => _jsCookie.default.get(sizeKey);
exports.getSize = getSize;
const setSize = size => _jsCookie.default.set(sizeKey, size);
// User
exports.setSize = setSize;
const tokenKey = 'vue_typescript_admin_access_token';
const getToken = () => _jsCookie.default.get(tokenKey);
exports.getToken = getToken;
const setToken = token => _jsCookie.default.set(tokenKey, token);
exports.setToken = setToken;
const removeToken = () => _jsCookie.default.remove(tokenKey);
exports.removeToken = removeToken;