"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _agentsSubMenu = require("./subMenus/agents-sub-menu");
var _dealersRolesSubMenu = require("./subMenus/dealers-roles-sub-menu");
var _dealersUserSubMenu = require("./subMenus/dealers-user-sub-menu");
var _agentsUserSubMenu = require("./subMenus/agents-user-sub-menu");
var _dealersAddressSubMenu = require("./subMenus/dealers-address-sub-menu");
var _dealersTransporterSubMenu = require("./subMenus/dealers-transporter-sub-menu");
const dealerRouter = {
  path: '/dealers',
  component: _index.default,
  redirect: 'noredirect',
  name: 'dealerManagement.title',
  meta: {
    roles: ['Super Admin', 'Admin', 'Unit Manager', 'Invoice Manager', 'Agent', 'Read Only', 'Sales Manager', 'Order Manager'],
    title: 'dealerManagement.title',
    icon: 'peoples'
  },
  children: [{
    path: 'list',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/dealers/list.vue'))),
    name: 'dealerManagement.dealer.title',
    meta: {
      title: 'dealerManagement.dealer.title',
      noCache: true,
      icon: 'peoples'
    }
  }, {
    path: 'add',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/dealers/manage.vue'))),
    name: 'dealerManagement.dealer.add',
    props: {
      isEdit: false
    },
    meta: {
      roles: ['Super Admin', 'Admin'],
      title: 'dealerManagement.dealer.add',
      noCache: true,
      hidden: true,
      activeMenu: '/dealers/list'
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/dealers/manage.vue'))),
    name: 'dealerManagement.dealer.edit',
    props: {
      isEdit: true
    },
    meta: {
      roles: ['Super Admin', 'Admin'],
      title: 'dealerManagement.dealer.edit',
      noCache: true,
      hidden: true,
      activeMenu: '/dealers/list'
    }
  }, ..._dealersRolesSubMenu.dealersRolesSubMenu, ..._dealersAddressSubMenu.dealersAddressSubMenu, ..._dealersUserSubMenu.dealersUserSubMenu, ..._agentsSubMenu.agentsSubMenu, ..._agentsUserSubMenu.agentsUserSubMenu, ..._dealersTransporterSubMenu.dealersTransporterSubMenu]
};
var _default = exports.default = dealerRouter;