"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCategory = exports.getCategoryById = exports.getCategoriesTree = exports.getCategoriesList = exports.getCategories = exports.fileTransferHeaders = exports.deleteCategory = exports.defaultCategoryData = exports.createCategory = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultCategoryData = exports.defaultCategoryData = {
  id: 0,
  enabled: true,
  name: '',
  tallyAlias: '',
  icon: '',
  image: '',
  description: '',
  priority: 0,
  featured: true,
  parent: null,
  bgcolor: null,
  rangeId: null,
  range: {
    id: null
  }
};
const getCategories = params => (0, _request.default)({
  url: '/categories',
  method: 'get',
  params
});
exports.getCategories = getCategories;
const getCategoriesList = params => (0, _request.default)({
  url: '/categories/list',
  method: 'get',
  params
});
exports.getCategoriesList = getCategoriesList;
const getCategoriesTree = () => (0, _request.default)({
  url: '/categories/list/tree',
  method: 'get'
});
exports.getCategoriesTree = getCategoriesTree;
const getCategoryById = id => (0, _request.default)({
  url: `/categories/${id}`,
  method: 'get'
});
exports.getCategoryById = getCategoryById;
const updateCategory = (id, data) => (0, _request.default)({
  url: `/categories/${id}`,
  method: 'patch',
  data
});
exports.updateCategory = updateCategory;
const deleteCategory = id => (0, _request.default)({
  url: `/categories/${id}`,
  method: 'delete'
});
exports.deleteCategory = deleteCategory;
const createCategory = data => (0, _request.default)({
  url: '/categories',
  method: 'post',
  data
});
exports.createCategory = createCategory;