import { render, staticRenderFns } from "./OrderDispatchTab.vue?vue&type=template&id=6a99021b&scoped=true"
import script from "./OrderDispatchTab.vue?vue&type=script&lang=ts"
export * from "./OrderDispatchTab.vue?vue&type=script&lang=ts"
import style0 from "./OrderDispatchTab.vue?vue&type=style&index=0&id=6a99021b&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a99021b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\eiosys-work\\eiosys-frontend\\Cona-Admin\\Cona-Electrical-Admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6a99021b')) {
      api.createRecord('6a99021b', component.options)
    } else {
      api.reload('6a99021b', component.options)
    }
    module.hot.accept("./OrderDispatchTab.vue?vue&type=template&id=6a99021b&scoped=true", function () {
      api.rerender('6a99021b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/order/components/OrderDispatchTab.vue"
export default component.exports