"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      clearable: "",
      remote: "",
      loading: _vm.dealerListLoading,
      "loading-text": "fetching dealers..",
      "remote-method": _vm.getDealerList,
      placeholder: _vm.$t("dealersDispatch.dealer")
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.dealerId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "dealerId", $$v);
      },
      expression: "listQuery.dealerId"
    }
  }, _vm._l(_vm.dealerList, function (dealer) {
    return _c("el-option", {
      key: dealer.id,
      attrs: {
        label: dealer.name,
        value: dealer.id
      }
    }, [_c("span", {
      staticStyle: {
        float: "left",
        "font-size": "14px"
      }
    }, [_vm._v(_vm._s(dealer.name) + " - " + _vm._s(dealer.code))]), _c("span", {
      staticStyle: {
        float: "right",
        color: "#8492a6",
        "font-size": "14px"
      }
    }, [_vm._v("   | ID: " + _vm._s(dealer.id))])]);
  }), 1), _c("el-select", {
    attrs: {
      filterable: "",
      clearable: "",
      loading: _vm.branchListLoading,
      name: "branchId",
      placeholder: "Select Branch"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.branchId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "branchId", $$v);
      },
      expression: "listQuery.branchId"
    }
  }, _vm._l(_vm.branchList, function (branch) {
    return _c("el-option", {
      key: branch.id,
      attrs: {
        label: branch.code,
        value: branch.id
      }
    });
  }), 1), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      placeholder: "Search Product",
      loading: _vm.productListLoading,
      "loading-text": "fetching products..",
      clearable: "",
      remote: "",
      "reserve-keyword": "",
      "remote-method": _vm.fetchProductList
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.productId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "productId", $$v);
      },
      expression: "listQuery.productId"
    }
  }, _vm._l(_vm.productList, function (product) {
    return _c("el-option", {
      key: product.id,
      attrs: {
        label: product.name,
        value: product.id
      }
    }, [_vm._v(" " + _vm._s(product.catalogueCode) + " - " + _vm._s(product.name) + " ")]);
  }), 1), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      clearable: "",
      placeholder: "Range"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.rangeId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "rangeId", $$v);
      },
      expression: "listQuery.rangeId"
    }
  }, _vm._l(_vm.rangeList, function (range) {
    return _c("el-option", {
      key: range.id,
      attrs: {
        label: range.name,
        value: range.id
      }
    });
  }), 1), _c("br"), _c("VueCtkDateTimePicker", {
    staticClass: "filter-item",
    attrs: {
      range: "",
      formatted: "DD-MM-YYYY"
    },
    model: {
      value: _vm.dateRange,
      callback: function ($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  }), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")]), !_vm.isReadOnly ? _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-document"
    },
    on: {
      click: _vm.downloadData
    }
  }, [_vm._v(" Download ")]) : _vm._e()], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      "summary-method": _vm.getSummaries,
      "show-summary": "",
      border: "",
      fit: "",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "260",
      label: "Product",
      prop: "productName"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "160",
      align: "center",
      label: "Color",
      prop: "productsVariationName"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "120",
      align: "right",
      label: "Total",
      prop: "total"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "120",
      align: "right",
      label: "Carton(s)",
      prop: "cartons"
    }
  })], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;