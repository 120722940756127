"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _companies = require("@/api/companies");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _utils = require("@/utils");
var _pincode = require("@/api/pincode");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.formData = Object.assign({}, _companies.defaultCompanyData);
    this.loading = false;
    this.pincodeList = [];
    this.rules = {
      addressLine1: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      },
      pincodeId: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      },
      gstNumber: {
        pattern: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        message: 'Enter a valid GST number',
        trigger: 'blur'
      }
    };
  }
  get lang() {
    return _app.AppModule.language;
  }
  activated() {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, _companies.defaultCompanyData);
    }
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _companies.getCompanyById)(id);
      // fetch pincode
      await this.getPincodeById(data.pincodeId);
      this.formData = data;
      // Just for test
      const title = this.formData.name;
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getPincodeList(query) {
    try {
      if (query && query.length > 2) {
        this.pincodeList = await (0, _pincode.getPincodes)((0, _utils.getQuery)({
          pincode: query,
          filter: {
            pincode: '$starts'
          }
        }));
      }
    } catch (error) {
      this.$notify({
        title: 'Error',
        type: 'error',
        message: error.toString()
      });
    }
  }
  async getPincodeById(pincodeId) {
    try {
      if (pincodeId) {
        const data = await (0, _pincode.getPincodeById)(pincodeId);
        this.pincodeList = [data];
      }
    } catch (error) {
      this.$notify({
        title: 'Error',
        type: 'error',
        message: error.toString()
      });
    }
  }
  async updateLocation(pincodeId) {
    if (pincodeId) {
      const pincode = await (0, _pincode.getPincodeById)(pincodeId);
      this.formData.pincode = {
        id: pincode.id
      };
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      await this.updateLocation(this.formData.pincodeId);
      if (this.isEdit) {
        await (0, _companies.updateCompany)(this.formData.id, this.formData);
      } else {
        await (0, _companies.createCompany)(this.formData);
      }
      this.loading = false;
      this.$notify({
        title: 'Success',
        message: 'Address saved',
        type: 'success',
        duration: 2000
      });
      _router.default.push('/companies/list');
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'CompanyDetail',
  components: {}
})], default_1);
var _default = exports.default = default_1;