"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _vueCountTo = _interopRequireDefault(require("vue-count-to"));
var _dealers = require("@/api/dealers");
var _agents = require("@/api/agents");
var _products = require("@/api/products");
var _order = require("@/api/order");
var _ordersDispatches = require("@/api/orders-dispatches");
var _ordersTickets = require("@/api/orders-tickets");
var _index = require("@/utils/index");
var _admin = require("@/store/modules/admin");
var _dashboard = require("@/api/dashboard");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.supportTicketCount = [];
    this.orderStatus = [{
      status: 'Order Confirmed',
      color: 'blue',
      count: 0
    }, {
      status: 'Order Dispatched',
      color: 'info',
      count: 0
    }, {
      status: 'Partial Dispatch',
      color: 'info',
      count: 0
    }, {
      status: 'Order Delivered',
      color: 'success',
      count: 0
    }, {
      status: 'Order Cancelled',
      color: '',
      count: 0
    }];
    this.dispatchStatus = [{
      status: 'Pending',
      color: 'warning',
      count: 0
    }, {
      status: 'Request Approval',
      color: 'warning',
      count: 0
    }, {
      status: 'Approved',
      color: 'success',
      count: 0
    }, {
      status: 'Rejected',
      color: 'danger',
      count: 0
    }];
    this.dealerCount = 0;
    this.agentCount = 0;
    this.productscount = 0;
    this.orderscount = 0;
    this.agentId = 0;
    this.totalFySales = 0;
    this.totalFyPayment = 0;
    this.currentRecievableAmount = 0;
    this.totalPendingOrders = 0;
    this.currentMonthSales = 0;
    this.currentMonthPayments = 0;
    this.previousMonthSales = 0;
    this.previousMonthPayments = 0;
    this.dailyOrderValue = 0;
    this.isAgent = false;
  }
  created() {
    this.loadModules();
  }
  loadModules() {
    this.agentId = _admin.AdminModule.agentId;
    this.getDealerCount();
    this.getOrderStatusCount();
    this.getDispatchStatusCount();
    this.fetchTotalFySales();
    this.fetchTotalFyPayment();
    this.fetchCurrentRecievable();
    this.fetchTotalPendingOrders();
    this.fetchCurrentMonthSales();
    this.fetchCurrentMonthPayments();
    this.fetchPreviousMonthPayments();
    this.fetchPreviousMonthSales();
    this.fetchDailyOrderValue();
  }
  async getOrderStatusCount() {
    const data = await (0, _order.getStatusCount)({
      enabled: true,
      agentId: this.agentId
    });
    this.orderStatus.forEach(item => {
      item.count = data[item.status] * 1;
    });
  }
  async getDispatchStatusCount() {
    const data = await (0, _ordersDispatches.getDispatchCount)({
      enabled: true,
      agentId: this.agentId
    });
    this.dispatchStatus.forEach(item => {
      item.count = data[item.status] * 1;
    });
  }
  async getSupportTicketCount() {
    this.supportTicketCount = await (0, _ordersTickets.ordersTicketsCount)({});
  }
  async fetchTotalFySales() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await (0, _dashboard.totalFySales)(query);
    this.totalFySales = +data.sum;
  }
  async fetchTotalFyPayment() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await (0, _dashboard.totalFyPayment)(query);
    this.totalFyPayment = +data.totalPayments;
  }
  async fetchCurrentRecievable() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await (0, _dashboard.totalCurrentRecievable)(query);
    this.currentRecievableAmount = data;
  }
  async fetchTotalPendingOrders() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await (0, _dashboard.totalPendingOrders)(query);
    this.totalPendingOrders = +data;
  }
  async fetchCurrentMonthSales() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await (0, _dashboard.getCurrentMonthSales)(query);
    this.currentMonthSales = +data.sum;
  }
  async fetchCurrentMonthPayments() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await (0, _dashboard.getCurrentMonthPayments)(query);
    this.currentMonthPayments = +data.currentMonthPayments;
  }
  async fetchPreviousMonthSales() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await (0, _dashboard.getPreviousMonthSales)(query);
    this.previousMonthSales = +data.previousMonthSales;
  }
  async fetchPreviousMonthPayments() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await (0, _dashboard.getPreviousMonthPayments)(query);
    this.previousMonthPayments = +data.previousMonthPayments;
  }
  async fetchDailyOrderValue() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await (0, _dashboard.getDailyOrderValue)(query);
    this.dailyOrderValue = +data;
  }
  async getDealerCount() {
    try {
      this.dealerCount = await (0, _dealers.dealersCount)((0, _index.getQuery)({
        enabled: true,
        agentId: this.agentId
      }));
    } catch (error) {
      this.$notify({
        title: 'Error',
        type: 'error',
        message: error.toString()
      });
    }
  }
  async getProductCount() {
    try {
      this.productscount = await (0, _products.productCount)((0, _index.getQuery)({
        enabled: true,
        filter: {
          enabled: 'eq'
        }
      }));
    } catch (error) {
      this.$notify({
        title: 'Error',
        type: 'error',
        message: error.toString()
      });
    }
  }
  async getAgentCount() {
    this.agentCount = await (0, _agents.agentsCount)((0, _index.getQuery)({
      enabled: true,
      filter: {
        enabled: 'eq'
      }
    }));
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'PanelGroup',
  components: {
    CountTo: _vueCountTo.default
  }
})], default_1);
var _default = exports.default = default_1;