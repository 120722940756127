import "core-js/modules/es.error.cause.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import { dealerWiseOsOdReport, downloadOsOdExcel } from "@/api/orders-dispatches";
import Pagination from "@/components/Pagination/index.vue";
import { getQuery } from "@/utils/index";
import Inr from "@/components/Inr/index.vue";
import { searchDealers } from "@/api/dealers";
import { AdminModule } from "@/store/modules/admin";
import { getStates } from "@/api/locations/states";
import { getCities } from "@/api/locations/cities";
import Enabled from "@/components/Enabled/index.vue";
import { getBranches } from "@/api/locations/branches";
import { BranchAgentService } from "@/services/branch-agent-service";
import { isAdmin, isSuperAdmin } from "@/common";
let default_1 = class extends Vue {
  constructor() {
    super(...arguments);
    this.isReadOnly = false;
    this.baseUrl = process.env.VUE_APP_BASE_API;
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = false;
    this.downloadLoading = false;
    this.dateRange = "";
    this.dialogVisible = false;
    this.isAgent = false;
    this.dispatchId = 0;
    this.agentList = [];
    this.dealerList = [];
    this.stateList = [];
    this.cityList = [];
    this.branchListLoading = false;
    this.branchList = [];
    this.dealerListLoading = false;
    this.stateListLoading = false;
    this.cityListLoading = false;
    this.totalGrandTotal = "";
    this.statusColorMap = {
      Pending: "warning",
      "Request Approval": "primary",
      Approved: "success",
      Rejected: "danger"
    };
    this.listQuery = {
      page: 1,
      limit: 10,
      hsnNumber: undefined,
      startDate: undefined,
      endDate: undefined,
      branchId: undefined,
      sort: "id,ASC"
    };
    this.sortOptions = [{
      label: "ID Ascending",
      key: "id,ASC"
    }, {
      label: "ID Descending",
      key: "id,DESC"
    }];
    this.loggedInAdmin = null;
  }
  async activated() {
    try {
      if (AdminModule.roles.includes("Read Only")) {
        this.isReadOnly = true;
      }
      this.loggedInAdmin = AdminModule;
      // Assign branchId using BranchAgentService
      this.listQuery.branchId = await BranchAgentService.assignBranchId();
      // Check if branchId is null and throw an error
      if (this.listQuery.branchId === null) {
        throw new Error("You are not assigned to a branch.");
      }
      // Fetch agents for the assigned branchId
      this.agentList = await BranchAgentService.fetchAgents(this.listQuery.branchId);
      // Fetch branches for non-super admin users
      this.branchList = await BranchAgentService.fetchBranches(this.listQuery.branchId);
      this.listQuery = {
        ...this.listQuery,
        ...this.$route.query
      };
      // get dispatch list
      await this.getList();
      // check if logged in user is Agent/Admin
      if (this.loggedInAdmin.userType === "Agent") {
        this.isAgent = true;
        this.listQuery.agentId = this.loggedInAdmin.agentId;
      }
      this.listQuery = {
        ...this.listQuery,
        ...this.$route.query
      };
      if (this.listQuery.branchId) {
        // Optionally, you can handle any additional logic here if needed
        this.handleFilter(); // Trigger any necessary actions when the branch is pre-selected
      }
      // get dispatch list
      await this.getStateList();
      await this.getList();
    } catch (err) {
      if (err instanceof Error) {
        this.$notify.error({
          title: "Error",
          message: err.message
        });
      }
      this.listLoading = false;
    }
  }
  async fetchBranches(superAdmin = true) {
    this.branchListLoading = true;
    // Base query
    const query = {
      enabled: true,
      sort: "name,ASC"
    };
    //  filter only if superAdmin is false
    if (!superAdmin) {
      if (this.listQuery.branchId.length === 0) {
        this.listQuery.branchId = AdminModule.branchIds;
      }
      query.id = this.listQuery.branchId;
      query.filter = {
        enabled: "eq",
        id: "$in"
      };
    }
    // Fetch branch list
    this.branchList = await getBranches(getQuery(query));
    this.branchListLoading = false;
  }
  async getList() {
    try {
      const data = await dealerWiseOsOdReport(this.listQuery);
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getDealerList(query = null) {
    try {
      const isNumeric = /^[0-9]+$/.test(query);
      // search only if length is greater than 1 if query is id else 2
      if (!query || (isNumeric ? query.length < 1 : query.length < 2)) {
        this.dealerList = [];
        return;
      }
      const searchParams = {
        keyword: query,
        searchBy: isNumeric ? "dealerId" : "name",
        branchId: this.listQuery.branchId !== undefined ? this.listQuery.branchId : AdminModule.branchIds
      };
      if (this.listQuery.branchId !== undefined) {
        searchParams.branchId = this.listQuery.branchId;
      }
      this.dealerList = await searchDealers(searchParams);
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async getCityList(query = null) {
    try {
      if (query && query.length > 2) {
        this.cityListLoading = true;
        this.cityList = await getCities(getQuery({
          name: query,
          filter: {
            name: "$contL"
          }
        }));
      }
      this.cityListLoading = false;
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async getStateList(query = null) {
    try {
      this.stateList = await getStates(getQuery({
        enabled: true,
        sort: "name,ASC",
        filter: {
          enabled: "eq"
        }
      }));
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async handleFilter() {
    var _AdminModule$branchId;
    this.listQuery.page = 1;
    // handle branch and agent code
    // Fetch agents for a single branch
    if ((!isSuperAdmin() || !isAdmin()) && ((_AdminModule$branchId = AdminModule.branchIds) === null || _AdminModule$branchId === void 0 ? void 0 : _AdminModule$branchId.length) === 1) {
      this.listQuery.branchId = AdminModule.branchIds[0];
    }
    if (this.listQuery.branchId) {
      // Fetch agents and branches for non-superadmin/admin users
      this.agentList = await BranchAgentService.fetchAgents(this.listQuery.branchId);
      await BranchAgentService.fetchBranches(this.listQuery.branchId);
      // Fetch the list after agents and branches are loaded
      this.getList();
    } else {
      this.getList();
    }
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === "ascending" ? "ASC" : "DESC");
      this.handleFilter();
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? "ascending" : sort === `${key},DESC` ? "descending" : "";
  }
  toggleDialog(id = 0) {
    this.dialogVisible = true;
    this.dispatchId = id;
  }
  async handleExcelDownload() {
    this.downloadLoading = false;
    try {
      const query = this.listQuery;
      const blob = await downloadOsOdExcel(query);
      // Create a blob URL for the Excel file
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "dealer-os-od.xlsx");
      document.body.appendChild(link);
      // Simulate click to trigger download
      link.click();
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading Excel:", error);
      this.$message.error("Error downloading Excel");
    } finally {
      // Reset loading state
      this.downloadLoading = false;
    }
  }
};
default_1 = __decorate([Component({
  name: "DealerWiseOsOdReportList",
  components: {
    Pagination,
    Inr,
    Enabled
  }
})], default_1);
export default default_1;