"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.reduce.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _admin = require("@/store/modules/admin");
var _validate = require("@/utils/validate");
var _index = _interopRequireDefault(require("@/components/LangSelect/index.vue"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.validateUsername = (rule, value, callback) => {
      if (!(0, _validate.isValidEmail)(value)) {
        callback(new Error('Please enter valid email'));
      } else {
        callback();
      }
    };
    this.validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('Please enter your password'));
      } else {
        callback();
      }
    };
    this.loginForm = {
      email: '',
      password: ''
    };
    this.loginRules = {
      email: [{
        validator: this.validateUsername,
        trigger: 'blur'
      }],
      password: [{
        validator: this.validatePassword,
        trigger: 'blur'
      }]
    };
    this.passwordType = 'password';
    this.loading = false;
    this.showDialog = false;
    this.otherQuery = {};
  }
  onRouteChange(route) {
    // TODO: remove the "as Dictionary<string>" hack after v4 release for vue-router
    // See https://github.com/vuejs/vue-router/pull/2050 for details
    const query = route.query;
    if (query) {
      this.redirect = query.redirect;
      this.otherQuery = this.getOtherQuery(query);
    }
  }
  mounted() {
    if (this.loginForm.email === '') {
      this.$refs.email.focus();
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus();
    }
  }
  showPwd() {
    if (this.passwordType === 'password') {
      this.passwordType = '';
    } else {
      this.passwordType = 'password';
    }
    this.$nextTick(() => {
      this.$refs.password.focus();
    });
  }
  handleLogin() {
    this.$refs.loginForm.validate(async valid => {
      if (valid) {
        this.loading = true;
        try {
          await _admin.AdminModule.AgentLogin(this.loginForm);
          this.$router.push({
            path: this.redirect || '/',
            query: this.otherQuery
          });
        } catch (error) {
          this.loading = false;
        }
      } else {
        return false;
      }
    });
  }
  getOtherQuery(query) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== 'redirect') {
        acc[cur] = query[cur];
      }
      return acc;
    }, {});
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Watch)('$route', {
  immediate: true
})], default_1.prototype, "onRouteChange", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Login',
  components: {
    LangSelect: _index.default
  }
})], default_1);
var _default = exports.default = default_1;