"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _units = require("@/api/locations/units");
var _utils = require("@/utils");
var _products = require("@/api/products");
var _admin = require("@/store/modules/admin");
var _admins = require("@/api/admins");
var _inventoryInwards = require("@/api/inventory-inwards");
var _inventoryInwardsLogs = require("@/api/inventory-inwards-logs");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.formData = {};
    this.inventoryInwardsLogs = [];
    this.loading = false;
    this.loggedInAdmin = null;
    this.unitManagers = [];
    this.unitList = [];
    this.productList = [];
    this.counter = 1;
    this.selectedProductIds = [];
    this.emptyInventoryInwardsLogs = {
      id: 0,
      productId: null,
      product: null,
      productsVariationId: null,
      productsVariation: null,
      quantity: 0,
      looseQuantity: 0,
      cartonQuantity: 0,
      masterCarton: 0,
      inventoryInwardId: null,
      inventoryInward: null,
      unitId: 0,
      unit: null,
      adminId: 0,
      admin: null,
      // extra cols
      currentStock: 0,
      rowSpan: 1
    };
    this.rules = {
      entryDate: {
        required: true,
        message: "This field is required",
        trigger: "blur"
      },
      adminId: {
        required: true,
        message: "Please select Unit Manager",
        trigger: "blur"
      },
      unitId: {
        required: true,
        message: "This field is required",
        trigger: "blur"
      }
    };
  }
  get lang() {
    return _app.AppModule.language;
  }
  activated() {
    this.loggedInAdmin = _admin.AdminModule;
    this.formData.entryDate = new Date();
    this.inventoryInwardsLogs = [];
    this.selectedProductIds = [];
    this.productList = [];
    this.$nextTick(() => {
      // get unit managers
      this.fetchUnitManagers();
      //  get units
      this.fetchUnitList();
    });
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchUnitManagers() {
    let managerUnitId;
    // if adding & logged in user is unit manager
    // if (!this.isEdit && this.loggedInAdmin.adminsRoleId === 3) {
    if (!this.isEdit && this.loggedInAdmin.adminsRoleIds.includes(3)) {
      managerUnitId = this.loggedInAdmin.id;
      // set to form data
      this.formData.adminId = this.loggedInAdmin.id;
    }
    // if editing, then get saved unit manager id
    if (this.isEdit) {
      managerUnitId = this.formData.adminId;
    }
    let result = await (0, _admins.getAdmins)((0, _utils.getQuery)({
      id: managerUnitId,
      enabled: true,
      adminsRoleId: 3,
      filter: {
        id: "$eq",
        enabled: "$eq",
        adminsRoleId: "$in"
      }
    }));
    this.unitManagers = result.data;
  }
  async fetchUnitList() {
    try {
      // reset
      this.resetTable();
      // empty check adminId
      if (!this.formData.adminId) {
        return;
      }
      this.unitList = await (0, _units.getUnits)((0, _utils.getQuery)({
        enable: true,
        "admin.id": this.formData.adminId,
        sort: "name,ASC",
        filter: {
          enabled: "$eq",
          "admin.id": "$in"
        },
        select: ["id", "name", "code"].join(",")
      }));
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async fetchProductList(query) {
    try {
      if (query.length > 2) {
        this.productList = await (0, _products.searchProducts)((0, _utils.getQuery)({
          keyword: query
        }));
      }
    } catch (err) {} // eslint-disable-line  no-empty
  }
  resetTable() {
    // reset data
    this.inventoryInwardsLogs = [Object.assign({}, this.emptyInventoryInwardsLogs)];
    this.counter = 1;
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  async saveForm() {
    try {
      this.loading = true;
      // clear empty rows where product is not selected or quantity is 0
      this.inventoryInwardsLogs = this.inventoryInwardsLogs.filter(row => row.productId && row.quantity);
      // remove product, productsVariation, unit, admin, inventoryInward
      this.inventoryInwardsLogs = this.inventoryInwardsLogs.map(row => {
        const newRow = Object.assign({}, row);
        delete newRow.product;
        delete newRow.productsVariation;
        delete newRow.unit;
        delete newRow.admin;
        delete newRow.inventoryInward;
        return newRow;
      });
      // check if empty
      if (!this.inventoryInwardsLogs.length) {
        this.$notify({
          title: "Error",
          message: "Please add at least one product",
          type: "error",
          duration: 4000
        });
        this.resetTable();
        return;
      }
      // save
      await (0, _inventoryInwardsLogs.manageInventoryInwardsLog)(this.inventoryInwardsLogs);
      this.$notify({
        title: "Success",
        message: "Inventory added",
        type: "success",
        duration: 2000
      });
      this.loading = false;
      _router.default.push("/inventory-inwards/list");
    } catch (err) {
      if (err instanceof Error) {
        this.$notify({
          title: "Error",
          message: err.message,
          type: "error",
          duration: 4000
        });
        return;
      }
    } finally {
      this.loading = false;
    }
  }
  async fetchProducts(query) {
    try {
      if (query.length > 2) {
        this.productList = await (0, _products.getProducts)((0, _utils.getQuery)({
          enable: true,
          limit: 10,
          filter: {
            enabled: "$eq"
          }
        }));
      }
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async productSelected(rowScope, rowIndex) {
    // check if selected product already added
    if (this.selectedProductIds.includes(rowScope.productId)) {
      this.$notify({
        type: "warning",
        title: "Duplicate entry",
        message: "Product already added to list"
      });
      this.inventoryInwardsLogs[rowIndex].productId = null;
      return false;
    } else {
      this.selectedProductIds.push(rowScope.productId);
    }
    // get current inventoryInward based on unitId && product.id which gives productVariationId & currentStock
    const currentProductInventory = await (0, _inventoryInwards.getInventoryInwards)((0, _utils.getQuery)({
      limit: 100,
      unitId: this.formData.unitId,
      productId: rowScope.productId,
      filter: {
        unitId: "$eq",
        productId: "$eq"
      },
      select: ["id", "productsVariationId", "currentStock"].join(",")
    }));
    // get product from product list
    const product = this.productList.find(p => p.id === rowScope.productId);
    // create new row for each variation with same product
    product.productsVariation.forEach((variation, variationIndex) => {
      // get current stock
      const currentStock = currentProductInventory.find(cpi => cpi.productsVariationId === variation.id);
      // modify 0th row
      if (variationIndex === 0) {
        // set unit id
        this.inventoryInwardsLogs[rowIndex].unitId = this.formData.unitId;
        // set admin id
        this.inventoryInwardsLogs[rowIndex].adminId = this.formData.adminId;
        // set product
        this.inventoryInwardsLogs[rowIndex].product = product;
        // set product id
        this.inventoryInwardsLogs[rowIndex].productId = product.id;
        // set product variation
        this.inventoryInwardsLogs[rowIndex].productsVariation = variation;
        // set product variation id
        this.inventoryInwardsLogs[rowIndex].productsVariationId = variation.id;
        // set master carton
        this.inventoryInwardsLogs[rowIndex].masterCarton = product.masterCarton;
        // set current stock
        this.inventoryInwardsLogs[rowIndex].currentStock = currentStock && currentStock.currentStock ? currentStock.currentStock : 0;
        // set id
        // this.inventoryInwardsLogs[rowIndex].id =
        //   currentStock && currentStock.id ? currentStock.id : 0;
        // set rowSpan
        this.inventoryInwardsLogs[rowIndex].rowSpan = product.productsVariation.length;
      }
      // since 0th row is already created
      if (variationIndex > 0) {
        // insert new row at row rowIndex + i
        this.inventoryInwardsLogs.splice(rowIndex + variationIndex, 0, {
          // id: currentStock && currentStock.id ? currentStock.id : 0,   // SB
          id: 0,
          productId: variation.productId,
          product: {
            id: variation.productId
          },
          productsVariationId: variation.id,
          productsVariation: variation,
          unitId: this.formData.unitId,
          unit: {
            id: this.formData.unitId
          },
          adminId: this.formData.adminId,
          admin: {
            id: this.formData.adminId
          },
          quantity: 0,
          looseQuantity: 0,
          cartonQuantity: 0,
          masterCarton: product.masterCarton,
          currentStock: currentStock && currentStock.currentStock ? currentStock.currentStock : 0,
          inventoryInwardId: null,
          inventoryInward: null,
          rowSpan: 0
        });
      }
    });
  }
  quantityChanged(rowData) {
    // calculate quantity
    rowData.quantity = rowData.cartonQuantity * rowData.masterCarton + rowData.looseQuantity;
  }
  doProductRowSpan(args) {
    const {
      row,
      column,
      rowIndex,
      columnIndex
    } = args;
    // check if columnIndex is 0 || 6
    if (columnIndex === 0 || columnIndex === 6) {
      return {
        rowspan: row.rowSpan,
        colspan: 1
      };
    }
    return {
      rowspan: 1,
      colspan: 1
    };
  }
  addProduct() {
    this.counter += 1;
    // restrict upto 5 rows
    if (this.counter > 5) {
      this.counter -= 1;
      this.$notify({
        title: "Warning",
        message: "You can add upto 5 products only",
        type: "warning"
      });
      return false;
    }
    this.inventoryInwardsLogs.push(Object.assign({}, this.emptyInventoryInwardsLogs));
  }
  deleteProduct(index, row) {
    this.inventoryInwardsLogs.splice(index, row.rowSpan);
    // remove from selected product ids array
    const indexToRemove = this.selectedProductIds.indexOf(row.productId);
    this.selectedProductIds.splice(indexToRemove, 1);
    this.counter -= 1;
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "InventoryInwardDetail",
  components: {}
})], default_1);
var _default = exports.default = default_1;