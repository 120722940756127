"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transactionsSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const transactionsSubMenu = exports.transactionsSubMenu = [{
  path: '/transaction/list/',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/transaction/list.vue'))),
  name: 'Transaction',
  meta: {
    // title: 'accountManagement.transaction.title',
    title: 'accountManagement.transaction.title',
    roles: ['Super Admin', 'Admin', 'Account Manager', 'Read Only', 'Sales Manager', 'Invoice Manager', 'Unit Manager'],
    noCache: true,
    icon: 'inr'
  }
}, {
  path: '/transaction/list/:id(.*)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/transaction/list.vue'))),
  name: 'Transaction',
  meta: {
    title: 'accountManagement.transaction.title',
    roles: ['Super Admin', 'Admin'],
    noCache: true,
    hidden: true
  }
}, {
  path: '/transaction/add/:id(.*)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/transaction/manage.vue'))),
  name: 'accountManagement.transaction.add',
  props: {
    isEdit: false
  },
  meta: {
    title: 'accountManagement.transaction.add',
    noCache: true,
    activeMenu: '/transaction/list/:id(.*)',
    hidden: true
  }
}, {
  path: '/transaction/edit/:id(.*)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/transaction/manage.vue'))),
  name: 'accountManagement.transaction.edit',
  props: {
    isEdit: true
  },
  meta: {
    title: 'accountManagement.transaction.edit',
    noCache: true,
    activeMenu: '/transaction/list/:id(.*)',
    hidden: true
  }
}, {
  path: '/transaction/rtgs/:id(.*)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/transaction/rtgs.vue'))),
  name: 'accountManagement.transaction.rtgs',
  props: {
    isEdit: true
  },
  meta: {
    title: 'accountManagement.transaction.rtgs',
    noCache: true,
    activeMenu: '/transaction/list/:id(.*)',
    hidden: true
  }
}];