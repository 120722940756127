// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n@supports (-webkit-mask: none) and (not (cater-color: #fff)) {\n.login-container .el-input input {\n    color: #505050 !important;\n}\n.login-container .el-input input::first-line {\n    color: #e0e0e0;\n}\n.login-container .el-input input::-moz-placeholder {\n    color: #505050;\n}\n.login-container .el-input input::placeholder {\n    color: #505050;\n}\n}\n.login-container .el-input {\n  display: inline-block;\n  height: 47px;\n  width: 85%;\n}\n.login-container .el-input input {\n  height: 47px;\n  background: transparent;\n  border: 0px;\n  border-radius: 0px;\n  padding: 12px 5px 12px 15px;\n  color: #e0e0e0;\n  caret-color: #fff;\n  --webkit-appearance: none;\n}\n.login-container .el-input input:-webkit-autofill {\n  box-shadow: 0 0 0px 1000px #808080 inset !important;\n  -webkit-text-fill-color: #fff !important;\n}\n.login-container .el-form-item {\n  border: 1px solid rgba(255, 255, 255, 0.1);\n  background: rgba(0, 0, 0, 0.1);\n  border-radius: 5px;\n  color: #454545;\n}\n.login-container .logo {\n  text-align: center;\n  margin-top: 5em;\n}\n.login-container .logo .login-logo {\n  max-width: 20em;\n  margin: 0 auto;\n  width: 400px;\n  display: inherit;\n}", ""]);
// Exports
exports.locals = {
	"menuBg": "#B58643",
	"menuText": "#e0e0e0",
	"menuActiveText": "#FFE164",
	"menuActiveBg": "#906a35"
};
module.exports = exports;
