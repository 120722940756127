"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.categoriesSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const categoriesSubMenu = exports.categoriesSubMenu = [{
  path: '/category/list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/category/list.vue'))),
  name: 'categories',
  meta: {
    title: 'productManagement.category.title',
    roles: ['Super Admin', 'Admin', 'Read Only'],
    noCache: true,
    icon: 'tree',
    hidden: true
  }
}, {
  path: '/category/list/:parentId(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/category/list.vue'))),
  name: 'categories',
  meta: {
    title: 'productManagement.category.title',
    noCache: true,
    hidden: true
  }
}, {
  path: '/category/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/category/manage.vue'))),
  name: 'productManagement.category.manage',
  props: {
    isEdit: true
  },
  meta: {
    title: 'productManagement.category.manage',
    noCache: true,
    activeMenu: '/category/list',
    hidden: true
  }
}, {
  path: '/category/add/:parentId(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/category/manage.vue'))),
  name: 'productManagement.category.manage',
  props: {
    isEdit: false
  },
  meta: {
    title: 'productManagement.category.manage',
    noCache: true,
    activeMenu: '/category/list',
    hidden: true
  }
}];