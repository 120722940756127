import request from '@/utils/request';
export const defaultAgentData = {
  id: 0,
  enabled: true,
  name: '',
  code: ''
};
export const getAgents = params => request({
  url: '/agents',
  method: 'get',
  params
});
export const getAgentsBranchWise = params => request({
  url: '/agents/branch-wise',
  method: 'get',
  params
});
export const getAgentBranchWiseCount = params => request({
  url: '/agents/branch-wise-count',
  method: 'get',
  params
});
export const getAgentById = id => request({
  url: `/agents/${id}`,
  method: 'get'
});
export const updateAgent = (id, data) => request({
  url: `/agents/${id}`,
  method: 'patch',
  data
});
export const deleteAgent = id => request({
  url: `/agents/${id}`,
  method: 'delete'
});
export const createAgent = data => request({
  url: '/agents',
  method: 'post',
  data
});
export const agentsCount = params => request({
  url: '/agents/count',
  method: 'get',
  params
});