"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _categorySubMenu = require("./subMenus/category-sub-menu");
var _rangeSubMenu = require("./subMenus/range-sub-menu");
var _schemesCategoriesSubMenu = require("./subMenus/schemes-categories-sub-menu");
const productRouter = {
  path: '/product',
  component: _index.default,
  redirect: 'noredirect',
  name: 'productManagement.title',
  meta: {
    roles: ['Super Admin', 'Admin', 'Unit Manager', 'Invoice Manager', 'Read Only', 'Sales Manager', 'Order Manager'],
    title: 'productManagement.title',
    icon: 'almond'
  },
  children: [{
    path: '/attribute/list',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/attribute/list.vue'))),
    name: 'productManagement.attribute.title',
    meta: {
      title: 'productManagement.attribute.title',
      roles: ['Super Admin', 'Admin', 'Read Only'],
      noCache: true,
      icon: 'sack'
    }
  }, {
    path: '/attribute/add',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/attribute/manage.vue'))),
    name: 'productManagement.attribute.add',
    props: {
      isEdit: false
    },
    meta: {
      title: 'productManagement.attribute.add',
      noCache: true,
      hidden: true
    }
  }, {
    path: '/attribute/edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/attribute/manage.vue'))),
    name: 'productManagement.attribute.edit',
    props: {
      isEdit: true
    },
    meta: {
      title: 'productManagement.attribute.edit',
      noCache: true,
      hidden: true
    }
  }, {
    path: '/attribute-value/list/:id',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/attribute-value/list.vue'))),
    name: 'Attribute Value',
    meta: {
      title: 'productManagement.attributevalue.title',
      roles: ['Super Admin', 'Admin', 'Read Only'],
      noCache: true,
      activeMenu: '/attribute/list',
      hidden: true
    }
  }, {
    path: '/attribute-value/add/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/attribute-value/manage.vue'))),
    name: 'Manage Attribute Value',
    props: {
      isEdit: false
    },
    meta: {
      title: 'productManagement.attributevalue.manage',
      noCache: true,
      hidden: true
    }
  }, {
    path: '/attribute-value/edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/attribute-value/manage.vue'))),
    name: 'productManagement.attributevalue.manage',
    props: {
      isEdit: true
    },
    meta: {
      title: 'productManagement.attributevalue.manage',
      noCache: true,
      hidden: true
    }
  }, ..._rangeSubMenu.rangesSubMenu, ..._categorySubMenu.categoriesSubMenu, ..._schemesCategoriesSubMenu.schemesCategoriesSubMenu, {
    path: '/product/list',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/products/list.vue'))),
    name: 'productManagement.product.title',
    meta: {
      title: 'productManagement.product.title',
      icon: 'almond',
      noCache: true
    }
  }, {
    path: '/product/add',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/products/manage.vue'))),
    name: 'productManagement.product.manage',
    props: {
      isEdit: false
    },
    meta: {
      title: 'productManagement.product.manage',
      roles: ['Super Admin', 'Admin'],
      noCache: true,
      hidden: true
    }
  }, {
    path: '/product/edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/products/manage.vue'))),
    name: 'productManagement.product.manage',
    props: {
      isEdit: true
    },
    meta: {
      title: 'productManagement.product.manage',
      roles: ['Super Admin', 'Admin'],
      noCache: true,
      hidden: true
    }
  }, {
    path: '/product/import',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/products/import.vue'))),
    name: 'productManagement.product.import',
    props: {
      isEdit: false
    },
    meta: {
      title: 'productManagement.product.import',
      noCache: true,
      hidden: true
    }
  }]
};
var _default = exports.default = productRouter;