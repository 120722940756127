"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("el-input", {
    staticClass: "filter-item",
    attrs: {
      placeholder: _vm.$t("product.name")
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleFilter.apply(null, arguments);
      }
    },
    model: {
      value: _vm.listQuery.name,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "name", $$v);
      },
      expression: "listQuery.name"
    }
  }), _c("el-input", {
    staticClass: "filter-item",
    attrs: {
      placeholder: _vm.$t("product.catalogueCode")
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleFilter.apply(null, arguments);
      }
    },
    model: {
      value: _vm.listQuery.catalogueCode,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "catalogueCode", $$v);
      },
      expression: "listQuery.catalogueCode"
    }
  }), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "Range"
    },
    on: {
      change: _vm.handleCategoryFilter
    },
    model: {
      value: _vm.listQuery.rangeId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "rangeId", $$v);
      },
      expression: "listQuery.rangeId"
    }
  }, _vm._l(_vm.rangeList, function (range) {
    return _c("el-option", {
      key: range.id,
      attrs: {
        label: range.name,
        value: range.id
      }
    });
  }), 1), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      disabled: !_vm.listQuery.rangeId,
      clearable: "",
      filterable: "",
      placeholder: "Category"
    },
    on: {
      change: _vm.handleSubCategoryFilter
    },
    model: {
      value: _vm.categoryId,
      callback: function ($$v) {
        _vm.categoryId = $$v;
      },
      expression: "categoryId"
    }
  }, _vm._l(_vm.categoryList, function (category) {
    return _c("el-option", {
      key: category.id,
      attrs: {
        label: category.name,
        value: category.id
      }
    });
  }), 1), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      disabled: !_vm.categoryId,
      clearable: "",
      filterable: "",
      placeholder: "Sub Category"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.subCategoryId,
      callback: function ($$v) {
        _vm.subCategoryId = $$v;
      },
      expression: "subCategoryId"
    }
  }, _vm._l(_vm.subCategoryList, function (category) {
    return _c("el-option", {
      key: category.id,
      attrs: {
        label: category.name,
        value: category.id
      }
    });
  }), 1), _c("el-select", {
    staticClass: "filter-item",
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.sort,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "sort", $$v);
      },
      expression: "listQuery.sort"
    }
  }, _vm._l(_vm.sortOptions, function (item) {
    return _c("el-option", {
      key: item.key,
      attrs: {
        label: item.label,
        value: item.key
      }
    });
  }), 1), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "info",
      size: "mini",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")]), _c("router-link", {
    directives: [{
      name: "permission",
      rawName: "v-permission",
      value: [_vm.SUPER_ADMIN],
      expression: "[SUPER_ADMIN]"
    }],
    staticClass: "margin-horizontal",
    attrs: {
      to: "/product/add"
    }
  }, [_c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "warning",
      size: "mini",
      icon: "el-icon-plus"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.add")) + " ")])], 1), _c("router-link", {
    directives: [{
      name: "permission",
      rawName: "v-permission",
      value: [_vm.SUPER_ADMIN],
      expression: "[SUPER_ADMIN]"
    }],
    staticClass: "margin-horizontal",
    attrs: {
      to: "/product/import"
    }
  }, [_c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "info",
      size: "mini",
      icon: "el-icon-plus"
    }
  }, [_vm._v(" Import ")])], 1), !_vm.isReadOnly ? _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-document"
    },
    on: {
      click: _vm.handleExcelDownload
    }
  }, [_vm._v(" Export as Excel ")]) : _vm._e()], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    ref: "eltable",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "highlight-current-row": "",
      "row-key": "id"
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      sortable: "custom",
      width: "80",
      align: "center",
      label: _vm.$t("table.id"),
      prop: "id",
      "class-name": _vm.getSortClass("id")
    }
  }), _c("el-table-column", {
    attrs: {
      width: "240px",
      label: "Product",
      prop: "name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.productsImage.length > 0 ? _c("el-image", {
          staticStyle: {
            width: "50px",
            height: "50px"
          },
          attrs: {
            src: scope.row.productsImage[0].image.concat("-sm"),
            fit: "fill",
            "src-list": [scope.row.productsImage[0].image.concat("-lg")]
          }
        }) : _vm._e(), _c("p", [_c("enabled", {
          attrs: {
            disabled: true,
            url: "/products/" + scope.row.id
          },
          model: {
            value: scope.row.enabled,
            callback: function ($$v) {
              _vm.$set(scope.row, "enabled", $$v);
            },
            expression: "scope.row.enabled"
          }
        }), _vm._v(" " + _vm._s(scope.row.name) + " ")], 1), _c("p", {
          staticClass: "text-xsmall text-italic"
        }, [_vm._v("(" + _vm._s(scope.row.catalogueCode) + ")")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "270",
      align: "center",
      label: "Variants",
      prop: "productsVariation"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.productType === "simple" ? [_c("inr", {
          attrs: {
            number: scope.row.productsVariation[0].mrp
          }
        }), _vm._v(" / "), _c("inr", {
          attrs: {
            number: scope.row.productsVariation[0].oldMrp
          }
        })] : [_c("el-descriptions", {
          staticClass: "product-variation-description",
          attrs: {
            column: 1,
            size: "mini",
            border: ""
          }
        }, _vm._l(scope.row.productsVariation, function (variant) {
          return _c("el-descriptions-item", {
            key: variant.id
          }, [_c("template", {
            slot: "label"
          }, [_vm._v(" " + _vm._s(variant.name) + " ")]), _c("inr", {
            attrs: {
              number: variant.mrp
            }
          }), _vm._v(" / "), _c("inr", {
            attrs: {
              number: variant.oldMrp
            }
          })], 2);
        }), 1)]];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "130",
      align: "center",
      label: "Scheme Category",
      prop: "schemesCategory.name"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "120",
      align: "center",
      label: "Range",
      prop: "range.name"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "200",
      align: "center",
      label: "Categories",
      prop: "category"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        var _scope$row$category;
        return [(_scope$row$category = scope.row.category) !== null && _scope$row$category !== void 0 && _scope$row$category.length ? [_c("p", {
          staticClass: "text-xsmall"
        }, [_vm._v(" " + _vm._s(scope.row.category[0].name) + " "), scope.row.category.length > 1 ? [_vm._v(" / " + _vm._s(scope.row.category[1].name) + " ")] : _vm._e()], 2)] : _vm._e()];
      }
    }])
  }), _vm.hasNoAccess([_vm.UNIT_MANAGER, _vm.INVOICE_MANAGER]) ? _c("el-table-column", {
    attrs: {
      align: "center",
      label: _vm.$t("table.actions"),
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("router-link", {
          attrs: {
            to: "/product/edit/" + scope.row.id
          }
        }, [!_vm.isReadOnly ? _c("el-button", {
          directives: [{
            name: "waves",
            rawName: "v-waves"
          }],
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-edit"
          }
        }) : _vm._e()], 1)];
      }
    }], null, false, 2216083546)
  }) : _vm._e()], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;