"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UNIT_MANAGER = exports.SUPER_ADMIN = exports.READ_ONLY = exports.ORDER_MANAGER = exports.INVOICE_MANAGER = exports.ADMIN = exports.ACCOUNT_MANAGER = void 0;
const SUPER_ADMIN = exports.SUPER_ADMIN = 1;
const ADMIN = exports.ADMIN = 2;
const UNIT_MANAGER = exports.UNIT_MANAGER = 3;
const INVOICE_MANAGER = exports.INVOICE_MANAGER = 4;
const READ_ONLY = exports.READ_ONLY = 5;
const ACCOUNT_MANAGER = exports.ACCOUNT_MANAGER = 6;
const ORDER_MANAGER = exports.ORDER_MANAGER = 7;