"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _dealersTransporters = require("@/api/dealers-transporters");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _utils = require("@/utils");
var _transporters = require("@/api/transporters");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.formData = Object.assign({}, _dealersTransporters.defaultDealersTransporterData);
    this.loading = false;
    this.transporterList = [];
    this.rules = {
      transporterId: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      },
      freightPerCarton: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      }
    };
  }
  get lang() {
    return _app.AppModule.language;
  }
  activated() {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, _dealersTransporters.defaultDealersTransporterData);
      this.formData.dealerId = parseInt(this.$route.params && this.$route.params.dealerId);
    }
    // get transporter list
    this.getTransporterList();
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _dealersTransporters.getDealersTransporterById)(id);
      this.formData = data;
      // Just for test
      const title = this.formData.id + '';
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getTransporterList() {
    try {
      this.transporterList = await (0, _transporters.getTransporters)((0, _utils.getQuery)({
        enabled: true,
        filter: {
          enabled: '$eq'
        }
      }));
    } catch (error) {
      this.$notify({
        title: 'Error',
        type: 'error',
        message: error.toString()
      });
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  async saveForm() {
    try {
      this.loading = true;
      this.formData.dealer = {
        id: this.formData.dealerId
      };
      this.formData.transporter = {
        id: this.formData.transporterId
      };
      if (this.isEdit) {
        await (0, _dealersTransporters.updateDealersTransporter)(this.formData.id, this.formData);
      } else {
        await (0, _dealersTransporters.createDealersTransporter)(this.formData);
      }
      this.loading = false;
      this.$notify({
        title: 'Success',
        message: 'Transporter saved',
        type: 'success',
        duration: 2000
      });
      _router.default.push(`/dealers-transporters/list/${this.formData.dealerId}`);
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'DealersTransporterDetail',
  components: {}
})], default_1);
var _default = exports.default = default_1;