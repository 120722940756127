"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateAgentsUser = exports.getAgentsUsers = exports.getAgentsUserById = exports.getAgentInfo = exports.deleteAgentsUser = exports.defaultAgentsUserData = exports.createAgentsUser = exports.agentLogout = exports.agentLogin = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultAgentsUserData = exports.defaultAgentsUserData = {
  id: 0,
  enabled: true,
  fullName: '',
  emailAddress: '',
  mobileNumber: '',
  password: undefined,
  agentId: undefined,
  agent: {
    id: null
  }
};
const getAgentsUsers = params => (0, _request.default)({
  url: '/agents-users',
  method: 'get',
  params
});
exports.getAgentsUsers = getAgentsUsers;
const getAgentsUserById = id => (0, _request.default)({
  url: `/agents-users/${id}`,
  method: 'get'
});
exports.getAgentsUserById = getAgentsUserById;
const updateAgentsUser = (id, data) => (0, _request.default)({
  url: `/agents-users/${id}`,
  method: 'patch',
  data
});
exports.updateAgentsUser = updateAgentsUser;
const deleteAgentsUser = id => (0, _request.default)({
  url: `/agents-users/${id}`,
  method: 'delete'
});
exports.deleteAgentsUser = deleteAgentsUser;
const createAgentsUser = data => (0, _request.default)({
  url: '/agents-users/',
  method: 'post',
  data
});
exports.createAgentsUser = createAgentsUser;
const agentLogin = data => (0, _request.default)({
  url: '/agents-users/login',
  method: 'post',
  data
});
exports.agentLogin = agentLogin;
const agentLogout = () => (0, _request.default)({
  url: '/agents-users/logout',
  method: 'post'
});
exports.agentLogout = agentLogout;
const getAgentInfo = data => (0, _request.default)({
  url: '/agents-users/me',
  method: 'post',
  data
});
exports.getAgentInfo = getAgentInfo;