"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _admins = require("@/api/admins");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _roles = require("@/api/roles");
var _utils = require("@/utils");
var _admin = require("@/store/modules/admin");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.roleList = [];
    this.adminToken = _admin.AdminModule;
    this.selectedRoles = [];
    this.changePassword = false;
    this.formData = Object.assign({}, _admins.defaultAdminData);
    this.loading = false;
    this.rules = {
      fullName: [{
        required: true,
        message: "This field is required",
        trigger: "blur"
      }, {
        min: 3,
        max: 100,
        message: "Length should be 3 to 100",
        trigger: "blur"
      }],
      mobileNumber: [{
        required: true,
        message: "This field is required",
        trigger: "blur"
      }, {
        pattern: /^\d+$/,
        message: "Please enter numbers only",
        trigger: "blur"
      }, {
        min: 10,
        max: 10,
        message: "Length should be 10",
        trigger: "blur"
      }],
      emailAddress: [{
        required: true,
        message: "This field is required",
        trigger: "blur"
      }, {
        type: "email",
        message: "Please enter correct email address",
        trigger: ["blur"]
      }],
      password: [{
        required: true,
        message: "This field is required",
        trigger: "blur"
      }, {
        min: 6,
        message: "Password length should be greater than 6",
        trigger: "blur"
      }]
    };
  }
  get lang() {
    return _app.AppModule.language;
  }
  activated() {
    this.fetchRoles();
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, _admins.defaultAdminData);
    }
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _admins.getAdminById)(id);
      this.formData = data;
      // populate selected admins
      if (data.adminsRole && data.adminsRole.length) {
        this.selectedRoles = data.adminsRole.map(adminRole => adminRole.id);
      }
      // Just for test
      const title = this.formData.emailAddress;
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async fetchRoles() {
    try {
      this.roleList = await (0, _roles.getRoles)((0, _utils.getQuery)({
        enabled: true,
        sort: "name,ASC",
        filter: {
          enabled: "eq"
        }
      }));
    } catch (err) {} // eslint-disable-line  no-empty
  }
  resetPassword() {
    if (!this.changePassword) {
      this.formData.password = "";
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    if (this.formData.id) {
      this.fetchData(this.formData.id);
    }
    // (this.$refs.formData as Form).resetFields()
  }
  async saveForm() {
    try {
      this.loading = true;
      // Save multiple admins
      if (this.selectedRoles.length) {
        this.formData.adminsRole = this.selectedRoles.map(id => ({
          id
        }));
      }
      // if (this.isEdit) {
      //   if (!this.changePassword) {
      //     delete this.formData.password;
      //   }
      //   // this.formData.adminsRole.id = this.formData.adminsRoleId;
      //   await updateAdmin(this.formData.id, this.formData);
      // } else {
      //   delete this.formData.adminsRole;
      //   await register(this.formData);
      // }
      if (this.isEdit) {
        if (!this.changePassword) {
          delete this.formData.password;
        }
        await (0, _admins.updateAdmin)(this.formData.id, this.formData);
      } else {
        // Make sure to assign the selected roles to `adminsRole`
        this.formData.adminsRole = this.selectedRoles.map(id => ({
          id
        }));
        // Do not delete `adminsRole`
        await (0, _admins.register)(this.formData);
      }
      this.loading = false;
      this.$notify({
        title: "Success",
        message: "Admin saved successfully",
        type: "success",
        duration: 2000
      });
      _router.default.push("/admins/list");
    } catch (err) {
      this.loading = false;
    }
  }
  canChangePassword() {
    // check if not edit
    if (!this.isEdit) {
      return false;
    }
    // check if super admin
    // if (this.adminToken.adminsRoleId === 1) {
    //   return true;
    // }
    //multi user condition change
    if (this.adminToken.adminsRoleIds.includes(1)) {
      return true;
    }
    // don't allow to change password otherwise
    return false;
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "AdminDetail",
  components: {}
})], default_1);
var _default = exports.default = default_1;