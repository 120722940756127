"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _states = require("@/api/locations/states");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _router = _interopRequireDefault(require("@/router"));
var _admin = require("@/store/modules/admin");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.isReadOnly = false;
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.countryId = 0;
    this.listQuery = {
      page: 1,
      limit: 10,
      code: undefined,
      name: undefined,
      enabled: true,
      countryId: 0,
      sort: "name,ASC",
      filter: {
        code: "$contL",
        name: "$contL",
        enabled: "$eq",
        countryId: "$eq"
      }
    };
    this.sortOptions = [{
      label: "A-Z",
      key: "name,ASC"
    }, {
      label: "Z-A",
      key: "name,DESC"
    }];
    this.enableTypeOptions = [{
      key: true,
      value: "Enabled"
    }, {
      key: false,
      value: "Disabled"
    }];
  }
  activated() {
    if (_admin.AdminModule.roles.includes("Read Only")) {
      this.isReadOnly = true;
    }
    this.countryId = parseInt(this.$route.params && (this.$route.params.countryId || "1"));
    this.listQuery = {
      ...this.listQuery,
      ...this.$route.query
    };
    this.getList();
  }
  async getList() {
    try {
      this.listLoading = true;
      this.listQuery.countryId = this.countryId;
      const data = await (0, _states.getStates)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  handleDelete(data) {
    if (data) {
      this.$confirm(this.$tc("table.deleteConfirmation", undefined, {
        name: data.name
      }), this.$tc("table.delete"), {
        confirmButtonText: this.$tc("table.delete"),
        confirmButtonClass: "el-button--danger",
        cancelButtonText: "Cancel",
        type: "error"
      }).then(() => {
        (0, _states.deleteState)(data.id).then(deleted => {
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: this.$tc("table.deleteCanceled")
        });
      });
    }
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === "ascending" ? "ASC" : "DESC");
      this.handleFilter();
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? "ascending" : sort === `${key},DESC` ? "descending" : "";
  }
  handleDownload() {
    this.downloadLoading = true;
    const tHeader = ["id", "name", "country", "enabled", "timestamp"];
    const filterVal = ["id", "name", "country.name", "enabled", "createdTimestamp"];
    const data = (0, _index2.formatJson)(filterVal, this.list);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc("route.locations.states.title"));
    this.downloadLoading = false;
  }
  edit(id = 0) {
    const query = this.listQuery;
    delete query.filter;
    _router.default.push({
      path: "/states/edit/" + id,
      query
    });
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "StateList",
  components: {
    Pagination: _index.default,
    Enabled: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;