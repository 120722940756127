"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.reduce.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _index = _interopRequireDefault(require("@/components/LangSelect/index.vue"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    //   private validateUsername = (rule: any, value: string, callback: Function) => {
    //     if (!isValidEmail(value)) {
    //       callback(new Error('Please enter valid email'))
    //     } else {
    //       callback()
    //     }
    //   };
    super(...arguments);
    this.validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('The password can not be less than 6 digits'));
      } else {
        callback();
      }
    };
    this.resetForm = {
      password: '',
      confirmPassword: '',
      hash: this.$route.params.hash
    };
    this.loginRules = {
      password: [{
        validator: this.validatePassword,
        trigger: 'blur'
      }],
      confirmPassword: [{
        validator: this.validatePassword,
        trigger: 'blur'
      }]
    };
    this.passwordType = 'password';
    this.loading = false;
    this.showDialog = false;
    this.otherQuery = {};
  }
  // @Watch('$route', { immediate: true })
  // private onRouteChange(route: Route) {
  //   // TODO: remove the "as Dictionary<string>" hack after v4 release for vue-router
  //   // See https://github.com/vuejs/vue-router/pull/2050 for details
  //   const query = route.query as Dictionary<string>
  //   if (query) {
  //     this.redirect = query.redirect
  //     this.otherQuery = this.getOtherQuery(query)
  //   }
  // }
  mounted() {
    if (this.resetForm.confirmPassword === '') {
      this.$refs.confirmPassword.focus();
    } else if (this.resetForm.password === '') {
      this.$refs.password.focus();
    }
  }
  showPwd() {
    if (this.passwordType === 'password') {
      this.passwordType = '';
    } else {
      this.passwordType = 'password';
    }
    this.$nextTick(() => {
      this.$refs.password.focus();
    });
  }
  handleLogin() {
    this.$refs.resetForm.validate(async valid => {
      if (valid) {
        this.loading = true;
        try {
          await this.resetForm;
          this.$router.push({
            path: this.redirect || '/',
            query: this.otherQuery
          });
        } catch (error) {
          this.loading = false;
        }
      } else {
        return false;
      }
    });
  }
  getOtherQuery(query) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== 'redirect') {
        acc[cur] = query[cur];
      }
      return acc;
    }, {});
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ChangePassword',
  components: {
    LangSelect: _index.default
  }
})], default_1);
var _default = exports.default = default_1;