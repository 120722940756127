"use strict";

var _interopRequireWildcard = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
require("core-js/modules/es.array.push.js");
var _vue = _interopRequireDefault(require("vue"));
require("normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
require("@/styles/element-variables.scss");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("@/App.vue"));
var _store = _interopRequireDefault(require("@/store"));
var _app = require("@/store/modules/app");
var _router = _interopRequireDefault(require("@/router"));
var _lang = _interopRequireDefault(require("@/lang"));
require("@/icons/components");
require("@/permission");
require("@/utils/error-log");
require("@/pwa/register-service-worker");
var directives = _interopRequireWildcard(require("@/directives"));
var filters = _interopRequireWildcard(require("@/filters"));
var _vueMoment = _interopRequireDefault(require("vue-moment"));
var _vuePluginLoadScript = _interopRequireDefault(require("vue-plugin-load-script"));
var _vueQrcode = _interopRequireDefault(require("@chenfengyuan/vue-qrcode"));
var _vueCtkDateTimePicker = _interopRequireDefault(require("vue-ctk-date-time-picker"));
require("vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css");
require("element-ui/lib/theme-chalk/display.css");
var _idleVue = _interopRequireDefault(require("idle-vue"));
var _admin = require("@/store/modules/admin");
var _roles = require("./constants/roles.constants");
_vue.default.component("VueCtkDateTimePicker", _vueCtkDateTimePicker.default);
_vue.default.use(_elementUi.default, {
  size: _app.AppModule.size,
  i18n: (key, value) => _lang.default.t(key, value)
});
_vue.default.use(_vueMoment.default);
_vue.default.use(_vuePluginLoadScript.default);
_vue.default.component(_vueQrcode.default.name, _vueQrcode.default);
_vue.default.use(_vueSvgicon.default, {
  tagName: "svg-icon",
  defaultWidth: "1em",
  defaultHeight: "1em"
});
// Attach each constant directly to Vue prototype
_vue.default.prototype.SUPER_ADMIN = _roles.SUPER_ADMIN;
_vue.default.prototype.ADMIN = _roles.ADMIN;
_vue.default.prototype.UNIT_MANAGER = _roles.UNIT_MANAGER;
_vue.default.prototype.INVOICE_MANAGER = _roles.INVOICE_MANAGER;
_vue.default.prototype.READ_ONLY = _roles.READ_ONLY;
_vue.default.prototype.ACCOUNT_MANAGER = _roles.ACCOUNT_MANAGER;
_vue.default.prototype.ORDER_MANAGER = _roles.ORDER_MANAGER;
// Register vue idle plugin to automatically logout user after 10 seconds of idle time
const eventsHub = new _vue.default();
_vue.default.use(_idleVue.default, {
  eventEmitter: eventsHub,
  // idleTime: 600000, // 10 minutes for testing; adjust as necessary
  idleTime: 6000000
});
// Listen for idle event
eventsHub.$on("idleVue_onIdle", () => {
  if (_admin.AdminModule.userType === "Admin") {
    _admin.AdminModule.LogOut(); // Trigger admin logout
    _router.default.push("/login"); // Redirect to the login page after logout
  } else if (_admin.AdminModule.userType === "agent") {
    _admin.AdminModule.AgentLogOut(); // Trigger agent logout
    _router.default.push("/agent-login"); // Redirect to the agent login page after logout
  }
});
// Register global directives
Object.keys(directives).forEach(key => {
  _vue.default.directive(key, directives[key]);
});
// Register global filter functions
Object.keys(filters).forEach(key => {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
new _vue.default({
  router: _router.default,
  store: _store.default,
  i18n: _lang.default,
  render: h => h(_App.default)
}).$mount("#app");