"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BranchAgentService = void 0;
var _branches = require("@/api/locations/branches");
var _agents = require("@/api/agents");
var _admin = require("@/store/modules/admin");
var _utils = require("@/utils");
var _common = require("@/common");
// branchAgentService.js

const BranchAgentService = exports.BranchAgentService = {
  async fetchBranches(branchIds = undefined) {
    const query = {
      enabled: true,
      sort: "name,ASC"
    };
    if (!(0, _common.isSuperAdmin)() || !(0, _common.isAdmin)()) {
      // Convert array to comma-separated values before passing to getQuery
      query["id"] = branchIds.length > 1 ? branchIds.join(',') : branchIds;
      query["filter"] = {
        enabled: "eq",
        id: "$in"
      };
    }
    return await (0, _branches.getBranches)((0, _utils.getQuery)(query));
  },
  async fetchAgents(branchIds = undefined) {
    let arrayOfBranchId = Array.isArray(branchIds) ? branchIds : [branchIds];
    console.log('condition', !(0, _common.isSuperAdmin)() === false, !(0, _common.isAdmin)() === false, arrayOfBranchId.length > 0);
    try {
      const query = {
        enabled: true,
        sort: "name,ASC",
        filter: {
          enabled: "eq"
        }
      };
      if ((!(0, _common.isSuperAdmin)() || !(0, _common.isAdmin)()) && arrayOfBranchId.length > 0) {
        query["branchId"] = arrayOfBranchId;
        console.log("here agents");
        return await (0, _agents.getAgentsBranchWise)((0, _utils.getQuery)(query));
      } else {
        console.log("here agents else");
        return await (0, _agents.getAgents)((0, _utils.getQuery)(query));
      }
    } catch (error) {
      // Handle error
    }
  },
  assignBranchId() {
    var _loggedInAdminDetails, _loggedInAdminDetails2;
    let loggedInAdminDetails = _admin.AdminModule;
    let branchId = null;
    if (((_loggedInAdminDetails = loggedInAdminDetails.branchIds) === null || _loggedInAdminDetails === void 0 ? void 0 : _loggedInAdminDetails.length) === 1) {
      branchId = loggedInAdminDetails.branchIds[0];
    } else if (((_loggedInAdminDetails2 = loggedInAdminDetails.branchIds) === null || _loggedInAdminDetails2 === void 0 ? void 0 : _loggedInAdminDetails2.length) > 1) {
      branchId = loggedInAdminDetails.branchIds;
    }
    return branchId;
  }
};