"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _admin = require("@/store/modules/admin");
var _index = _interopRequireDefault(require("@/components/FileUpload/index.vue"));
var _admins = require("@/api/admins");
var _agents = require("@/api/agents");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.avatar = [];
    this.formData = Object.assign({}, _admins.defaultAdminData);
    this.data = {};
    this.isAgent = false;
    this.agentData = {};
    this.adminData = {};
  }
  activated() {
    if (_admin.AdminModule.userType === "Agent") {
      this.isAgent = true;
      this.fetchData(_admin.AdminModule.agentId, _admin.AdminModule.userType);
    } else {
      this.fetchData(_admin.AdminModule.id, _admin.AdminModule.userType);
    }
  }
  async fetchData(id, type) {
    try {
      if (type === "Agent") {
        const data = await (0, _agents.getAgentById)(id);
        this.formData = data;
        this.agentData = data;
      } else {
        const data = await (0, _admins.getAdminById)(id);
        this.formData = data;
        this.adminData = data;
      }
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async submit() {
    try {
      await (0, _admins.updateAdmin)(this.formData.id, this.formData);
      this.$message({
        message: "User information has been updated successfully",
        type: "success",
        duration: 5 * 1000
      });
    } catch (err) {} // eslint-disable-line  no-empty
  }
  fileUploaded(file) {
    this.formData.avatar = file.response;
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  required: true
})], default_1.prototype, "user", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "Account",
  components: {
    FileUpload: _index.default
  }
})], default_1);
var _default = exports.default = default_1;