"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _ordersDispatches = require("@/api/orders-dispatches");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _index3 = _interopRequireDefault(require("@/components/Inr/index.vue"));
var _agents = require("@/api/agents");
var _admin = require("@/store/modules/admin");
var _dealers = require("@/api/dealers");
var _common = require("@/common");
var _branchAgentService = require("@/services/branch-agent-service");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.isAgent = false;
    this.isReadOnly = false;
    this.agentList = [];
    this.dealerList = [];
    this.branchListLoading = false;
    this.branchList = [];
    this.dealerListLoading = false;
    this.downloadLoading = false;
    this.listQuery = {
      page: 1,
      limit: 10,
      dealerWise: undefined,
      days: undefined,
      dealerId: undefined
    };
  }
  async activated() {
    try {
      // check if logged in user is Agent/Admin
      if (_admin.AdminModule.userType === "Agent") {
        this.isAgent = true;
        this.listQuery.agentId = _admin.AdminModule.agentId;
      }
      if (_admin.AdminModule.roles.includes("Read Only")) {
        this.isReadOnly = true;
      }
      if (this.$route.query.days && this.$route.query.dealerWise) {
        this.listQuery.days = this.$route.query.days, this.listQuery.dealerWise = this.$route.query.dealerWise;
      }
      // Assign branchId using BranchAgentService
      this.listQuery.branchId = await _branchAgentService.BranchAgentService.assignBranchId();
      // Check if branchId is null and throw an error
      if (this.listQuery.branchId === null) {
        throw new Error("You are not assigned to a branch.");
      }
      // Fetch agents for the assigned branchId
      this.agentList = await _branchAgentService.BranchAgentService.fetchAgents(this.listQuery.branchId);
      // Fetch branches for non-super admin users
      this.branchList = await _branchAgentService.BranchAgentService.fetchBranches(this.listQuery.branchId);
      this.getList();
    } catch (err) {
      if (err instanceof Error) {
        this.$notify.error({
          title: "Error",
          message: err.message
        });
      }
      this.listLoading = false;
    }
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _ordersDispatches.fetchOverdueByDays)(this.listQuery);
      this.list = data.overdueDealers;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getDealerList(query = null) {
    try {
      const isNumeric = /^[0-9]+$/.test(query);
      // search only if length is greater than 1 if query is id else 2
      if (!query || (isNumeric ? query.length < 1 : query.length < 2)) {
        this.dealerList = [];
        return;
      }
      const searchParams = {
        keyword: query,
        searchBy: isNumeric ? "dealerId" : "name",
        branchId: this.listQuery.branchId !== undefined ? this.listQuery.branchId : _admin.AdminModule.branchIds
      };
      this.dealerList = await (0, _dealers.searchDealers)(searchParams);
    } catch (error) {} // eslint-disable-line  no-empty
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === "ascending" ? "ASC" : "DESC");
      this.handleFilter();
    }
  }
  async handleExcelDownload() {
    this.downloadLoading = false;
    try {
      const query = this.listQuery;
      const blob = await (0, _ordersDispatches.downloadExcelOverdue)(query);
      // Create a blob URL for the Excel file
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "overdues.xlsx");
      document.body.appendChild(link);
      // Simulate click to trigger download
      link.click();
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading Excel:", error);
      this.$message.error("Error downloading Excel");
    } finally {
      // Reset loading state
      this.downloadLoading = false;
    }
  }
  async handlePdfDownload() {
    this.downloadLoading = false;
    try {
      const query = this.listQuery;
      const blob = await (0, _ordersDispatches.downloadPdfOverdue)(query);
      // Create a blob URL for the Excel file
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "overdues.pdf");
      document.body.appendChild(link);
      // Simulate click to trigger download
      link.click();
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading Pdf:", error);
      this.$message.error("Error downloading Pdf");
    } finally {
      // Reset loading state
      this.downloadLoading = false;
    }
  }
  async handleFilter() {
    var _AdminModule$branchId;
    this.listQuery.page = 1;
    // handle branch and agent code
    // Fetch agents for a single branch
    if ((!(0, _common.isSuperAdmin)() || !(0, _common.isAdmin)()) && ((_AdminModule$branchId = _admin.AdminModule.branchIds) === null || _AdminModule$branchId === void 0 ? void 0 : _AdminModule$branchId.length) === 1) {
      this.listQuery.branchId = _admin.AdminModule.branchIds[0];
    }
    if (this.listQuery.branchId) {
      // Fetch agents and branches for non-superadmin/admin users
      this.agentList = await _branchAgentService.BranchAgentService.fetchAgents(this.listQuery.branchId);
      await _branchAgentService.BranchAgentService.fetchBranches(this.listQuery.branchId);
      // Fetch the list after agents and branches are loaded
      this.getList();
    } else {
      this.getList();
    }
  }
  async fetchAgents() {
    this.agentList = await (0, _agents.getAgents)((0, _index2.getQuery)({
      enabled: true,
      sort: "name,ASC",
      filter: {
        enabled: "eq"
      }
    }));
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "DealerList",
  components: {
    Pagination: _index.default,
    Inr: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;