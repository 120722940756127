"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cashDiscountsSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const cashDiscountsSubMenu = exports.cashDiscountsSubMenu = [{
  path: '/cash-discounts/list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/master/cash-discounts/list.vue'))),
  name: 'cashDiscounts',
  meta: {
    title: 'master.cashDiscounts.title',
    noCache: true,
    icon: 'discount'
  }
}, {
  path: '/cash-discounts/add',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/master/cash-discounts/manage.vue'))),
  name: 'Add Cash Discount',
  props: {
    isEdit: false
  },
  meta: {
    title: 'master.cashDiscounts.add',
    noCache: true,
    activeMenu: '/cash-discounts/list',
    hidden: true
  }
}, {
  path: '/cash-discounts/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/master/cash-discounts/manage.vue'))),
  name: 'Edit Cash Discount',
  props: {
    isEdit: true
  },
  meta: {
    title: 'master.cashDiscounts.edit',
    noCache: true,
    activeMenu: '/cash-discounts/list',
    hidden: true
  }
}];