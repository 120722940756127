"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = _interopRequireDefault(require("@/components/Inr/index.vue"));
var _dealers = require("@/api/dealers");
var _admin = require("@/store/modules/admin");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _dealerWiseCashDiscounts = require("@/api/dealer-wise-cash-discounts");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.isReadOnly = false;
    this.baseUrl = process.env.VUE_APP_BASE_API;
    this.tableKey = 0;
    this.total = 0;
    this.listLoading = false;
    this.downloadLoading = false;
    this.dateRange = "";
    this.dialogVisible = false;
    this.isAgent = false;
    this.dispatchId = 0;
    this.agentList = [];
    this.dealerList = [];
    this.stateList = [];
    this.cityList = [];
    this.dealerListLoading = false;
    this.stateListLoading = false;
    this.cityListLoading = false;
    this.totalGrandTotal = "";
    this.groupedList = [];
    this.reCalculateFlag = false;
    this.listQuery = {
      page: 1,
      limit: 10,
      startDate: undefined,
      endDate: undefined,
      sort: "dealerId,ASC"
    };
    this.sortOptions = [{
      label: "ID Ascending",
      key: "id,ASC"
    }, {
      label: "ID Descending",
      key: "id,DESC"
    }];
    this.loggedInAdmin = null;
  }
  activated() {
    if (_admin.AdminModule.roles[0] === "Read Only") {
      this.isReadOnly = true;
    }
    this.loggedInAdmin = _admin.AdminModule;
    // check if logged in user is Agent/Admin
    if (this.loggedInAdmin.userType === "Agent") {
      this.isAgent = true;
      this.listQuery.agentId = this.loggedInAdmin.agentId;
    }
    this.listQuery = {
      ...this.listQuery,
      ...this.$route.query
    };
    // get dispatch list
    this.getList();
  }
  async getList() {
    try {
      const data = await (0, _dealerWiseCashDiscounts.getDealerWiseCashDiscounts)(this.listQuery);
      console.log(data.groupedData);
      this.groupedList = data.groupedData;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getDealerList(query = null) {
    try {
      const isNumeric = /^[0-9]+$/.test(query);
      // search only if length is greater than 1 if query is id else 2
      if (!query || (isNumeric ? query.length < 1 : query.length < 2)) {
        this.dealerList = [];
        return;
      }
      this.dealerList = await (0, _dealers.searchDealers)({
        keyword: query,
        searchBy: isNumeric ? "dealerId" : "name"
      });
    } catch (error) {} // eslint-disable-line  no-empty
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === "ascending" ? "ASC" : "DESC");
      this.handleFilter();
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? "ascending" : sort === `${key},DESC` ? "descending" : "";
  }
  toggleDialog(id = 0) {
    this.dialogVisible = true;
    this.dispatchId = id;
  }
  async handleRecalculate() {
    try {
      this.reCalculateFlag = true;
      this.listLoading = true;
      const confirm = await this.$confirm("Are you sure to Re-Calculate ?", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        confirmButtonClass: "el-button--success",
        cancelButtonClass: "el-button--danger",
        type: "warning"
      });
      if (confirm === "confirm") {
        const data = await (0, _dealerWiseCashDiscounts.reCalculateCd)();
        this.$message({
          type: "success",
          message: "Re-Calculated successfully"
        });
        // refresh the order
        this.$emit("refresh");
      }
      this.getList();
    } catch (error) {
      this.listLoading = false;
      console.error("Error Recalculating:", error);
      this.$message.error("Error Recalculating");
    }
  }
  async handleExcelDownload() {
    this.downloadLoading = false;
    try {
      const query = this.listQuery;
      const blob = await (0, _dealerWiseCashDiscounts.getDealerWiseCdExcel)(query);
      // Create a blob URL for the Excel file
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "dealer-wise-cd.xlsx");
      document.body.appendChild(link);
      // Simulate click to trigger download
      link.click();
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading Excel:", error);
      this.$message.error("Error downloading Excel");
    } finally {
      // Reset loading state
      this.downloadLoading = false;
    }
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "DealerWiseCashDiscount",
  components: {
    Pagination: _index.default,
    Inr: _index2.default,
    Enabled: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;