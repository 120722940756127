"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateUnit = exports.getUnits = exports.getUnitById = exports.deleteUnit = exports.defaultUnitData = exports.createUnit = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultUnitData = exports.defaultUnitData = {
  id: 0,
  enabled: true,
  name: '',
  code: '',
  alias: '',
  unitType: '',
  sequence: '',
  admin: [],
  branchId: undefined,
  branch: {
    id: undefined
  },
  cityId: undefined,
  city: {
    id: undefined
  },
  stateId: undefined,
  state: {
    id: undefined
  },
  countryId: undefined,
  country: {
    id: undefined
  }
};
const getUnits = params => (0, _request.default)({
  url: '/units',
  method: 'get',
  params
});
exports.getUnits = getUnits;
const getUnitById = id => (0, _request.default)({
  url: `/units/${id}`,
  method: 'get'
});
exports.getUnitById = getUnitById;
const updateUnit = (id, data) => (0, _request.default)({
  url: `/units/${id}`,
  method: 'patch',
  data
});
exports.updateUnit = updateUnit;
const deleteUnit = id => (0, _request.default)({
  url: `/units/${id}`,
  method: 'delete'
});
exports.deleteUnit = deleteUnit;
const createUnit = data => (0, _request.default)({
  url: '/units',
  method: 'post',
  data
});
exports.createUnit = createUnit;