"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _vueClassComponent = require("vue-class-component");
var _app = require("@/store/modules/app");
var _settings = require("@/store/modules/settings");
var _components = require("./components");
var _resize = _interopRequireDefault(require("./mixin/resize"));
let default_1 = class extends (0, _vueClassComponent.mixins)(_resize.default) {
  get classObj() {
    return {
      hideSidebar: !this.sidebar.opened,
      openSidebar: this.sidebar.opened,
      withoutAnimation: this.sidebar.withoutAnimation,
      mobile: this.device === _app.DeviceType.Mobile
    };
  }
  get showSettings() {
    return _settings.SettingsModule.showSettings;
  }
  get showTagsView() {
    return _settings.SettingsModule.showTagsView;
  }
  get fixedHeader() {
    return _settings.SettingsModule.fixedHeader;
  }
  handleClickOutside() {
    _app.AppModule.CloseSideBar(false);
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Layout',
  components: {
    AppMain: _components.AppMain,
    Navbar: _components.Navbar,
    Settings: _components.Settings,
    Sidebar: _components.Sidebar,
    TagsView: _components.TagsView
  }
})], default_1);
var _default = exports.default = default_1;