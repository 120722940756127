"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _SchemeDetails = _interopRequireDefault(require("./components/SchemeDetails.vue"));
let default_1 = class extends _vuePropertyDecorator.Vue {};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ManageScheme',
  components: {
    SchemeDetail: _SchemeDetails.default
  },
  props: ['isEdit']
})], default_1);
var _default = exports.default = default_1;