"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Enabled",
      prop: "enabled"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.formData.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "enabled", $$v);
      },
      expression: "formData.enabled"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Name",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      name: "name",
      required: "",
      placeholder: "Name"
    },
    model: {
      value: _vm.formData.name,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Code",
      prop: "code"
    }
  }, [_c("el-input", {
    attrs: {
      name: "code",
      placeholder: "Unit Code"
    },
    model: {
      value: _vm.formData.code,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "code", $$v);
      },
      expression: "formData.code"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Alias",
      prop: "alias"
    }
  }, [_c("el-input", {
    attrs: {
      name: "alias"
    },
    model: {
      value: _vm.formData.alias,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "alias", $$v);
      },
      expression: "formData.alias"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Unit Type",
      prop: "unitType"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      name: "unitType",
      placeholder: "Select Unit Type"
    },
    model: {
      value: _vm.formData.unitType,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "unitType", $$v);
      },
      expression: "formData.unitType"
    }
  }, _vm._l(_vm.unitTypes, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Managers"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      multiple: "",
      placeholder: "Select Manager"
    },
    model: {
      value: _vm.selectedAdmin,
      callback: function ($$v) {
        _vm.selectedAdmin = $$v;
      },
      expression: "selectedAdmin"
    }
  }, _vm._l(_vm.managers, function (admin) {
    return _c("el-option", {
      key: admin.id,
      attrs: {
        label: admin.fullName + " - " + admin.adminsRole.map(role => role.name).join(", "),
        value: admin.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Sequence",
      prop: "sequence"
    }
  }, [_c("el-input", {
    attrs: {
      name: "sequence"
    },
    model: {
      value: _vm.formData.sequence,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "sequence", $$v);
      },
      expression: "formData.sequence"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;