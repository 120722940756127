"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _transactions = require("@/api/transactions");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _index4 = _interopRequireDefault(require("@/components/Inr/index.vue"));
var _admin = require("@/store/modules/admin");
var _dealers = require("@/api/dealers");
var _branchAgentService = require("@/services/branch-agent-service");
var _common = require("@/common");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.loggedInAdmin = null;
    this.rejectFormVisible = false;
    this.branchListLoading = false;
    this.branchList = [];
    this.rowIndex = 0;
    this.dealerList = [];
    this.dealerListLoading = false;
    this.isAgent = false;
    this.dateRange = "";
    this.startDate = "";
    this.endDate = "";
    this.formData = {
      id: null,
      status: "failure",
      errorMessage: null,
      adminId: null
    };
    this.listQuery = {
      dealerId: undefined,
      page: 1,
      limit: 10,
      createdTimestamp: undefined,
      branchId: undefined,
      sort: "id,DESC",
      filter: {
        dealerId: "$eq",
        createdTimestamp: "between",
        branchId: "$in"
      }
    };
    this.sortOptions = [{
      label: "ID Ascending",
      key: "id,ASC"
    }, {
      label: "ID Descending",
      key: "id,DESC"
    }];
    this.statusColorMap = {
      pending: "primary",
      success: "success",
      failure: "danger"
    };
    this.rules = {
      errorMessage: {
        required: true,
        message: "This field is required",
        trigger: "blur"
      }
    };
  }
  async activated() {
    try {
      this.loggedInAdmin = _admin.AdminModule;
      // check if logged in user is Agent/Admin
      if (this.loggedInAdmin.userType === "Agent") {
        this.isAgent = true;
      }
      // Assign branchId using BranchAgentService
      this.listQuery.branchId = await _branchAgentService.BranchAgentService.assignBranchId();
      // Check if branchId is null and throw an error
      if (this.listQuery.branchId === null) {
        throw new Error("You are not assigned to a branch.");
      }
      // Fetch branches for non-super admin users
      this.branchList = await _branchAgentService.BranchAgentService.fetchBranches(this.listQuery.branchId);
      if (this.$route.query.startDate && this.$route.query.endDate) {
        this.dateRange = {
          start: this.$route.query.startDate,
          end: this.$route.query.endDate
        };
      }
      this.getList();
    } catch (err) {
      if (err instanceof Error) {
        this.$notify.error({
          title: "Error",
          message: err.message
        });
      }
      this.listLoading = false;
    }
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _transactions.getTransactions)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getDealerList(query = null) {
    try {
      const isNumeric = /^[0-9]+$/.test(query);
      // search only if length is greater than 1 if query is id else 2
      if (!query || (isNumeric ? query.length < 1 : query.length < 2)) {
        this.dealerList = [];
        return;
      }
      const searchParams = {
        keyword: query,
        searchBy: isNumeric ? "dealerId" : "name",
        branchId: this.listQuery.branchId !== undefined ? this.listQuery.branchId : _admin.AdminModule.branchIds
      };
      this.dealerList = await (0, _dealers.searchDealers)(searchParams);
    } catch (error) {
      this.dealerListLoading = false;
    }
  }
  toggleRejectFormDialog(row, index = 0) {
    this.rejectFormVisible = true;
    this.formData.id = row.id;
    this.rowIndex = index;
  }
  validateForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  async saveForm() {
    try {
      this.listLoading = true;
      this.rejectFormVisible = false;
      this.formData.adminId = this.loggedInAdmin.id;
      await (0, _transactions.updateTransaction)(this.formData.id, this.formData);
      this.list[this.rowIndex].status = "failure";
      this.$notify({
        title: "Success",
        type: "success",
        message: "Transaction updated",
        duration: 2000
      });
      this.listLoading = false;
    } catch (error) {
      this.$message({
        message: error.toString(),
        type: "error",
        showClose: true
      });
      this.listLoading = false;
    }
  }
  async approveForm(row, index = 0) {
    try {
      this.listLoading = true;
      await (0, _transactions.updateTransaction)(row.id, {
        status: "success"
      });
      this.list[index].status = "success";
      this.$notify({
        title: "Success",
        type: "success",
        message: "Transaction updated",
        duration: 2000
      });
      this.listLoading = false;
    } catch (error) {
      this.$message({
        message: error.toString(),
        type: "error",
        showClose: true
      });
      this.listLoading = false;
    }
  }
  async handleFilter() {
    var _AdminModule$branchId;
    if (this.dateRange) {
      this.startDate = this.dateRange.start;
      this.endDate = this.dateRange.end;
      this.listQuery.createdTimestamp = [this.startDate, this.endDate].join(",");
    } else {
      this.listQuery.createdTimestamp = "";
      this.listQuery.page = 1;
    }
    // handle branch and agent code
    // Fetch agents for a single branch
    if ((!(0, _common.isSuperAdmin)() || !(0, _common.isAdmin)()) && ((_AdminModule$branchId = _admin.AdminModule.branchIds) === null || _AdminModule$branchId === void 0 ? void 0 : _AdminModule$branchId.length) === 1) {
      this.listQuery.branchId = _admin.AdminModule.branchIds[0];
    }
    if (this.listQuery.branchId) {
      // Fetch agents and branches for non-superadmin/admin users
      await _branchAgentService.BranchAgentService.fetchBranches(this.listQuery.branchId);
      // Fetch the list after agents and branches are loaded
      this.getList();
    } else {
      this.getList();
    }
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === "ascending" ? "ASC" : "DESC");
      this.handleFilter();
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? "ascending" : sort === `${key},DESC` ? "descending" : "";
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "TransactionList",
  components: {
    Pagination: _index.default,
    Enabled: _index3.default,
    Inr: _index4.default
  }
})], default_1);
var _default = exports.default = default_1;