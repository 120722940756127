"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      clearable: "",
      remote: "",
      loading: _vm.dealerListLoading,
      "loading-text": "fetching dealers..",
      "remote-method": _vm.getDealerList,
      placeholder: _vm.$t("dealersDispatch.dealer")
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.dealerId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "dealerId", $$v);
      },
      expression: "listQuery.dealerId"
    }
  }, _vm._l(_vm.dealerList, function (dealer) {
    return _c("el-option", {
      key: dealer.id,
      attrs: {
        label: dealer.name,
        value: dealer.id
      }
    }, [_c("span", {
      staticStyle: {
        float: "left",
        "font-size": "13px"
      }
    }, [_vm._v(_vm._s(dealer.name) + " - " + _vm._s(dealer.code))]), _c("span", {
      staticStyle: {
        float: "right",
        color: "#8492a6",
        "font-size": "13px"
      }
    }, [_vm._v("   | ID: " + _vm._s(dealer.id))])]);
  }), 1), !_vm.isReadOnly ? _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-document"
    },
    on: {
      click: _vm.handleExcelDownload
    }
  }, [_vm._v(" Export as Excel ")]) : _vm._e(), !_vm.isReadOnly ? _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "warning",
      size: "mini",
      icon: "el-icon-s-claim"
    },
    on: {
      click: _vm.handleRecalculate
    }
  }, [_vm._v(" Re-Calculate ")]) : _vm._e(), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-search",
      size: "mini"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")])], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.groupedList,
      border: "",
      fit: ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "220",
      align: "center",
      label: "Dealer Name",
      prop: "dealerName"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.dealerName) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "30 Days",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "80",
      align: "center",
      label: "Rate",
      prop: "rate30"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.rate30) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "120",
      align: "center",
      label: "Total Sales",
      prop: "totalSale30"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.totalSale30
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "120",
      align: "center",
      label: "Outstanding",
      prop: "differenceFrom30"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.differenceFrom30
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "120",
      align: "center",
      label: "Expected CD",
      prop: "expectedCashDiscount30"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.expectedCashDiscount30
          }
        })];
      }
    }])
  })], 1), _c("el-table-column", {
    attrs: {
      label: "45 Days",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "80",
      align: "center",
      label: "Rate",
      prop: "rate45"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.rate45) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "120",
      align: "center",
      label: "Total Sales",
      prop: "totalSale45"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.totalSale45
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "120",
      align: "center",
      label: "Outstanding",
      prop: "differenceFrom45"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.differenceFrom45
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "120",
      align: "center",
      label: "Expected CD",
      prop: "expectedCashDiscount45"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.expectedCashDiscount45
          }
        })];
      }
    }])
  })], 1), _c("el-table-column", {
    attrs: {
      label: "60 Days",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "80",
      align: "center",
      label: "Rate",
      prop: "rate60"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.rate60) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "120",
      align: "center",
      label: "Total Sales",
      prop: "totalSale60"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.totalSale60
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "120",
      align: "center",
      label: "Outstanding",
      prop: "differenceFrom60"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.differenceFrom60
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "120",
      align: "center",
      label: "Expected CD",
      prop: "expectedCashDiscount60"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.expectedCashDiscount60
          }
        })];
      }
    }])
  })], 1), _c("el-table-column", {
    attrs: {
      label: "75 Days",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "80",
      align: "center",
      label: "Rate",
      prop: "rate75"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.rate75) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "120",
      align: "center",
      label: "Total Sales",
      prop: "totalSale75"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.totalSale75
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "120",
      align: "center",
      label: "Outstanding",
      prop: "differenceFrom75"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.differenceFrom75
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "120",
      align: "center",
      label: "Expected CD",
      prop: "expectedCashDiscount75"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.expectedCashDiscount75
          }
        })];
      }
    }])
  })], 1), _c("el-table-column", {
    attrs: {
      label: "90 Days",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "80",
      align: "center",
      label: "Rate",
      prop: "rate90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.rate90) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "120",
      align: "center",
      label: "Total Sales",
      prop: "totalSale90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.totalSale90
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "120",
      align: "center",
      label: "Outstanding",
      prop: "differenceFrom90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.differenceFrom90
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "120",
      align: "center",
      label: "Expected CD",
      prop: "expectedCashDiscount90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.expectedCashDiscount90
          }
        })];
      }
    }])
  })], 1)], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;