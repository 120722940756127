import request from '@/utils/request';
export const defaultLedgerData = {
  id: 0,
  dealerId: undefined,
  ordersDispatchId: undefined,
  transactionId: undefined,
  amount: 0,
  action: '',
  paymentType: null,
  status: null,
  dealer: {
    id: null
  },
  ordersDispatch: {
    id: null
  },
  transaction: {
    id: null
  },
  quantity: undefined,
  productId: undefined,
  productsVariationId: undefined,
  CnDnProducts: [],
  isCnDn: false,
  isNew: false,
  dealersTransporterId: undefined
};
export const getLedgers = params => request({
  url: '/ledgers',
  method: 'get',
  params
});
export const getLedgerById = id => request({
  url: `/ledgers/${id}`,
  method: 'get'
});
export const updateLedger = (id, data) => request({
  url: `/ledgers/${id}`,
  method: 'patch',
  data
});
export const deleteLedger = id => request({
  url: `/ledgers/${id}`,
  method: 'delete'
});
export const createLedger = data => request({
  url: '/ledgers/',
  method: 'post',
  data
});
export const getLedgerList = data => request({
  url: '/ledgers/list',
  method: 'post',
  data
});
export const addLedgerTransaction = data => request({
  url: '/ledgers/add',
  method: 'post',
  data
});
export const downloadExcel = data => request({
  url: '/ledgers/download-excel',
  method: 'post',
  data,
  responseType: 'blob'
});