"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _index = _interopRequireDefault(require("@/components/Enabled/index.vue"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.billingAddress = '';
    this.deliveryAddress = '';
  }
  created() {
    if (this.data.billingAddress) {
      this.billingAddress = this.getAddress(this.data.billingAddress);
    }
    if (this.data.deliveryAddress) {
      this.deliveryAddress = this.getAddress(this.data.deliveryAddress);
    }
  }
  getAddress(address) {
    let fullAaddress = '';
    if (address.id) {
      fullAaddress += 'ID: ' + address.id + ' <br />';
    }
    if (address.line1) {
      fullAaddress += 'Line 1: ' + address.line1 + ' <br />';
    }
    if (address.line2) {
      fullAaddress += 'Line 2: ' + address.line2 + ' ' + '<br />';
    }
    if (address.city) {
      fullAaddress += 'City: ' + address.cityName + ', <br />';
    }
    if (address.state) {
      fullAaddress += 'State: ' + address.state.name + ', <br />';
    }
    if (address.pincode) {
      fullAaddress += 'Pincode: ' + address.pincode.pincode + ' ' + '<br />';
    }
    if (address.gstNumber) {
      fullAaddress += 'GST: ' + address.gstNumber + ' ' + '<br />';
    }
    if (address.panNumber) {
      fullAaddress += 'PAN: ' + address.panNumber + ' ' + '<br />';
    }
    return fullAaddress;
  }
  getAddress_(address) {
    const addressArray = [];
    if (address.name) {
      addressArray.push(address.name);
    }
    if (address.line1) {
      addressArray.push(address.line1);
    }
    if (address.line2) {
      addressArray.push(address.line2);
    }
    if (address.city) {
      addressArray.push(address.city.name);
    }
    if (address.state) {
      addressArray.push(address.state.name);
    }
    if (address.country) {
      addressArray.push(address.country.name);
    }
    if (address.pincode) {
      addressArray.push(address.pincode.pincode);
    }
    if (address.gstNumber) {
      addressArray.push('GST: ' + address.gstNumber);
    }
    if (address.panNumber) {
      addressArray.push('PAN: ' + address.panNumber);
    }
    return addressArray.join(', ');
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: {}
})], default_1.prototype, "data", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'OrderUserTab',
  components: {
    Enabled: _index.default
  }
})], default_1);
var _default = exports.default = default_1;