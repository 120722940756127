"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _admin = require("@/store/modules/admin");
var _index = _interopRequireDefault(require("@/components/PanThumb/index.vue"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.emptyGif = 'https://wpimg.wallstcn.com/0e03b7da-db9e-4819-ba10-9016ddfdaed3';
  }
  get name() {
    return _admin.AdminModule.name;
  }
  get avatar() {
    return _admin.AdminModule.avatar;
  }
  get roles() {
    return _admin.AdminModule.roles;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'DashboardExecutive',
  components: {
    PanThumb: _index.default
  }
})], default_1);
var _default = exports.default = default_1;