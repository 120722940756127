import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
import { defaultLedgerData, getLedgerById, addLedgerTransaction } from "@/api/ledgers";
import { AppModule } from "@/store/modules/app";
import { TagsViewModule } from "@/store/modules/tags-view";
import router from "@/router";
import { Action } from "vuex-module-decorators";
import { getDealerById, searchDealers } from "@/api/dealers";
import { getQuery } from "@/utils";
import { searchDispatchedProducts, searchProducts } from "@/api/products";
import { searchEntryNumber } from "@/api/orders-dispatches";
import { getDealersTransporters } from "@/api/dealers-transporters";
let default_1 = class default_1 extends Vue {
  constructor() {
    super(...arguments);
    this.formData = Object.assign({}, defaultLedgerData);
    this.loading = false;
    this.dealerListLoading = false;
    this.dealerList = [];
    this.amountInWords = "";
    this.productListLoading = false;
    this.products = [];
    this.entryNumberListLoading = false;
    this.entryNumbers = [];
    this.quantity = undefined;
    this.listLoading = false;
    this.selectedVariationId = null;
    this.ordersProducts = [];
    this.transporterList = [];
    this.rules = {
      dealerId: {
        required: true,
        message: "This field is required",
        trigger: "blur"
      },
      amount: {
        required: true,
        message: "This field is required",
        trigger: "blur"
      },
      paymentType: {
        required: true,
        message: "This field is required",
        trigger: "blur"
      },
      comments: {
        required: false,
        message: "This field is required",
        trigger: "blur"
      },
      paymentMode: {
        required: true,
        message: "This field is required",
        trigger: "blur"
      },
      transactionReference: {
        required: true,
        message: "This field is required",
        trigger: "blur"
      }
    };
    this.paymentTypeOptions = [{
      label: "Cash in Bank",
      key: "Cash in Bank"
    }, {
      label: "Cheque",
      key: "Cheque"
    }, {
      label: "Net Banking",
      key: "Net Banking"
    }, {
      label: "Credit Note",
      key: "Credit Note"
    }, {
      label: "Debit Note",
      key: "Debit Note"
    }, {
      label: "Credit Note-Item Wise",
      key: "Credit Note-Item Wise"
    }, {
      label: "Debit Note-Item Wise",
      key: "Debit Note-Item Wise"
    }];
  }
  get lang() {
    return AppModule.language;
  }
  activated() {
    console.log("in activated");
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, defaultLedgerData);
    }
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await getLedgerById(id);
      this.formData = data;
      // mark as journal entry
      this.formData.paymentType = "Journal Note";
      // fetch mapped dealer
      const dealer = await getDealerById(data.dealerId);
      this.dealerList = [dealer];
      // Just for test
      const title = "Ledger";
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getDealerList(query = null) {
    try {
      const isNumeric = /^[0-9]+$/.test(query);
      // search only if length is greater than 1 if query is id else 2
      if (!query || (isNumeric ? query.length < 1 : query.length < 2)) {
        this.dealerList = [];
        return;
      }
      this.dealerList = await searchDealers({
        keyword: query,
        searchBy: isNumeric ? "dealerId" : "name"
      });
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async handleDealerSelection() {
    if (this.formData.dealerId) {
      this.fetchTransporterList(this.formData.dealerId);
    }
  }
  async fetchTransporterList(dealerId = 0) {
    console.log("fetching transporter");
    // reset transporter ID
    this.formData.dealersTransporterId = null;
    // fetch dealers transporters
    this.transporterList = await getDealersTransporters(getQuery({
      enabled: true,
      dealerId,
      sort: "isPrimary,ASC",
      filter: {
        enabled: "$eq",
        dealerId: "$eq"
      }
    }));
    // if length is 1 show selected by default
    // switch (this.transporterList.length) {
    //   case 0:
    //     this.formData.dealersTransporterId = null;
    //     break;
    //   case 1:
    //     this.formData.dealersTransporterId = this.transporterList[0].id;
    //     break;
    //   default:
    //     break;
    // }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  mounted() {
    this.updateAmountInWords(this.formData.amount);
  }
  numberToWords(num) {
    const a = ["", "one ", "two ", "three ", "four ", "five ", "six ", "seven ", "eight ", "nine ", "ten ", "eleven ", "twelve ", "thirteen ", "fourteen ", "fifteen ", "sixteen ", "seventeen ", "eighteen ", "nineteen "];
    const b = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];
    if ((num = num.toString()).length > 9) return "";
    const n = ("000000000" + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str += n[1] != 0 ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore " : "";
    str += n[2] != 0 ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh " : "";
    str += n[3] != 0 ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand " : "";
    str += n[4] != 0 ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred " : "";
    str += n[5] != 0 ? (str != "" ? "and " : "") + (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) : "";
    return str;
  }
  updateAmountInWords(amount) {
    this.amountInWords = this.numberToWords(amount);
  }
  async _getProductList(query) {
    try {
      this.productListLoading = true;
      if (query && query.length > 2) {
        this.products = await searchProducts(getQuery({
          keyword: query
        }));
      }
      this.productListLoading = false;
    } catch (err) {
      this.productListLoading = false;
      throw err;
    }
  }
  async addVariation(value) {
    let selected = {
      productId: null,
      hsnCode: null,
      productsVariationId: null,
      name: null,
      variationName: null,
      nettRate: null,
      amount: null,
      quantity: null
    };
    this.products.map(product => {
      product.productsVariation.map(item => {
        if (item.id === this.selectedVariationId) {
          selected = {
            productId: product.id,
            hsnCode: product.hsnCode,
            productsVariationId: item.id,
            name: product.name,
            variationName: item.name,
            nettRate: item.mrp
          };
        }
      });
    });
    this.ordersProducts.push(selected);
  }
  async getProductList(ordersDispatchId) {
    try {
      this.productListLoading = true;
      this.products = await searchDispatchedProducts(getQuery({
        ordersDispatchId
      }));
      this.productListLoading = false;
    } catch (err) {
      this.productListLoading = false;
      throw err;
    }
  }
  async handleEntryNumber(value) {
    if (value) {
      await this.getProductList(value);
    }
  }
  async getEntryNumberList(query) {
    try {
      this.entryNumberListLoading = true;
      if (query && query.length > 2) {
        this.entryNumbers = await searchEntryNumber(getQuery({
          keyword: query,
          dealerId: this.formData.dealerId
        }));
      }
      this.entryNumberListLoading = false;
    } catch (err) {
      this.entryNumberListLoading = false;
      throw err;
    }
  }
  handleQuantity(index) {
    if (!this.formData.isNew) {
      console.log("in if");
      this.$set(this.products[index], "CnDnQuantity", this.quantity);
      if (this.quantity) {
        this.$set(this.products[index], "CnDnAmount", parseFloat(this.products[index].nettRate) * parseFloat(this.quantity));
      }
      this.formData.CnDnProducts.push({
        productId: this.products[index].productId,
        hsnCode: this.products[index].hsnCode,
        productsVariationId: this.products[index].productsVariationId,
        name: this.products[index].productName,
        nettRate: this.products[index].nettRate,
        amount: this.products[index].CnDnAmount.toFixed(2),
        quantity: this.products[index].CnDnQuantity
      });
    } else {
      console.log("in else");
      console.log(this.ordersProducts[index].quantity);
      //this.ordersProducts[index].quantity = this.quantity;
      this.ordersProducts[index].amount = parseFloat(this.ordersProducts[index].nettRate) * parseFloat(this.ordersProducts[index].quantity);
      this.formData.CnDnProducts = this.ordersProducts;
    }
    console.log(this.formData.CnDnProducts);
  }
  async saveForm() {
    try {
      this.loading = true;
      if (this.isEdit) {
        // delete id to create new journal entry
        delete this.formData.id;
        // await updateLedger(this.formData.id, this.formData);
      } else {
        // await createLedger(this.formData);
        // set ledger action as per payment type
        if (this.formData.paymentType === "Credit Note") {
          this.formData.action = "credit"; // reversed
          this.formData.status = "Pending";
        } else if (this.formData.paymentType === "Debit Note") {
          this.formData.action = "debit"; // reversed
        } else if (this.formData.paymentType === "Online") {
          this.formData.status = "pending";
          this.formData.paymentType = "online";
        } else if (this.formData.paymentType === "Cash in Bank") {
          this.formData.status = "pending";
          this.formData.paymentType = "offline";
          this.formData.paymentMode = "cash in bank";
        } else if (this.formData.paymentType === "Cheque") {
          this.formData.status = "pending";
          this.formData.paymentType = "offline";
          this.formData.paymentMode = "cheque";
        } else if (this.formData.paymentType === "Net Banking") {
          this.formData.status = "pending";
          this.formData.paymentType = "offline";
        } else if (this.formData.paymentType === "Credit Note-Item Wise") {
          this.formData.status = "Pending";
          this.formData.action = "credit";
          this.formData.isCnDn = true;
        } else if (this.formData.paymentType === "Debit Note-Item Wise") {
          this.formData.action = "debit";
          this.formData.isCnDn = true;
        }
      }
      this.formData.dealer = {
        id: this.formData.dealerId
      };
      if (this.formData.ordersDispatchId) {
        this.formData.ordersDispatch.id = this.formData.ordersDispatchId;
      }
      if (this.formData.CnDnProducts.length !== 0) {
        this.formData.CnDnProducts.forEach(item => {
          this.formData.amount += parseFloat(item.amount);
        });
      }
      if (this.formData.paymentType === "Credit Note-Item Wise" || this.formData.paymentType === "Debit Note-Item Wise") {
        this.$confirm("Saving This Will Create E-Invoice!", "Create E-Invoice", {
          confirmButtonText: "Yes",
          confirmButtonClass: "el-button--success",
          cancelButtonText: "No",
          cancelButtonClass: "el-button--danger",
          type: "warning"
        }).then(async () => {
          await addLedgerTransaction(this.formData);
          this.loading = false;
          this.$notify({
            title: "Success",
            message: "Ledger saved",
            type: "success",
            duration: 2000
          });
          router.push("/ledgers/list");
        }).catch(() => {
          this.$message({
            type: "info",
            message: "Request Cancel"
          });
        });
      } else {
        await addLedgerTransaction(this.formData);
        this.loading = false;
        this.$notify({
          title: "Success",
          message: "Ledger saved",
          type: "success",
          duration: 2000
        });
        router.push("/ledgers/list");
      }
    } catch (err) {
      this.loading = false;
    }
  }
};
__decorate([Prop({
  default: false
})], default_1.prototype, "isEdit", void 0);
__decorate([Action], default_1.prototype, "saveForm", null);
default_1 = __decorate([Component({
  name: "LedgerDetail",
  components: {}
})], default_1);
export default default_1;