"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Enabled",
      prop: "enabled"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.formData.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "enabled", $$v);
      },
      expression: "formData.enabled"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "TCS Applicable",
      prop: "tcsApplicable"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.formData.tcsApplicable,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "tcsApplicable", $$v);
      },
      expression: "formData.tcsApplicable"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Monthly CD Applicable",
      prop: "isMonthlyCashDiscountApplicable"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.formData.isMonthlyCashDiscountApplicable,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "isMonthlyCashDiscountApplicable", $$v);
      },
      expression: "formData.isMonthlyCashDiscountApplicable"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Name",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      name: "name",
      required: "",
      placeholder: "Name"
    },
    model: {
      value: _vm.formData.name,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Code",
      prop: "code"
    }
  }, [_c("el-input", {
    attrs: {
      name: "code",
      placeholder: "code"
    },
    model: {
      value: _vm.formData.code,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "code", $$v);
      },
      expression: "formData.code"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Private Marka",
      prop: "privateMarka"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Private marka"
    },
    model: {
      value: _vm.formData.privateMarka,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "privateMarka", $$v);
      },
      expression: "formData.privateMarka"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Logo",
      prop: "logo"
    }
  }, [_c("file-upload", {
    attrs: {
      url: "dealers/upload",
      multiple: false,
      "file-list": _vm.logo
    },
    on: {
      change: _vm.fileUploaded
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Role",
      prop: "dealersRoleId"
    }
  }, [_c("el-select", {
    attrs: {
      disabled: _vm.isEdit && !_vm.isSuperAdmin,
      filterable: "",
      name: "dealersRoleId",
      placeholder: "Select"
    },
    model: {
      value: _vm.formData.dealersRoleId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "dealersRoleId", $$v);
      },
      expression: "formData.dealersRoleId"
    }
  }, _vm._l(_vm.roleList, function (role) {
    return _c("el-option", {
      key: role.id,
      attrs: {
        label: role.name,
        value: role.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Agents",
      required: ""
    }
  }, [_c("el-select", {
    attrs: {
      disabled: _vm.isEdit && !_vm.isSuperAdmin,
      filterable: "",
      name: "agentId",
      placeholder: "Select"
    },
    model: {
      value: _vm.formData.agentId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "agentId", $$v);
      },
      expression: "formData.agentId"
    }
  }, _vm._l(_vm.agentList, function (agent) {
    return _c("el-option", {
      key: agent.id,
      attrs: {
        label: agent.name,
        value: agent.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Branch",
      required: ""
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      name: "branchId",
      placeholder: "Select"
    },
    model: {
      value: _vm.formData.branchId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "branchId", $$v);
      },
      expression: "formData.branchId"
    }
  }, _vm._l(_vm.branchList, function (branch) {
    return _c("el-option", {
      key: branch.id,
      attrs: {
        label: branch.name + " - " + branch.code,
        value: branch.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Schemes"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      name: "selectedSchemes",
      multiple: "",
      placeholder: "Select"
    },
    model: {
      value: _vm.selectedSchemes,
      callback: function ($$v) {
        _vm.selectedSchemes = $$v;
      },
      expression: "selectedSchemes"
    }
  }, _vm._l(_vm.schemesList, function (scheme) {
    return _c("el-option", {
      key: scheme.id,
      attrs: {
        label: scheme.name,
        value: scheme.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Status",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Status"
    },
    model: {
      value: _vm.formData.status,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "status", $$v);
      },
      expression: "formData.status"
    }
  }, _vm._l(["Approved", "Blocked", "Hold"], function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1)], 1), _vm.formData.status === "Hold" ? _c("el-form-item", {
    attrs: {
      label: "Hold Reason",
      prop: "holdReason"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 3,
      placeholder: "Reason for hold..."
    },
    model: {
      value: _vm.formData.holdReason,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "holdReason", $$v);
      },
      expression: "formData.holdReason"
    }
  })], 1) : _vm._e(), _vm.formData.status === "Approved" ? _c("el-form-item", {
    attrs: {
      label: "Credit Limit",
      prop: "creditLimit"
    }
  }, [_c("el-input-number", {
    attrs: {
      precision: 2,
      step: 1
    },
    model: {
      value: _vm.formData.creditLimit,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "creditLimit", $$v);
      },
      expression: "formData.creditLimit"
    }
  })], 1) : _vm._e(), _vm.formData.status === "Approved" ? _c("el-form-item", {
    attrs: {
      label: "Credit Period (days)",
      prop: "creditPeriod"
    }
  }, [_c("el-input-number", {
    attrs: {
      precision: 2,
      step: 1
    },
    model: {
      value: _vm.formData.creditPeriod,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "creditPeriod", $$v);
      },
      expression: "formData.creditPeriod"
    }
  })], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "Discount (%)",
      prop: "discount"
    }
  }, [_c("el-input-number", {
    attrs: {
      precision: 2,
      step: 1,
      max: 100
    },
    model: {
      value: _vm.formData.discount,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "discount", $$v);
      },
      expression: "formData.discount"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;