"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _path = _interopRequireDefault(require("path"));
var _vuePropertyDecorator = require("vue-property-decorator");
var _permission = require("@/store/modules/permission");
var _tagsView = require("@/store/modules/tags-view");
var _ScrollPane = _interopRequireDefault(require("./ScrollPane.vue"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.visible = false;
    this.top = 0;
    this.left = 0;
    this.selectedTag = {};
    this.affixTags = [];
  }
  get visitedViews() {
    return _tagsView.TagsViewModule.visitedViews;
  }
  get routes() {
    return _permission.PermissionModule.routes;
  }
  onRouteChange() {
    this.addTags();
    this.moveToCurrentTag();
  }
  onVisibleChange(value) {
    if (value) {
      document.body.addEventListener('click', this.closeMenu);
    } else {
      document.body.removeEventListener('click', this.closeMenu);
    }
  }
  mounted() {
    this.initTags();
    this.addTags();
  }
  isActive(route) {
    return route.path === this.$route.path;
  }
  isAffix(tag) {
    return tag.meta && tag.meta.affix;
  }
  filterAffixTags(routes, basePath = '/') {
    let tags = [];
    routes.forEach(route => {
      if (route.meta && route.meta.affix) {
        const tagPath = _path.default.resolve(basePath, route.path);
        tags.push({
          fullPath: tagPath,
          path: tagPath,
          name: route.name,
          meta: {
            ...route.meta
          }
        });
      }
      if (route.children) {
        const childTags = this.filterAffixTags(route.children, route.path);
        if (childTags.length >= 1) {
          tags = [...tags, ...childTags];
        }
      }
    });
    return tags;
  }
  initTags() {
    this.affixTags = this.filterAffixTags(this.routes);
    for (const tag of this.affixTags) {
      // Must have tag name
      if (tag.name) {
        _tagsView.TagsViewModule.addVisitedView(tag);
      }
    }
  }
  addTags() {
    const {
      name
    } = this.$route;
    if (name) {
      _tagsView.TagsViewModule.addView(this.$route);
    }
    return false;
  }
  moveToCurrentTag() {
    const tags = this.$refs.tag; // TODO: better typescript support for router-link
    this.$nextTick(() => {
      for (const tag of tags) {
        if (tag.to.path === this.$route.path) {
          this.$refs.scrollPane.moveToTarget(tag);
          // When query is different then update
          if (tag.to.fullPath !== this.$route.fullPath) {
            _tagsView.TagsViewModule.updateVisitedView(this.$route);
          }
          break;
        }
      }
    });
  }
  refreshSelectedTag(view) {
    _tagsView.TagsViewModule.delCachedView(view);
    const {
      fullPath
    } = view;
    this.$nextTick(() => {
      this.$router.replace({
        path: '/redirect' + fullPath
      });
    });
  }
  closeSelectedTag(view) {
    _tagsView.TagsViewModule.delView(view);
    if (this.isActive(view)) {
      this.toLastView(_tagsView.TagsViewModule.visitedViews, view);
    }
  }
  closeOthersTags() {
    this.$router.push(this.selectedTag);
    _tagsView.TagsViewModule.delOthersViews(this.selectedTag);
    this.moveToCurrentTag();
  }
  closeAllTags(view) {
    _tagsView.TagsViewModule.delAllViews();
    if (this.affixTags.some(tag => tag.path === this.$route.path)) {
      return;
    }
    this.toLastView(_tagsView.TagsViewModule.visitedViews, view);
  }
  toLastView(visitedViews, view) {
    const latestView = visitedViews.slice(-1)[0];
    if (latestView) {
      this.$router.push(latestView);
    } else {
      // Default redirect to the home page if there is no tags-view, adjust it if you want
      if (view.name === 'Dashboard') {
        // to reload home page
        this.$router.replace({
          path: '/redirect' + view.fullPath
        });
      } else {
        this.$router.push('/');
      }
    }
  }
  openMenu(tag, e) {
    const menuMinWidth = 105;
    const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
    const offsetWidth = this.$el.offsetWidth; // container width
    const maxLeft = offsetWidth - menuMinWidth; // left boundary
    const left = e.clientX - offsetLeft + 15; // 15: margin right
    if (left > maxLeft) {
      this.left = maxLeft;
    } else {
      this.left = left;
    }
    this.top = e.clientY;
    this.visible = true;
    this.selectedTag = tag;
  }
  closeMenu() {
    this.visible = false;
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Watch)('$route')], default_1.prototype, "onRouteChange", null);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Watch)('visible')], default_1.prototype, "onVisibleChange", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'TagsView',
  components: {
    ScrollPane: _ScrollPane.default
  }
})], default_1);
var _default = exports.default = default_1;