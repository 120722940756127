"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.newItemWiseReportSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const newItemWiseReportSubMenu = exports.newItemWiseReportSubMenu = [{
  path: '/report/newItemWiseReport/list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/report/new-item-wise-report/list.vue'))),
  name: 'report.title',
  meta: {
    title: 'report.newItemWiseReport.title',
    roles: ['Super Admin', 'Admin'],
    noCache: true,
    hidden: false,
    icon: 'form'
  }
}];