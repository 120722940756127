"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _dealers = require("@/api/dealers");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _index4 = _interopRequireDefault(require("@/components/Inr/index.vue"));
var _states = require("@/api/locations/states");
var _cities = require("@/api/locations/cities");
var _admin = require("@/store/modules/admin");
var _common = require("@/common");
var _branchAgentService = require("@/services/branch-agent-service");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.isReadOnly = false;
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.branchListLoading = false;
    this.branchList = [];
    this.isAgent = false;
    this.agentList = [];
    this.stateList = [];
    this.cityList = [];
    this.dealerList = [];
    this.dealerListLoading = false;
    this.statusColorMap = {
      Pending: "info",
      Approved: "success",
      Blocked: "danger",
      Hold: "warning"
    };
    this.listQuery = {
      page: 1,
      limit: 10,
      id: undefined,
      enabled: true,
      code: undefined,
      status: undefined,
      agentId: undefined,
      branchId: undefined,
      "address.stateId": undefined,
      "address.cityId": undefined,
      sort: "id,ASC",
      filter: {
        id: "$eq",
        enabled: "$eq",
        code: "$startsL",
        status: "$eq",
        agentId: "$eq",
        branchId: "$in",
        "address.stateId": "$eq",
        "address.cityId": "$eq"
      }
    };
    this.sortOptions = [{
      label: "ID Ascending",
      key: "id,ASC"
    }, {
      label: "ID Descending",
      key: "id,DESC"
    }];
    this.enableTypeOptions = [{
      key: true,
      value: "Enable"
    }, {
      key: false,
      value: "Disabled"
    }];
  }
  async activated() {
    try {
      if (_admin.AdminModule.roles.includes("Read Only")) {
        this.isReadOnly = true;
      }
      // Assign branchId using BranchAgentService
      this.listQuery.branchId = await _branchAgentService.BranchAgentService.assignBranchId();
      // Check if branchId is null and throw an error
      if (this.listQuery.branchId === null) {
        throw new Error("You are not assigned to a branch.");
      }
      // Fetch agents for the assigned branchId
      this.agentList = await _branchAgentService.BranchAgentService.fetchAgents(this.listQuery.branchId);
      // Fetch branches for non-super admin users
      this.branchList = await _branchAgentService.BranchAgentService.fetchBranches(this.listQuery.branchId);
      // check if logged in user is Agent/Admin
      if (_admin.AdminModule.userType === "Agent") {
        this.isAgent = true;
        this.listQuery.agentId = _admin.AdminModule.agentId;
      }
      await this.getList();
      // get state list
      this.fetchStates();
    } catch (err) {
      if (err instanceof Error) {
        this.$notify.error({
          title: "Error",
          message: err.message
        });
      }
      this.listLoading = false;
    }
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _dealers.getDealers)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async handleFilter() {
    var _AdminModule$branchId;
    // Check if stateId exists in listQuery and fetch cities
    if (this.listQuery["address.stateId"]) {
      this.fetchCitiesByState(this.listQuery["address.stateId"]);
    }
    // Reset the page to 1 on filter change
    this.listQuery.page = 1;
    // handle branch and agent code
    // Fetch agents for a single branch
    if ((!(0, _common.isSuperAdmin)() || !(0, _common.isAdmin)()) && ((_AdminModule$branchId = _admin.AdminModule.branchIds) === null || _AdminModule$branchId === void 0 ? void 0 : _AdminModule$branchId.length) === 1) {
      this.listQuery.branchId = _admin.AdminModule.branchIds[0];
    }
    if (this.listQuery.branchId) {
      // Fetch agents and branches for non-superadmin/admin users
      this.agentList = await _branchAgentService.BranchAgentService.fetchAgents(this.listQuery.branchId);
      await _branchAgentService.BranchAgentService.fetchBranches(this.listQuery.branchId);
      // Fetch the list after agents and branches are loaded
      this.getList();
    } else {
      this.getList();
    }
  }
  hasNoAccess(requiredRoles) {
    return (0, _common.isExcludedToAccess)(requiredRoles);
  }
  sortChange(data) {
    this.handleFilter();
  }
  async fetchStates() {
    this.stateList = await (0, _states.getStates)((0, _index2.getQuery)({
      enabled: true,
      sort: "name,ASC",
      filter: {
        enabled: "eq"
      }
    }));
  }
  async fetchCitiesByState(stateId = 0) {
    this.cityList = await (0, _cities.getCities)((0, _index2.getQuery)({
      enabled: true,
      stateId,
      sort: "name,ASC",
      filter: {
        enabled: "eq",
        stateId: "$eq"
      }
    }));
  }
  async handleDownload() {
    this.downloadLoading = true;
    const tHeader = ["ID", "Name", "Code"];
    const filterVal = ["id", "name", "code"];
    const agentFilter = Object.assign({}, this.listQuery);
    delete agentFilter.limit;
    delete agentFilter.page;
    const dealers = await (0, _dealers.getDealers)((0, _index2.getQuery)(agentFilter));
    const data = (0, _index2.formatJson)(filterVal, dealers);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc("route.dealerManagement.dealer.title"));
    this.downloadLoading = false;
  }
  async getDealerList(query = null) {
    try {
      const isNumeric = /^[0-9]+$/.test(query);
      // search only if length is greater than 1 if query is id else 2
      if (!query || (isNumeric ? query.length < 1 : query.length < 2)) {
        this.dealerList = [];
        return;
      }
      const searchParams = {
        keyword: query,
        searchBy: isNumeric ? "dealerId" : "name",
        branchId: this.listQuery.branchId !== undefined ? this.listQuery.branchId : _admin.AdminModule.branchIds
      };
      this.dealerList = await (0, _dealers.searchDealers)(searchParams);
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async handleExcelDownload() {
    this.downloadLoading = false;
    try {
      const queryWithoutPagination = {
        ...this.listQuery
      };
      delete queryWithoutPagination.page;
      delete queryWithoutPagination.limit;
      const blob = await (0, _dealers.downloadExcel)(queryWithoutPagination);
      // Create a blob URL for the Excel file
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "dealers.xlsx");
      document.body.appendChild(link);
      // Simulate click to trigger download
      link.click();
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading Excel:", error);
      this.$message.error("Error downloading Excel");
    } finally {
      // Reset loading state
      this.downloadLoading = false;
    }
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "DealerList",
  components: {
    Pagination: _index.default,
    Enabled: _index3.default,
    Inr: _index4.default
  }
})], default_1);
var _default = exports.default = default_1;