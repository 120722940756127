import { render, staticRenderFns } from "./ProductCategoryTab.vue?vue&type=template&id=1879b930&scoped=true"
import script from "./ProductCategoryTab.vue?vue&type=script&lang=ts"
export * from "./ProductCategoryTab.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1879b930",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\eiosys-work\\eiosys-frontend\\Cona-Admin\\Cona-Electrical-Admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1879b930')) {
      api.createRecord('1879b930', component.options)
    } else {
      api.reload('1879b930', component.options)
    }
    module.hot.accept("./ProductCategoryTab.vue?vue&type=template&id=1879b930&scoped=true", function () {
      api.rerender('1879b930', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/products/components/ProductCategoryTab.vue"
export default component.exports