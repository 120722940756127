"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.schemesDiscountSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const schemesDiscountSubMenu = exports.schemesDiscountSubMenu = [{
  path: '/schemes-discounts/list/:schemeId(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/schemes-discounts/list.vue'))),
  name: 'schemeManagement.schemesDiscount.title',
  meta: {
    roles: ['Super Admin', 'Admin', 'Read Only'],
    title: 'schemeManagement.schemesDiscount.title',
    noCache: true,
    icon: 'auction',
    hidden: true
  }
}, {
  path: '/schemes-discounts/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/schemes-discounts/manage.vue'))),
  name: 'schemeManagement.schemesDiscount.edit',
  props: {
    isEdit: true
  },
  meta: {
    title: 'schemeManagement.schemesDiscount.edit',
    noCache: true,
    activeMenu: '/schemes-discounts/list/:schemeId(\\d+)',
    hidden: true
  }
}, {
  path: '/schemes-discounts/add/:schemeId(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/schemes-discounts/manage.vue'))),
  name: 'schemeManagement.schemesDiscount.add',
  props: {
    isEdit: false
  },
  meta: {
    title: 'schemeManagement.schemesDiscount.add',
    noCache: true,
    activeMenu: '/schemes-discounts/list/:schemeId(\\d+)',
    hidden: true
  }
}];