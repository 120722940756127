"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("el-input", {
    staticClass: "filter-item",
    attrs: {
      placeholder: _vm.$t("order.name")
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleFilter.apply(null, arguments);
      }
    },
    model: {
      value: _vm.listQuery.orderReference,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "orderReference", $$v);
      },
      expression: "listQuery.orderReference"
    }
  }), _c("el-select", {
    attrs: {
      filterable: "",
      clearable: "",
      loading: _vm.branchListLoading,
      name: "branchId",
      placeholder: "Select Branch"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.branchId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "branchId", $$v);
      },
      expression: "listQuery.branchId"
    }
  }, _vm._l(_vm.branchList, function (branch) {
    return _c("el-option", {
      key: branch.id,
      attrs: {
        label: branch.code,
        value: branch.id
      }
    });
  }), 1), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      clearable: "",
      remote: "",
      loading: _vm.dealerListLoading,
      "loading-text": "fetching dealers..",
      "remote-method": _vm.getDealerList,
      placeholder: _vm.$t("order.dealer")
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.dealerId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "dealerId", $$v);
      },
      expression: "listQuery.dealerId"
    }
  }, _vm._l(_vm.dealerList, function (dealer) {
    return _c("el-option", {
      key: dealer.id,
      attrs: {
        label: dealer.name,
        value: dealer.id
      }
    }, [_c("span", {
      staticStyle: {
        float: "left",
        "font-size": "14px"
      }
    }, [_vm._v(_vm._s(dealer.name) + " - " + _vm._s(dealer.code))]), _c("span", {
      staticStyle: {
        float: "right",
        color: "#8492a6",
        "font-size": "14px"
      }
    }, [_vm._v("   | ID: " + _vm._s(dealer.id))])]);
  }), 1), !_vm.isAgent ? _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      clearable: "",
      loading: _vm.agentListLoading,
      "loading-text": "fetching agents..",
      placeholder: _vm.$t("order.agent")
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.agentId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "agentId", $$v);
      },
      expression: "listQuery.agentId"
    }
  }, _vm._l(_vm.agentList, function (agent) {
    return _c("el-option", {
      key: agent.id,
      attrs: {
        label: agent.name,
        value: agent.id
      }
    }, [_vm._v(" " + _vm._s(agent.name) + " - " + _vm._s(agent.code) + " ")]);
  }), 1) : _vm._e(), _c("el-select", {
    staticStyle: {
      "margin-right": "8px"
    },
    attrs: {
      filterable: "",
      remote: "",
      clearable: "",
      "remote-method": _vm.getProductList,
      loading: _vm.productListLoading,
      placeholder: "Select Product",
      size: "mini"
    },
    on: {
      change: _vm.handleProductSelection
    },
    model: {
      value: _vm.selectedProduct,
      callback: function ($$v) {
        _vm.selectedProduct = $$v;
      },
      expression: "selectedProduct"
    }
  }, _vm._l(_vm.products, function (product) {
    return _c("el-option-group", {
      key: product.id,
      attrs: {
        label: "Main Product"
      }
    }, [_c("el-option", {
      key: "product-" + product.id,
      attrs: {
        label: product.name,
        value: "product-" + product.id
      }
    }, [_c("span", {
      staticStyle: {
        "font-weight": "bold"
      }
    }, [_vm._v(_vm._s(product.name))])]), product.productType !== "simple" && product.productsVariation && product.productsVariation.length ? [_c("el-option", {
      key: "variations-label-" + product.id,
      attrs: {
        label: "Variations",
        disabled: ""
      }
    }, [_c("span", {
      staticStyle: {
        "font-style": "italic",
        color: "gray"
      }
    }, [_vm._v("Variations:")])]), _vm._l(product.productsVariation, function (item) {
      return _c("el-option", {
        key: item.id,
        attrs: {
          label: product.name + "-" + item.name,
          value: "variation-" + item.id
        }
      }, [_c("span", [_vm._v(_vm._s(item.name))])]);
    })] : _vm._e()], 2);
  }), 1), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "Select State"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery["billingAddress.stateId"],
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "billingAddress.stateId", $$v);
      },
      expression: "listQuery['billingAddress.stateId']"
    }
  }, _vm._l(_vm.stateList, function (state) {
    return _c("el-option", {
      key: state.id,
      attrs: {
        label: state.name,
        value: state.id
      }
    });
  }), 1), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      disabled: !_vm.listQuery["billingAddress.stateId"] > 0,
      clearable: "",
      filterable: "",
      placeholder: "Select City"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery["billingAddress.cityId"],
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "billingAddress.cityId", $$v);
      },
      expression: "listQuery['billingAddress.cityId']"
    }
  }, _vm._l(_vm.cityList, function (city) {
    return _c("el-option", {
      key: city.id,
      attrs: {
        label: city.name,
        value: city.id
      }
    });
  }), 1), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      clearable: "",
      placeholder: "Status"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.status,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "status", $$v);
      },
      expression: "listQuery.status"
    }
  }, _vm._l(_vm.statusOptions, function (item) {
    return _c("el-option", {
      key: item.key,
      attrs: {
        label: item.label,
        value: item.key
      }
    });
  }), 1), _c("el-select", {
    staticClass: "filter-item",
    staticStyle: {
      width: "140px"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.sort,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "sort", $$v);
      },
      expression: "listQuery.sort"
    }
  }, _vm._l(_vm.sortOptions, function (item) {
    return _c("el-option", {
      key: item.key,
      attrs: {
        label: item.label,
        value: item.key
      }
    });
  }), 1), _c("br"), _c("VueCtkDateTimePicker", {
    staticClass: "filter-item",
    attrs: {
      range: "",
      formatted: "DD-MM-YYYY hh:mm a"
    },
    on: {
      change: _vm.dateval
    },
    model: {
      value: _vm.dateRange,
      callback: function ($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  }), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")]), _vm.isAgent || _vm.loggedInAdmin.adminsRoleIds.includes(3) ? _c("el-button", {
    staticClass: "filter-item",
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.resetOrder
    }
  }, [_vm._v(" New Order ")]) : _vm._e(), _c("el-dialog", {
    attrs: {
      title: "Select Dealer",
      visible: _vm.dialogFormVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogFormVisible = $event;
      }
    }
  }, [_c("el-select", {
    attrs: {
      "remote-method": _vm.searchUsers,
      filterable: "",
      clearable: "",
      "default-first-option": "",
      remote: "",
      placeholder: "Select Dealer"
    },
    on: {
      change: _vm.validateDealer
    },
    model: {
      value: _vm.dealerId,
      callback: function ($$v) {
        _vm.dealerId = $$v;
      },
      expression: "dealerId"
    }
  }, _vm._l(_vm.dealerList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        value: item.id,
        label: item.name
      }
    }, [_c("span", [_vm._v(" " + _vm._s(item.name) + " - " + _vm._s(item.code) + " ")])]);
  }), 1), _c("div", [_c("br")]), _c("el-select", {
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: "Select Dealer User"
    },
    model: {
      value: _vm.dealersUserId,
      callback: function ($$v) {
        _vm.dealersUserId = $$v;
      },
      expression: "dealersUserId"
    }
  }, _vm._l(_vm.dealersUserList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        value: item.id,
        label: item.fullName
      }
    }, [_c("span", {
      staticStyle: {
        float: "left",
        "font-size": "12px"
      }
    }, [_vm._v(_vm._s(item.fullName))]), _c("span", {
      staticStyle: {
        float: "right",
        color: "#8492a6",
        "font-size": "12px"
      }
    }, [_vm._v("   | ID: " + _vm._s(item.id))])]);
  }), 1), !_vm.orderExists ? _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function ($event) {
        _vm.dialogFormVisible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c("el-button", {
    attrs: {
      disabled: !_vm.dealersUserId,
      size: "mini",
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.checkInCartOrder(_vm.dealerId, _vm.dealersUserId);
      }
    }
  }, [_vm._v("Verify ")])], 1) : _vm._e(), _vm.orderExists ? _c("el-popconfirm", {
    attrs: {
      title: "An order already exists for this dealer, do you want to proceed?"
    },
    on: {
      confirm: _vm.createOrder,
      cancel: _vm.resetOrder
    }
  }, [_c("el-button", {
    attrs: {
      slot: "reference",
      type: "success"
    },
    slot: "reference"
  }, [_vm._v(" Confirm ")])], 1) : _vm._e()], 1), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-document"
    },
    on: {
      click: _vm.handleExcelDownload
    }
  }, [_vm._v(" Export as Excel ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "info",
      size: "mini",
      icon: "el-icon-info",
      disabled: _vm.selectedOrderIds.length === 0
    },
    on: {
      click: _vm.showSelectedProductInfo
    }
  }, [_vm._v(" Show selected Info ")])], 1), _vm.dateRange ? _c("div", [_vm._v(" Selected date: "), _c("el-tag", {
    attrs: {
      type: "warning"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("moment")(_vm.dateRange.start, "DD-MM-YYYY hh:mm")) + " ")]), _vm._v(" - "), _c("el-tag", {
    attrs: {
      type: "warning"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("moment")(_vm.dateRange.end, "DD-MM-YYYY hh:mm")) + " ")])], 1) : _vm._e(), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange,
      "selection-change": _vm.handleSelectionChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      width: "35",
      align: "center"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "200px",
      align: "left",
      label: "Order Status",
      prop: "status"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("strong", [_vm._v("ID: ")]), _c("router-link", {
          attrs: {
            to: "/order/" + scope.row.id
          }
        }, [scope.row.status !== "in cart" ? _c("el-link", {
          attrs: {
            type: "primary"
          }
        }, [_vm._v(" " + _vm._s(scope.row.orderReference) + " ")]) : _vm._e()], 1), scope.row.status === "in cart" ? _c("div", [_vm._v(" " + _vm._s(scope.row.orderReference) + " ")]) : _vm._e(), _c("br"), _c("el-tag", {
          attrs: {
            size: "mini",
            type: _vm.statusColorMap[scope.row.status]
          }
        }, [_vm._v(" " + _vm._s(scope.row.status) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "120px",
      align: "center",
      label: "Product(s)",
      prop: "totalProducts"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return scope.row.totalProducts ? [_c("el-button", {
          attrs: {
            type: "primary",
            round: "",
            size: "mini"
          },
          on: {
            click: function ($event) {
              return _vm.loadProductComponent(scope.row);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.totalProducts) + " ")])] : undefined;
      }
    }], null, true)
  }), _c("el-table-column", {
    attrs: {
      width: "220px",
      label: "Dealer",
      prop: "dealerId"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return scope.row.dealer ? [scope.row.dealer.name ? _c("b", [_c("i", {
          staticClass: "el-icon-suitcase"
        }), _vm._v(" " + _vm._s(scope.row.dealer.name) + " "), scope.row.dealer.status === "Hold" ? _c("el-popover", {
          attrs: {
            placement: "top-start",
            width: "300",
            trigger: "click",
            content: _vm.holdInfo
          }
        }, [_c("div", [_vm._v(_vm._s(_vm.holdInfo))]), _c("el-button", {
          attrs: {
            slot: "reference",
            type: "warning",
            icon: "el-icon-warning",
            size: "mini",
            circle: ""
          },
          on: {
            click: function ($event) {
              return _vm.fetchHoldInfo(scope.row.dealerId);
            }
          },
          slot: "reference"
        })], 1) : _vm._e()], 1) : _vm._e(), _c("br"), _c("i", {
          staticClass: "el-icon-s-custom"
        }), _vm._v(" " + _vm._s(scope.row.agent.code) + " "), _c("br"), scope.row.dealer.city ? _c("i", {
          staticClass: "el-icon-place"
        }, [_vm._v(" " + _vm._s(scope.row.dealer.city) + " ")]) : _vm._e()] : undefined;
      }
    }], null, true)
  }), _c("el-table-column", {
    attrs: {
      width: "160",
      align: "left",
      label: "Address"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return scope.row.billingAddress ? [_vm._v(" City: " + _vm._s(scope.row.billingAddress.cityName) + " "), _c("br"), scope.row.billingAddress.state ? _c("div", [_vm._v(" State: " + _vm._s(scope.row.billingAddress.state.name) + " ")]) : _vm._e()] : undefined;
      }
    }], null, true)
  }), !_vm.isSalesManager ? _c("el-table-column", {
    attrs: {
      width: "140",
      align: "left",
      label: "Grand Total",
      prop: "grandTotal"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.grandTotal
          }
        })];
      }
    }], null, false, 3425759555)
  }) : _vm._e(), _c("el-table-column", {
    attrs: {
      width: "150px",
      align: "center",
      prop: "orderTimestamp",
      label: "Placed On"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm._f("moment")(scope.row.orderTimestamp, "DD-MM-YYYY")) + " "), _c("br"), _vm._v(" " + _vm._s(_vm._f("moment")(scope.row.orderTimestamp, "hh:mm a")) + " ")];
      }
    }])
  }), !_vm.isAgent && !_vm.isInvoiceManager && !_vm.isSalesManager ? _c("el-table-column", {
    attrs: {
      align: "center",
      label: "Actions",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [!["in cart", "RTGS Payment", "Delivery Confirmed", "Order Cancelled"].includes(scope.row.status) && !_vm.isAgent && !_vm.isReadOnly && !_vm.isSalesManager ? _c("router-link", {
          attrs: {
            to: "/order/edit/" + scope.row.id
          }
        }, [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-edit"
          }
        })], 1) : _vm._e(), ["Order Confirmed", "Partial Dispatch", "Order Dispatched"].includes(scope.row.status) && _vm._isSuperAdmin() ? _c("el-button", {
          attrs: {
            type: "warning",
            size: "mini",
            icon: "el-icon-s-claim",
            disabled: _vm.reviseOrderFlag
          },
          on: {
            click: function ($event) {
              return _vm._reviseOrder(scope.row.id);
            }
          }
        }) : _vm._e(), _vm._v("   "), _vm._v("   "), _vm._v("   "), ["in cart", "Order Placed"].includes(scope.row.status) ? _c("router-link", {
          attrs: {
            to: "/order/modify/" + scope.row.id
          }
        }, [_c("el-tooltip", {
          attrs: {
            effect: "dark",
            content: "Modify Order",
            placement: "top-start"
          }
        }, [_c("el-button", {
          directives: [{
            name: "waves",
            rawName: "v-waves"
          }],
          attrs: {
            type: "success",
            size: "mini"
          }
        }, [_c("i", {
          staticClass: "el-icon-setting"
        })])], 1)], 1) : _vm._e()];
      }
    }], null, false, 1463070319)
  }) : _vm._e()], 1), _c("el-dialog", {
    attrs: {
      title: "Order Product Details",
      width: "75%",
      visible: _vm.dialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("order-product-tab", {
    attrs: {
      data: _vm.orderInfoData
    }
  })], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;