"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.verifyOtp = exports.updateAdmin = exports.register = exports.logout = exports.loginWithOtp = exports.login = exports.getAdmins = exports.getAdminInfo = exports.getAdminById = exports.getAdminByEmail = exports.deleteAdmin = exports.defaultAdminData = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultAdminData = exports.defaultAdminData = {
  id: 0,
  enabled: true,
  fullName: '',
  alias: '',
  emailAddress: '',
  mobileNumber: '',
  password: undefined,
  avatar: '',
  adminsRoleId: undefined,
  adminsRoleIds: [],
  // adminsRole: {
  //   id: null,
  //   name: ''
  // }
  adminsRole: []
};
const getAdmins = params => (0, _request.default)({
  url: '/admins',
  method: 'get',
  params
});
exports.getAdmins = getAdmins;
const getAdminById = id => (0, _request.default)({
  url: `/admins/${id}`,
  method: 'get'
});
exports.getAdminById = getAdminById;
const getAdminInfo = data => (0, _request.default)({
  url: '/admins/me',
  method: 'post',
  data
});
exports.getAdminInfo = getAdminInfo;
const getAdminByEmail = adminemail => (0, _request.default)({
  url: `/admins/${adminemail}`,
  method: 'get'
});
exports.getAdminByEmail = getAdminByEmail;
const updateAdmin = (id, data) => (0, _request.default)({
  url: `/admins/${id}`,
  method: 'patch',
  data
});
exports.updateAdmin = updateAdmin;
const deleteAdmin = adminname => (0, _request.default)({
  url: `/admins/${adminname}`,
  method: 'delete'
});
exports.deleteAdmin = deleteAdmin;
const login = data => (0, _request.default)({
  url: '/admins/login',
  method: 'post',
  data
});
exports.login = login;
const loginWithOtp = data => (0, _request.default)({
  url: '/admins/request-otp',
  method: 'post',
  data
});
exports.loginWithOtp = loginWithOtp;
const verifyOtp = data => (0, _request.default)({
  url: '/admins/verify-otp',
  method: 'post',
  data
});
exports.verifyOtp = verifyOtp;
const logout = () => (0, _request.default)({
  url: '/admins/logout',
  method: 'post'
});
exports.logout = logout;
const register = data => (0, _request.default)({
  url: '/admins',
  method: 'post',
  data
});
exports.register = register;