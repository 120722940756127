"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCashDiscount = exports.getCashDiscounts = exports.getCashDiscountById = exports.fileTransferHeaders = exports.deleteCashDisocunt = exports.defaultCashDiscountsData = exports.createCashDiscount = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultCashDiscountsData = exports.defaultCashDiscountsData = {
  id: 0,
  enabled: true,
  startDuration: undefined,
  endDuration: undefined,
  discount: undefined
};
const getCashDiscounts = params => (0, _request.default)({
  url: '/cash-discounts',
  method: 'get',
  params
});
exports.getCashDiscounts = getCashDiscounts;
const getCashDiscountById = id => (0, _request.default)({
  url: `/cash-discounts/${id}`,
  method: 'get'
});
exports.getCashDiscountById = getCashDiscountById;
const updateCashDiscount = (id, data) => (0, _request.default)({
  url: `/cash-discounts/${id}`,
  method: 'patch',
  data
});
exports.updateCashDiscount = updateCashDiscount;
const deleteCashDisocunt = id => (0, _request.default)({
  url: `/cash-discounts/${id}`,
  method: 'delete'
});
exports.deleteCashDisocunt = deleteCashDisocunt;
const createCashDiscount = data => (0, _request.default)({
  url: '/cash-discounts/',
  method: 'post',
  data
});
exports.createCashDiscount = createCashDiscount;