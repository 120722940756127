"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateScheme = exports.getSchemes = exports.getSchemeById = exports.deleteScheme = exports.defaultSchemeData = exports.createScheme = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultSchemeData = exports.defaultSchemeData = {
  id: 0,
  enabled: true,
  name: '',
  startDate: '',
  endDate: '',
  image: null
};
const getSchemes = params => (0, _request.default)({
  url: '/schemes',
  method: 'get',
  params
});
exports.getSchemes = getSchemes;
const getSchemeById = id => (0, _request.default)({
  url: `/schemes/${id}`,
  method: 'get'
});
exports.getSchemeById = getSchemeById;
const updateScheme = (id, data) => (0, _request.default)({
  url: `/schemes/${id}`,
  method: 'patch',
  data
});
exports.updateScheme = updateScheme;
const deleteScheme = id => (0, _request.default)({
  url: `/schemes/${id}`,
  method: 'delete'
});
exports.deleteScheme = deleteScheme;
const createScheme = data => (0, _request.default)({
  url: '/schemes',
  method: 'post',
  data
});
exports.createScheme = createScheme;