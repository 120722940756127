"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateTransporter = exports.getTransporters = exports.getTransporterById = exports.downloadExcel = exports.deleteTransporter = exports.defaultTransporterData = exports.createTransporter = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultTransporterData = exports.defaultTransporterData = {
  id: 0,
  enabled: true,
  name: undefined,
  mobileNumber: undefined,
  gstNumber: undefined,
  vehicleNumber: undefined,
  freightPerCarton: 0,
  countryId: null,
  country: {
    id: null
  },
  stateId: null,
  state: {
    id: null
  },
  cityId: null,
  city: {
    id: null
  }
};
const getTransporters = params => (0, _request.default)({
  url: '/transporters',
  method: 'get',
  params
});
exports.getTransporters = getTransporters;
const createTransporter = data => (0, _request.default)({
  url: '/transporters',
  method: 'post',
  data
});
exports.createTransporter = createTransporter;
const getTransporterById = id => (0, _request.default)({
  url: `/transporters/${id}`,
  method: 'get'
});
exports.getTransporterById = getTransporterById;
const updateTransporter = (id, data) => (0, _request.default)({
  url: `/transporters/${id}`,
  method: 'patch',
  data
});
exports.updateTransporter = updateTransporter;
const downloadExcel = data => (0, _request.default)({
  url: '/transporters/download-excel',
  method: 'post',
  data,
  responseType: 'blob'
});
exports.downloadExcel = downloadExcel;
const deleteTransporter = id => (0, _request.default)({
  url: `/transporters/${id}`,
  method: 'delete'
});
exports.deleteTransporter = deleteTransporter;