"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSchemesCategory = exports.getSchemesCategoryById = exports.getSchemesCategories = exports.fileTransferHeaders = exports.deleteSchemesCategory = exports.defaultSchemesCategoryData = exports.createSchemesCategory = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultSchemesCategoryData = exports.defaultSchemesCategoryData = {
  id: null,
  enabled: true,
  name: '',
  product: [],
  schemeId: undefined,
  scheme: {
    id: undefined
  },
  dealersRoleId: 0,
  dealersRole: []
};
const getSchemesCategories = params => (0, _request.default)({
  url: '/schemes-categories',
  method: 'get',
  params
});
exports.getSchemesCategories = getSchemesCategories;
const getSchemesCategoryById = id => (0, _request.default)({
  url: `/schemes-categories/${id}`,
  method: 'get'
});
exports.getSchemesCategoryById = getSchemesCategoryById;
const updateSchemesCategory = (id, data) => (0, _request.default)({
  url: `/schemes-categories/${id}`,
  method: 'patch',
  data
});
exports.updateSchemesCategory = updateSchemesCategory;
const deleteSchemesCategory = id => (0, _request.default)({
  url: `/schemes-categories/${id}`,
  method: 'delete'
});
exports.deleteSchemesCategory = deleteSchemesCategory;
const createSchemesCategory = data => (0, _request.default)({
  url: '/schemes-categories',
  method: 'post',
  data
});
exports.createSchemesCategory = createSchemesCategory;