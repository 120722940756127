"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getOrdersTransactions = exports.getOrdersTransactionById = exports.defaultOrdersTransactionData = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultOrdersTransactionData = exports.defaultOrdersTransactionData = {
  id: 0,
  orderId: undefined,
  ordersDispatchId: undefined,
  transactionId: undefined,
  amountReceived: 0,
  order: {
    id: null
  },
  ordersDispatch: {
    id: undefined
  },
  transaction: {
    id: undefined
  }
};
const getOrdersTransactions = params => (0, _request.default)({
  url: '/orders-transactions',
  method: 'get',
  params
});
exports.getOrdersTransactions = getOrdersTransactions;
const getOrdersTransactionById = id => (0, _request.default)({
  url: `/orders-transactions/${id}`,
  method: 'get'
});
exports.getOrdersTransactionById = getOrdersTransactionById;