"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _dealersUsers = require("@/api/dealers-users");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _index4 = _interopRequireDefault(require("@/components/Inr/index.vue"));
var _router = _interopRequireDefault(require("@/router"));
var _common = require("@/common");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.dealerId = 0;
    this.listQuery = {
      page: 1,
      limit: 10,
      code: undefined,
      id: undefined,
      fullName: undefined,
      emailAddress: undefined,
      mobileNumber: undefined,
      enabled: true,
      dealerId: undefined,
      sort: 'id,DESC',
      filter: {
        emailAddress: '$contL',
        fullName: '$contL',
        mobileNumber: '$startsL',
        enabled: '$eq',
        id: '$eq',
        dealerId: '$eq'
      }
    };
    this.sortOptions = [{
      label: 'ID Ascending',
      key: 'id,ASC'
    }, {
      label: 'ID Descending',
      key: 'id,DESC'
    }, {
      label: 'Order Ascending',
      key: 'totalOrderCount,ASC'
    }, {
      label: 'Order Descending',
      key: 'totalOrderCount,DESC'
    }];
    this.enableTypeOptions = [{
      key: true,
      value: 'Enable'
    }, {
      key: false,
      value: 'Disabled'
    }];
  }
  activated() {
    this.dealerId = parseInt(this.$route.params && this.$route.params.dealerId);
    this.listQuery = {
      ...this.listQuery,
      ...this.$route.query
    };
    this.getList();
  }
  async getList() {
    try {
      this.listLoading = true;
      if (this.dealerId) {
        this.listQuery.dealerId = this.dealerId;
        const data = await (0, _dealersUsers.getDealersUsers)((0, _index2.getQuery)(this.listQuery));
        this.list = data.data;
        this.total = data.total;
      } else {
        this.list = [];
        this.total = 0;
      }
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    this.handleFilter();
  }
  async handleDownload() {
    this.downloadLoading = true;
    const tHeader = ['id', 'enabled', 'Full Name', 'Mobile Number', 'Email Address', 'Is Mobile', 'Broker Name', 'Broker Mobile Number', 'Organization', 'GST Number', 'PAN Number', 'Wallet Balance', 'Wallet Usage Amount', 'Last Login', 'createdTimestamp'];
    const filterVal = ['id', 'enabled', 'fullName', 'mobileNumber', 'emailAddress', 'isMobile', 'broker.name', 'broker.mobileNumber', 'organization.name', 'organization.gstNumber', 'organization.panNumber', 'walletBalance', 'walletUsageAmount', 'pushUpdatedTimestamp', 'createdTimestamp'];
    const userFilter = Object.assign({}, this.listQuery);
    delete userFilter.limit;
    delete userFilter.page;
    const data2 = await (0, _dealersUsers.getDealersUsers)((0, _index2.getQuery)(userFilter));
    const data = (0, _index2.formatJson)(filterVal, data2);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc('route.dealerManagement.user.title'));
    this.downloadLoading = false;
  }
  _isSuperAdmin() {
    return (0, _common.isSuperAdmin)();
  }
  editUser(userId = 0) {
    const query = this.listQuery;
    // delete query.filter;
    _router.default.push({
      path: '/dealers-users/edit/' + userId
    });
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'DealersUserList',
  components: {
    Pagination: _index.default,
    Enabled: _index3.default,
    Inr: _index4.default
  }
})], default_1);
var _default = exports.default = default_1;