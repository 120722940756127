"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "verify-otp-container"
  }, [_vm._m(0), _c("el-form", {
    ref: "otpForm",
    staticClass: "otp-form",
    attrs: {
      model: _vm.otpForm,
      rules: _vm.otpRules,
      autocomplete: "on",
      "label-position": "left"
    }
  }, [_c("div", {
    staticClass: "title-container"
  }, [_c("h2", {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.$t("login.verifyOtpTitle")) + " ")])]), _c("el-form-item", {
    attrs: {
      prop: "otp"
    }
  }, [_c("span", {
    staticClass: "svg-container"
  }, [_c("svg-icon", {
    attrs: {
      name: "key"
    }
  })], 1), _c("el-input", {
    ref: "otp",
    attrs: {
      placeholder: _vm.$t("login.enterOtp"),
      name: "otp",
      type: "text",
      autocomplete: "on"
    },
    model: {
      value: _vm.otpForm.otp,
      callback: function ($$v) {
        _vm.$set(_vm.otpForm, "otp", $$v);
      },
      expression: "otpForm.otp"
    }
  })], 1), _c("el-button", {
    staticStyle: {
      width: "40%",
      "margin-left": "150px",
      "margin-bottom": "30px"
    },
    attrs: {
      loading: _vm.loading,
      type: "primary"
    },
    nativeOn: {
      click: function ($event) {
        $event.preventDefault();
        return _vm.verifyOtp.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("login.verifyOtp")) + " ")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "logo"
  }, [_c("img", {
    staticClass: "login-logo",
    attrs: {
      src: require("@/assets/img/logo.png")
    }
  })]);
}];
render._withStripped = true;