"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _categories = require("@/api/categories");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _index = _interopRequireDefault(require("@/components/FileUpload/index.vue"));
var _admin = require("@/store/modules/admin");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.parentId = 0;
    this.image = [];
    this.icon = [];
    this.isReadOnly = false;
    this.validateRequire = (rule, value, callback) => {
      if (value === "") {
        if (rule.field === "imageURL") {
          this.$message({
            message: "Upload cover image is required",
            type: "error"
          });
        } else {
          this.$message({
            message: rule.field + " is required",
            type: "error"
          });
        }
        callback(new Error(rule.field + " is required"));
      } else {
        callback();
      }
    };
    this.formData = Object.assign({}, _categories.defaultCategoryData);
    this.loading = false;
    this.rangeId = 0;
    this.rules = {
      name: [{
        required: true,
        message: "Please enter name",
        trigger: "blur"
      }, {
        min: 3,
        max: 150,
        message: "Length should be 3 to 150",
        trigger: "blur"
      }],
      email: [{
        required: true,
        message: "Please enter email",
        trigger: "blur"
      }, {
        type: "email",
        required: true,
        message: "Please enter valid email",
        trigger: "change"
      }],
      contactNumber: [{
        required: true,
        message: "Please enter mobile number",
        trigger: "blur"
      }, {
        min: 10,
        max: 15,
        message: "Length should be 10 to 15",
        trigger: ["blur", "change"]
      }]
    };
  }
  get lang() {
    return _app.AppModule.language;
  }
  activated() {
    if (_admin.AdminModule.roles.includes("Read Only")) {
      this.isReadOnly = true;
    }
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, _categories.defaultCategoryData);
      this.parentId = parseInt(this.$route.params.parentId);
      this.rangeId = +this.$route.query.rangeId || 0;
      // get range from parent category
      if (this.parentId) {
        this.fetchParentById(this.parentId);
      }
    }
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _categories.getCategoryById)(id);
      this.formData = data;
      this.rangeId = data.rangeId;
      this.parentId = data.parentId;
      this.icon.push({
        name: data.name,
        url: data.icon
      });
      this.image.push({
        name: data.name,
        url: data.image
      });
      this.parentId = data.parent.id;
      // Just for test
      const title = this.formData.name;
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async fetchParentById(id) {
    try {
      const data = await (0, _categories.getCategoryById)(id);
      this.rangeId = data.rangeId;
    } catch (err) {} // eslint-disable-line  no-empty
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  iconUploaded(file) {
    this.formData.icon = file.response;
  }
  imageUploaded(file) {
    this.formData.image = file.response;
  }
  async saveForm() {
    try {
      this.loading = true;
      // update range
      if (this.rangeId) {
        this.formData.range = {
          id: this.rangeId
        };
      }
      if (this.isEdit) {
        await (0, _categories.updateCategory)(this.formData.id, this.formData);
      } else {
        if (this.parentId) {
          this.formData.parent = this.parentId;
        }
        await (0, _categories.createCategory)(this.formData);
      }
      this.$notify({
        title: "Success",
        message: "Category saved",
        type: "success",
        duration: 2000
      });
      this.loading = false;
      _router.default.push("/category/list/" + this.parentId);
      // router.push('/category/list/' + this.parentId)
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "CategoryDetail",
  components: {
    FileUpload: _index.default
  }
})], default_1);
var _default = exports.default = default_1;