import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import { generateFax, generateInvoice, downloadExcel, dealerSalesReport, getGrandTotal, getFinancialYear } from "@/api/orders-dispatches";
import Pagination from "@/components/Pagination/index.vue";
import { getQuery } from "@/utils/index";
import Inr from "@/components/Inr/index.vue";
import { getAgentDealers, getDealerCities, getDealers } from "@/api/dealers";
import { AdminModule } from "@/store/modules/admin";
import { getStates } from "@/api/locations/states";
import { getCities } from "@/api/locations/cities";
import Enabled from "@/components/Enabled/index.vue";
import { BranchAgentService } from "@/services/branch-agent-service";
import { isAdmin, isSuperAdmin } from "@/common";
import moment from "moment";
let default_1 = class extends Vue {
  constructor() {
    super(...arguments);
    this.isReadOnly = false;
    this.baseUrl = process.env.VUE_APP_BASE_API;
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = false;
    this.downloadLoading = false;
    this.dateRange = "";
    this.branchListLoading = false;
    this.branchList = [];
    this.dialogVisible = false;
    this.isAgent = false;
    this.dispatchId = 0;
    this.agentList = [];
    this.dealerList = [];
    this.stateList = [];
    this.cityList = [];
    this.dealerListLoading = false;
    this.stateListLoading = false;
    this.cityListLoading = false;
    this.totalGrandTotal = "";
    this.statusColorMap = {
      Pending: "warning",
      "Request Approval": "primary",
      Approved: "success",
      Rejected: "danger"
    };
    this.listQuery = {
      page: 1,
      limit: 10,
      dealerId: undefined,
      adminId: undefined,
      agentId: undefined,
      stateId: undefined,
      cityId: undefined,
      startDate: undefined,
      endDate: undefined,
      branchId: [],
      sort: "id,ASC",
      filter: {
        dealerId: "$eq",
        adminId: "$eq",
        agentId: "$eq",
        stateId: "$eq",
        cityId: "$eq",
        branchId: "$in"
      }
    };
    this.sortOptions = [{
      label: "ID Ascending",
      key: "id,ASC"
    }, {
      label: "ID Descending",
      key: "id,DESC"
    }];
    this.loggedInAdmin = null;
    this.shortcuts = [{
      key: "thisWeek",
      label: "This week",
      value: "isoWeek"
    }, {
      key: "lastWeek",
      label: "Last week",
      value: "-isoWeek"
    }, {
      key: "last7Days",
      label: "Last 7 days",
      value: 7
    }, {
      key: "last30Days",
      label: "Last 30 days",
      value: 30
    }, {
      key: "thisMonth",
      label: "This month",
      value: "month"
    }, {
      key: "lastMonth",
      label: "Last month",
      value: "-month"
    }, {
      key: "thisYear",
      label: "This FY",
      value: () => {
        const currentDate = moment();
        let startYear = currentDate.year();
        // Financial year starts in April
        const startMonth = 3; // April (0-indexed as 3 in moment.js)
        const startDay = 1;
        if (currentDate.month() <= 2) {
          // If before April, move to the previous financial year
          startYear--;
        }
        const thisFinancialYearStart = moment([startYear, startMonth, startDay]);
        const thisFinancialYearEnd = moment([startYear + 1, startMonth, startDay]).subtract(1, "days");
        return {
          start: thisFinancialYearStart,
          end: thisFinancialYearEnd
        };
      }
    }, {
      key: "lastYear",
      label: "Last FY",
      value: () => {
        const currentDate = moment();
        let startYear = currentDate.year();
        // Financial year starts in April
        const startMonth = 3;
        const startDay = 1;
        if (currentDate.month() <= 2) {
          // Before April, use the previous financial year
          startYear--;
        }
        const lastFinancialYearStart = moment([startYear - 1, startMonth, startDay]);
        const lastFinancialYearEnd = moment([startYear, startMonth, startDay]).subtract(1, "days");
        return {
          start: lastFinancialYearStart,
          end: lastFinancialYearEnd
        };
      }
    }];
  }
  async activated() {
    try {
      if (AdminModule.roles.includes("Read Only")) {
        this.isReadOnly = true;
      }
      // Assign branchId using BranchAgentService
      this.listQuery.branchId = await BranchAgentService.assignBranchId();
      // Check if branchId is null and throw an error
      if (this.listQuery.branchId === null) {
        throw new Error("You are not assigned to a branch.");
      }
      // Fetch agents for the assigned branchId
      this.agentList = await BranchAgentService.fetchAgents(this.listQuery.branchId);
      // Fetch branches for non-super admin users
      this.branchList = await BranchAgentService.fetchBranches(this.listQuery.branchId);
      this.loggedInAdmin = AdminModule;
      // check if logged in user is Agent/Admin
      if (this.loggedInAdmin.userType === "Agent") {
        this.isAgent = true;
        this.listQuery.agentId = this.loggedInAdmin.agentId;
      }
      if (this.$route.query.startDate && this.$route.query.endDate) {
        this.dateRange = {
          start: this.$route.query.startDate,
          end: this.$route.query.endDate
        };
      }
      if (!this.dateRange) {
        this.getCurrentFinancialYear();
      }
      this.listQuery = {
        ...this.listQuery,
        ...this.$route.query
      };
      // get dispatch list
      this.getList();
      this.getStateList();
    } catch (err) {
      if (err instanceof Error) {
        this.$notify.error({
          title: "Error",
          message: err.message
        });
      }
      this.listLoading = false;
    }
  }
  async getList() {
    try {
      if (this.dateRange) {
        this.listQuery.startDate = this.dateRange.start;
        this.listQuery.endDate = this.dateRange.end;
      }
      const data = await dealerSalesReport(this.listQuery);
      const queryWithoutPagination = {
        ...this.listQuery
      };
      delete queryWithoutPagination.page;
      delete queryWithoutPagination.limit;
      this.totalGrandTotal = await getGrandTotal(queryWithoutPagination);
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getCurrentFinancialYear() {
    const dateData = await getFinancialYear();
    this.dateRange = {
      start: dateData.startDate,
      end: dateData.endDate
    };
    this.listQuery.startDate = this.dateRange.start;
    this.listQuery.endDate - this.dateRange.end;
    this.getList();
  }
  async downloadFax() {
    this.listLoading = true;
    try {
      const params = {};
      if (this.listQuery.adminId) {
        params.adminId = this.listQuery.adminId;
      }
      if (this.listQuery.agentId) {
        params.agentId = this.listQuery.agentId;
      }
      if (this.listQuery.dealerId) {
        params.dealerId = this.listQuery.dealerId;
      }
      if (this.listQuery.status) {
        params.status = this.listQuery.status;
      }
      if (this.dateRange) {
        params.startDate = this.dateRange.start;
        params.endDate = this.dateRange.end;
      }
      if (Object.keys(params).length) {
        const data = await generateFax(getQuery(params));
        window.open(data.url, "_blank");
        // const filename: string = "fax_" + new Date().getTime() + ".pdf";
        // this.downloadFile(data.url, filename);
      } else {
        this.$message({
          message: "Please select atleast one filter",
          type: "warning"
        });
      }
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getDealerList(query = null) {
    try {
      if (query && query.length > 2) {
        this.dealerListLoading = true;
        // params
        const params = {
          name: query,
          agentId: this.isAgent ? AdminModule.agentId : undefined,
          branchId: this.listQuery.branchId !== undefined ? this.listQuery.branchId : AdminModule.branchIds,
          filter: {
            name: "$contL",
            agentId: "$eq"
          }
        };
        // Sandeep: check if you are only getting dealers which are assigned to selected agent
        if (this.listQuery.agentId) {
          params.agentId = this.listQuery.agentId;
        }
        if (this.listQuery.branchId) {
          params.branchId = this.listQuery.branchId;
        }
        //  get dealers
        this.dealerList = await getDealers(getQuery(params));
      }
      this.dealerListLoading = false;
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async getStateList(query = null) {
    try {
      this.stateList = await getStates(getQuery({
        enabled: true,
        sort: "name,ASC",
        filter: {
          enabled: "eq"
        }
      }));
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async getCityList(stateId = 0) {
    try {
      this.cityListLoading = true;
      this.cityList = await getCities(getQuery({
        enabled: true,
        stateId,
        sort: "name,ASC",
        filter: {
          enabled: "$eq",
          stateId: "$eq"
        }
      }));
      this.cityListLoading = false;
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async getDealerCityList(params) {
    const data = await getDealerCities(params);
    this.stateList = data.stateList;
    this.cityList = data.cityList;
  }
  async getAgentDealersList(params) {
    const data = await getAgentDealers(params);
    this.dealerList = data;
  }
  async handleFilter() {
    var _AdminModule$branchId;
    if (this.dateRange && this.dateRange.start && this.dateRange.end) {
      this.listQuery.startDate = this.dateRange.start;
      this.listQuery.endDate = this.dateRange.end;
    } else {
      this.listQuery.startDate = undefined;
      this.listQuery.endDate = undefined;
    }
    if (this.listQuery.stateId) {
      this.getCityList(this.listQuery.stateId);
    } else {
      // empty city
      this.cityList = [];
      this.listQuery.cityId = undefined;
      this.listQuery.stateId = undefined;
    }
    if (!this.listQuery.cityId) {
      this.listQuery.cityId = undefined;
    }
    if (this.listQuery.dealerId) {
      const params = {
        dealerId: undefined
      };
      params.dealerId = this.listQuery.dealerId;
      this.getDealerCityList(params);
    }
    if (this.listQuery.agentId) {
      const params = {
        agentId: undefined
      };
      params.agentId = this.listQuery.agentId;
      this.getAgentDealersList(params);
    }
    this.listQuery.page = 1;
    // handle branch and agent code
    // Fetch agents for a single branch
    if ((!isSuperAdmin() || !isAdmin()) && ((_AdminModule$branchId = AdminModule.branchIds) === null || _AdminModule$branchId === void 0 ? void 0 : _AdminModule$branchId.length) === 1) {
      this.listQuery.branchId = AdminModule.branchIds[0];
    }
    if (this.listQuery.branchId) {
      // Fetch agents and branches for non-superadmin/admin users
      this.agentList = await BranchAgentService.fetchAgents(this.listQuery.branchId);
      await BranchAgentService.fetchBranches(this.listQuery.branchId);
      // Fetch the list after agents and branches are loaded
      this.getList();
    } else {
      this.getList();
    }
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === "ascending" ? "ASC" : "DESC");
      this.handleFilter();
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? "ascending" : sort === `${key},DESC` ? "descending" : "";
  }
  toggleDialog(id = 0) {
    this.dialogVisible = true;
    this.dispatchId = id;
  }
  async downloadInvoice(id = 0) {
    // get invoice url
    this.listLoading = true;
    const url = await generateInvoice(id);
    if (url) {
      window.open(url, "_blank");
      // const filename = "invoice_" + id + ".pdf";
      // this.downloadFile(data.url, filename);
    } else {
      this.$message({
        message: "Failed to generate invoice",
        type: "error"
      });
    }
    this.listLoading = false;
  }
  async handleExcelDownload() {
    this.downloadLoading = false;
    try {
      const queryWithoutPagination = {
        ...this.listQuery
      };
      delete queryWithoutPagination.page;
      delete queryWithoutPagination.limit;
      const allDataResponse = await dealerSalesReport(queryWithoutPagination);
      let startDate;
      let endDate;
      if (this.listQuery.startDate && this.listQuery.endDate) {
        startDate = this.listQuery.startDate;
        endDate = this.listQuery.endDate;
      }
      const allData = allDataResponse.data;
      let grandTotal = allData.reduce((total, item) => total + +item.totalAmount, 0);
      grandTotal = grandTotal.toFixed(2);
      const query = this.listQuery;
      const blob = await downloadExcel({
        query,
        grandTotal,
        startDate,
        endDate
      });
      // Create a blob URL for the Excel file
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sales-report.xlsx");
      document.body.appendChild(link);
      // Simulate click to trigger download
      link.click();
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading Excel:", error);
      this.$message.error("Error downloading Excel");
    } finally {
      // Reset loading state
      this.downloadLoading = false;
    }
  }
  getSummaries(param) {
    const {
      columns,
      data
    } = param;
    const sums = [];
    const totalGrandTotalRow = [];
    columns.forEach((column, index) => {
      // first column
      if (index === 0) {
        sums[index] = "Total Page Sales: ";
        totalGrandTotalRow[index] = "Grand Total: "; // Add label for the total grand total row
        return;
      }
      // Grand Total column
      if (index === 6) {
        // get all values
        const values = data.map(item => Number(item[column.property]));
        // loop over & null check
        if (!values.every(value => isNaN(value))) {
          // sum them using reduce
          sums[index] = values.reduce((prev, curr) => {
            // value conversion (do check with Sandesh if toFixed something else required for decimal precision)
            const value = Number(curr);
            // null check
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0); // initial value
          // Calculate total grand total
          totalGrandTotalRow[index] = parseFloat(this.totalGrandTotal).toFixed(2); // Store the totalGrandTotal value
        } // loop if ends
      } // index check ends
    }); // column loop ends
    // Return sums and total grand total row
    if (sums && sums.length) {
      sums[6] = sums[6].toFixed(2);
    }
    return [,,,,, sums, totalGrandTotalRow]; // Return both the sums and total grand total row
  }
};
default_1 = __decorate([Component({
  name: "SalesReportList",
  components: {
    Pagination,
    Inr,
    Enabled
  }
})], default_1);
export default default_1;