"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dealerWiseOsOdReportSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const dealerWiseOsOdReportSubMenu = exports.dealerWiseOsOdReportSubMenu = [{
  path: '/report/dealerWiseOsOdReport/list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/report/dealer-wise-os-od-report/list.vue'))),
  name: 'report.title',
  meta: {
    title: 'report.dealerWiseOsOdReport.title',
    roles: ['Super Admin', 'Admin', 'Unit Manager', 'Invoice Manager', 'Read Only', 'Account Manager'],
    noCache: true,
    hidden: false,
    icon: 'form'
  }
}];