"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dealersAddressSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const dealersAddressSubMenu = exports.dealersAddressSubMenu = [{
  path: '/dealers-addresses/list/:dealerId(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/dealers-addresses/list.vue'))),
  name: 'dealerManagement.address.title',
  meta: {
    title: 'dealerManagement.address.title',
    icon: 'user',
    noCache: true,
    hidden: true
  }
}, {
  path: '/dealers-addresses/add/:dealerId(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/dealers-addresses/manage.vue'))),
  name: 'dealerManagement.address.add',
  props: {
    isEdit: false
  },
  meta: {
    roles: ['Super Admin', 'Admin'],
    title: 'dealerManagement.address.add',
    noCache: true,
    activeMenu: '/dealers-addresses/list/:dealerId(\\d+)',
    hidden: true
  }
}, {
  path: '/dealers-addresses/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/dealers-addresses/manage.vue'))),
  name: 'dealerManagement.address.edit',
  props: {
    isEdit: true
  },
  meta: {
    roles: ['Super Admin', 'Admin'],
    title: 'dealerManagement.address.edit',
    noCache: true,
    activeMenu: '/dealers-addresses/list/:dealerId(\\d+)',
    hidden: true
  }
}];