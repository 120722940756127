"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reCalculateCd = exports.getDealerWiseCdExcel = exports.getDealerWiseCashDiscounts = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const getDealerWiseCashDiscounts = params => (0, _request.default)({
  url: '/cash-discount-outstandings/dealer-wise-cd',
  method: 'get',
  params
});
exports.getDealerWiseCashDiscounts = getDealerWiseCashDiscounts;
const getDealerWiseCdExcel = data => (0, _request.default)({
  url: '/cash-discount-outstandings/download-excel',
  method: 'post',
  data,
  responseType: 'blob'
});
exports.getDealerWiseCdExcel = getDealerWiseCdExcel;
const reCalculateCd = () => (0, _request.default)({
  url: '/cash-discount-trackers/osCd',
  method: 'get'
});
exports.reCalculateCd = reCalculateCd;