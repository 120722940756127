"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'nested': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M0 9.2c0 5 3.6 9.1 8 9.1s8-4 8-9.1c0-5-3.6-9.1-8-9.1S0 4 0 9.2zM32 .1h96v18.2H32V.1zm0 45.6c0 5 3.6 9.2 8 9.2s8-4.1 8-9.2c0-3.2-1.5-6.2-4-7.9a7.2 7.2 0 00-8 0 9.4 9.4 0 00-4 8zm32-9.1h64v18.3H64V36.6zm-32 82.2c0 5 3.6 9.1 8 9.1s8-4 8-9.1c0-5-3.6-9.1-8-9.1s-8 4-8 9.1zm32-9.1h64v18.2H64v-18.2zm0-27.4c0 5 3.6 9.1 8 9.1s8-4 8-9.1c0-3.3-1.5-6.3-4-8a7.1 7.1 0 00-8 0 9.4 9.4 0 00-4 8zM96 73h32v18.3H96V73.1z"/>'
  }
});