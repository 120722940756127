"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transportersSubMenu = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
const transportersSubMenu = exports.transportersSubMenu = [{
  path: '/transporters/list',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/transporters/list.vue'))),
  name: 'Transporter',
  meta: {
    roles: ['Super Admin', 'Admin', 'Read Only', 'Invoice Manager', 'Order Manager'],
    title: 'orderManagement.transporter.title',
    noCache: true,
    icon: 'building'
  }
}, {
  path: '/transporters/edit/:id(\\d+)',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/transporters/manage.vue'))),
  name: 'orderManagement.transporter.edit',
  props: {
    isEdit: true
  },
  meta: {
    title: 'orderManagement.transporter.edit',
    noCache: true,
    activeMenu: '/transporters/list',
    hidden: true
  }
}, {
  path: '/transporters/add/',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/transporters/manage.vue'))),
  name: 'orderManagement.transporter.add',
  props: {
    isEdit: false
  },
  meta: {
    title: 'orderManagement.transporter.add',
    noCache: true,
    activeMenu: '/transporters/list',
    hidden: true
  }
}];