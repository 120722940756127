"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _interopRequireWildcard = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AdminModule = void 0;
require("core-js/modules/es.error.cause.js");
var _tslib = require("tslib");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _admins = require("@/api/admins");
var _agentsUsers = require("@/api/agents-users");
var _cookies = require("@/utils/cookies");
var _router = _interopRequireWildcard(require("@/router"));
var _permission = require("./permission");
var _tagsView = require("./tags-view");
var _store = _interopRequireDefault(require("@/store"));
let Admin = class Admin extends _vuexModuleDecorators.VuexModule {
  constructor() {
    super(...arguments);
    this.token = (0, _cookies.getToken)() || '';
    this.name = '';
    this.avatar = '';
    this.roles = [];
    this.email = '';
    this.id = 0;
    this.adminsRoleId = 0;
    this.adminsRoleIds = [];
    this.userType = '';
    this.branchIds = [];
    this.agentId = null;
  }
  SET_TOKEN(token) {
    this.token = token;
  }
  SET_NAME(name) {
    this.name = name;
  }
  SET_AVATAR(avatar) {
    this.avatar = avatar;
  }
  SET_ID(id) {
    this.id = id;
  }
  SET_ROLES(roles) {
    this.roles = roles;
  }
  SET_BRANCH_IDS(branchIds) {
    this.branchIds = branchIds;
  }
  SET_ROLE_ID(adminsRoleId) {
    this.adminsRoleId = adminsRoleId;
  }
  SET_ROLE_IDS(adminsRoleIds = []) {
    this.adminsRoleIds = adminsRoleIds;
  }
  SET_AGENT_ID(agentId) {
    this.agentId = agentId;
  }
  SET_EMAIL(email) {
    this.email = email;
  }
  SET_TYPE(userType) {
    this.userType = userType;
  }
  async Login(adminInfo) {
    let {
      email,
      password
    } = adminInfo;
    email = email.trim();
    password = password.trim();
    try {
      const data = await (0, _admins.login)({
        email,
        password
      });
      (0, _cookies.setToken)(data.accessToken);
      this.SET_TOKEN(data.accessToken);
      return (0, _admins.getAdminInfo)({});
    } catch (error) {
      this.ResetToken();
      return Promise.reject(error);
    }
  }
  async requestOtp(mobileNumber) {
    mobileNumber = mobileNumber.trim();
    try {
      // Request OTP by sending the mobile number
      const data = await (0, _admins.loginWithOtp)({
        mobileNumber
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async verifyOtp(otpInfo) {
    let {
      mobileNumber,
      otp,
      hash
    } = otpInfo;
    mobileNumber = mobileNumber.trim();
    otp = otp.trim();
    try {
      // Verify OTP and get token
      const data = await (0, _admins.verifyOtp)({
        mobileNumber,
        otp,
        hash
      });
      console.log('data from verfify otp', data);
      (0, _cookies.setToken)(data.accessToken);
      this.SET_TOKEN(data.accessToken);
      return (0, _admins.getAdminInfo)({}); // Fetch admin info after successful login
    } catch (error) {
      this.ResetToken();
      return Promise.reject(error);
    }
  }
  async AgentLogin(adminInfo) {
    let {
      email,
      password
    } = adminInfo;
    email = email.trim();
    password = password.trim();
    try {
      const data = await (0, _agentsUsers.agentLogin)({
        email,
        password
      });
      (0, _cookies.setToken)(data.accessToken);
      this.SET_TOKEN(data.accessToken);
      return (0, _agentsUsers.getAgentInfo)({});
    } catch (error) {
      this.ResetToken();
      return Promise.reject(error);
    }
  }
  ResetToken() {
    (0, _cookies.removeToken)();
    this.SET_TOKEN('');
    this.SET_ROLES([]);
    this.SET_BRANCH_IDS([]);
    this.SET_ROLE_ID(0);
  }
  async GetAdminInfo() {
    if (this.token === '') {
      throw Error('GetAdminInfo: token is undefined!');
    }
    const data = await (0, _admins.getAdminInfo)({});
    if (!data) {
      throw Error('Verification failed, please Login again.');
    }
    localStorage.setItem("uuid", data.uuid);
    this.SET_ROLES(data.roles);
    // this.SET_ROLES(['Super Admin'])
    this.SET_BRANCH_IDS(data.branchIds);
    this.SET_NAME(data.fullName);
    // this.SET_AVATAR(avatar)
    this.SET_ID(data.id);
    this.SET_EMAIL(data.emailAddress);
    this.SET_ROLE_ID(data.adminsRoleId);
    // this.SET_ROLES([data.roles])
    this.SET_ROLE_IDS(data.adminsRoleIds);
    this.SET_TYPE(data.userType);
    this.SET_AGENT_ID(data.agentId);
    return data;
  }
  async ChangeRoles(role) {
    // Dynamically modify permissions
    this.SET_ROLES([role]);
    this.SET_ROLE_ID(0); // mubasshir
    const token = role + '-token';
    this.SET_TOKEN(token);
    (0, _cookies.setToken)(token);
    await this.GetAdminInfo();
    (0, _router.resetRouter)();
    // Generate dynamic accessible routes based on roles
    _permission.PermissionModule.GenerateRoutes(this.roles);
    // Add generated routes
    _router.default.addRoutes(_permission.PermissionModule.dynamicRoutes);
    // Reset visited views and cached views
    _tagsView.TagsViewModule.delAllViews();
  }
  async LogOut() {
    if (this.token === '') {
      throw Error('LogOut: token is undefined!');
    }
    localStorage.removeItem("uuid");
    await (0, _admins.logout)();
    (0, _cookies.removeToken)();
    (0, _router.resetRouter)();
    // Reset visited views and cached views
    _tagsView.TagsViewModule.delAllViews();
    this.SET_TOKEN('');
    this.SET_ROLES([]);
    this.SET_ROLE_ID(0);
    this.SET_ROLE_IDS([]);
  }
  async AgentLogOut() {
    if (this.token === '') {
      throw Error('LogOut: token is undefined!');
    }
    await (0, _agentsUsers.agentLogout)();
    (0, _cookies.removeToken)();
    (0, _router.resetRouter)();
    // Reset visited views and cached views
    _tagsView.TagsViewModule.delAllViews();
    this.SET_TOKEN('');
    this.SET_ROLES([]);
    this.SET_ROLE_ID(0);
  }
};
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Admin.prototype, "SET_TOKEN", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Admin.prototype, "SET_NAME", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Admin.prototype, "SET_AVATAR", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Admin.prototype, "SET_ID", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Admin.prototype, "SET_ROLES", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Admin.prototype, "SET_BRANCH_IDS", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Admin.prototype, "SET_ROLE_ID", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Admin.prototype, "SET_ROLE_IDS", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Admin.prototype, "SET_AGENT_ID", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Admin.prototype, "SET_EMAIL", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Admin.prototype, "SET_TYPE", null);
(0, _tslib.__decorate)([(0, _vuexModuleDecorators.Action)({
  rawError: true
})], Admin.prototype, "Login", null);
(0, _tslib.__decorate)([(0, _vuexModuleDecorators.Action)({
  rawError: true
})], Admin.prototype, "requestOtp", null);
(0, _tslib.__decorate)([(0, _vuexModuleDecorators.Action)({
  rawError: true
})], Admin.prototype, "verifyOtp", null);
(0, _tslib.__decorate)([(0, _vuexModuleDecorators.Action)({
  rawError: true
})], Admin.prototype, "AgentLogin", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], Admin.prototype, "ResetToken", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], Admin.prototype, "GetAdminInfo", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], Admin.prototype, "ChangeRoles", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], Admin.prototype, "LogOut", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], Admin.prototype, "AgentLogOut", null);
Admin = (0, _tslib.__decorate)([(0, _vuexModuleDecorators.Module)({
  dynamic: true,
  store: _store.default,
  name: 'admin'
})], Admin);
const AdminModule = exports.AdminModule = (0, _vuexModuleDecorators.getModule)(Admin);