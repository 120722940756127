import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import { downloadExcel, getLedgerList } from "@/api/ledgers";
import Pagination from "@/components/Pagination/index.vue";
import { getDealerById, searchDealers } from "@/api/dealers";
import { getQuery } from "@/utils";
import Inr from "@/components/Inr/index.vue";
import { getAgents, getAgentsBranchWise } from "@/api/agents";
import { AdminModule } from "@/store/modules/admin";
import { generateInvoice } from "@/api/orders-dispatches";
import { getBranches } from "@/api/locations/branches";
import moment from "moment";
let default_1 = class extends Vue {
  constructor() {
    super(...arguments);
    this.BASE_URL = process.env.VUE_APP_BASE_API;
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.dealerList = [];
    this.agentList = [];
    this.listLoading = false;
    this.branchListLoading = false;
    this.branchList = [];
    this.dealerListLoading = false;
    this.agentListLoading = false;
    this.dateRange = {
      start: "",
      end: ""
    };
    this.openingBalance = 0;
    this.closingBalance = 0;
    this.isAgent = false;
    this.loggedInAdmin = null;
    this.isReadOnly = false;
    this.isAccountManager = false;
    this.downloadLoading = false;
    this.listQuery = {
      page: 1,
      limit: 50,
      dealerId: undefined,
      agentId: undefined,
      startDate: undefined,
      endDate: undefined,
      branchId: undefined
    };
    this.shortcuts = [{
      key: "thisWeek",
      label: "This week",
      value: "isoWeek"
    }, {
      key: "lastWeek",
      label: "Last week",
      value: "-isoWeek"
    }, {
      key: "last7Days",
      label: "Last 7 days",
      value: 7
    }, {
      key: "last30Days",
      label: "Last 30 days",
      value: 30
    }, {
      key: "thisMonth",
      label: "This month",
      value: "month"
    }, {
      key: "lastMonth",
      label: "Last month",
      value: "-month"
    }, {
      key: "thisYear",
      label: "This FY",
      value: () => {
        const currentDate = moment();
        let startYear = currentDate.year();
        // Financial year starts in April
        const startMonth = 3; // April (0-indexed as 3 in moment.js)
        const startDay = 1;
        if (currentDate.month() <= 2) {
          // If before April, move to the previous financial year
          startYear--;
        }
        const thisFinancialYearStart = moment([startYear, startMonth, startDay]);
        const thisFinancialYearEnd = moment([startYear + 1, startMonth, startDay]).subtract(1, "days");
        return {
          start: thisFinancialYearStart,
          end: thisFinancialYearEnd
        };
      }
    }, {
      key: "lastYear",
      label: "Last FY",
      value: () => {
        const currentDate = moment();
        let startYear = currentDate.year();
        // Financial year starts in April
        const startMonth = 3;
        const startDay = 1;
        if (currentDate.month() <= 2) {
          // Before April, use the previous financial year
          startYear--;
        }
        const lastFinancialYearStart = moment([startYear - 1, startMonth, startDay]);
        const lastFinancialYearEnd = moment([startYear, startMonth, startDay]).subtract(1, "days");
        return {
          start: lastFinancialYearStart,
          end: lastFinancialYearEnd
        };
      }
    }];
  }
  async activated() {
    var _AdminModule$branchId;
    if (AdminModule.roles.includes("Read Only")) {
      this.isReadOnly = true;
    }
    if (AdminModule.roles.includes("Account Manager")) {
      this.isAccountManager = true;
    }
    // get logged in admin
    this.loggedInAdmin = AdminModule;
    if (((_AdminModule$branchId = AdminModule.branchIds) === null || _AdminModule$branchId === void 0 ? void 0 : _AdminModule$branchId.length) !== 0 && !AdminModule.roles.includes("Super Admin") && !AdminModule.roles.includes("Admin")) {
      this.listQuery.branchId = AdminModule.branchIds;
      // fetch branches for other users
      await this.fetchAgentsBranch(this.listQuery.branchId);
      await this.fetchBranches(false);
    } else {
      // fetch branches for admin
      await this.fetchBranches();
      await this.getAgentList();
    }
    // set date range filter for current month
    this.dateRange = {
      start: this.$moment("2022-04-01").format("YYYY-MM-01 12:00") + " am",
      end: this.$moment().endOf("day").format("YYYY-MM-DD 11:59") + " pm"
    };
    if (this.$route.query.startDate && this.$route.query.endDate) {
      this.dateRange = {
        start: this.$route.query.startDate,
        end: this.$route.query.endDate
      };
    }
    // fetch agent list
    // this.getAgentList();
    // check if logged in user is Agent/Admin
    if (AdminModule.userType === "Agent") {
      this.isAgent = true;
      this.listQuery.agentId = AdminModule.agentId;
    }
    // check if dealer ID is passed in query params
    if (this.$route.query.dealerId) {
      this.listQuery.dealerId = +this.$route.query.dealerId;
      // fetch selected dealer
      await this.getDealerById(this.listQuery.dealerId);
      // fetch ledger data
      await this.getList();
    }
  }
  async getList() {
    try {
      this.listLoading = true;
      if (this.dateRange) {
        this.listQuery.startDate = this.dateRange.start;
        this.listQuery.endDate = this.dateRange.end;
      } else {
        this.$notify({
          type: "warning",
          title: "Warning",
          message: "Please select a daterange"
        });
        return false;
      }
      if (!this.listQuery.dealerId && !this.listQuery.agentId) {
        this.$notify({
          type: "warning",
          title: "Warning",
          message: "Please select a dealer or agent"
        });
        return false;
      }
      const data = await getLedgerList(this.listQuery);
      this.list = data.data;
      this.total = data.total;
      this.openingBalance = data.openingBalance;
      this.closingBalance = data.closingBalance;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    } finally {
      this.listLoading = false;
    }
  }
  async fetchBranches(superAdmin = true) {
    this.branchListLoading = true;
    // Base query
    const query = {
      enabled: true,
      sort: "name,ASC"
    };
    //  filter only if superAdmin is false
    if (superAdmin === false) {
      if (this.listQuery.branchId.length === 0) {
        this.listQuery.branchId = AdminModule.branchIds;
      }
      query.id = this.listQuery.branchId;
      query.filter = {
        enabled: "eq",
        id: "$in"
      };
    }
    // Fetch branch list
    this.branchList = await getBranches(getQuery(query));
    this.branchListLoading = false;
  }
  async getDealerList(query = null) {
    try {
      const isNumeric = /^[0-9]+$/.test(query);
      // search only if length is greater than 1 if query is id else 2
      if (!query || (isNumeric ? query.length < 1 : query.length < 2)) {
        this.dealerList = [];
        return;
      }
      const searchParams = {
        keyword: query,
        searchBy: isNumeric ? "dealerId" : "name",
        branchId: this.listQuery.branchId !== undefined ? this.listQuery.branchId : AdminModule.branchIds
      };
      if (this.listQuery.branchId !== undefined) {
        searchParams.branchId = this.listQuery.branchId;
      }
      this.dealerList = await searchDealers(searchParams);
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async fetchAgentsBranch(branchId) {
    try {
      if (this.listQuery.branchId === "") {
        branchId = AdminModule.branchIds;
      }
      this.agentList = await getAgentsBranchWise(getQuery({
        enabled: true,
        sort: "name,ASC",
        branchId
      }));
    } catch (error) {}
  }
  async getDealerById(dealerId = null) {
    this.dealerListLoading = true;
    const data = await getDealerById(dealerId);
    if (data) {
      this.dealerList = [data];
    }
    this.dealerListLoading = false;
  }
  async getAgentList(query = null) {
    try {
      this.agentList = await getAgents(getQuery({
        enabled: true,
        sort: "name,ASC",
        filter: {
          enabled: "eq"
        }
      }));
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async handleFilter() {
    this.listQuery.page = 1;
    if (!AdminModule.roles.includes("Super Admin") && !AdminModule.roles.includes("Admin")) {
      await this.fetchAgentsBranch(this.listQuery.branchId);
      await this.fetchBranches(false);
      this.getList();
    } else {
      await this.fetchBranches();
      await this.getAgentList();
      this.getList();
    }
  }
  async handleExcelDownload() {
    this.downloadLoading = false;
    try {
      const query = this.listQuery;
      const blob = await downloadExcel(query);
      // Create a blob URL for the Excel file
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ledgers.xlsx");
      document.body.appendChild(link);
      // Simulate click to trigger download
      link.click();
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading Excel:", error);
      this.$message.error("Error downloading Excel");
    } finally {
      // Reset loading state
      this.downloadLoading = false;
    }
  }
  async downloadInvoice(id = 0) {
    try {
      // get invoice url
      this.listLoading = true;
      const url = await generateInvoice(id);
      if (url) {
        window.open(url, "_blank");
      } else {
        this.$message({
          message: "Failed to generate invoice",
          type: "error"
        });
      }
    } catch (error) {} finally {
      this.listLoading = false;
    }
  }
};
default_1 = __decorate([Component({
  name: "LedgerList",
  components: {
    Pagination,
    Inr
  }
})], default_1);
export default default_1;