import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
import { defaultRangeData, getRangeById, updateRange, createRange } from "@/api/ranges";
import { AppModule } from "@/store/modules/app";
import { TagsViewModule } from "@/store/modules/tags-view";
import router from "@/router";
import { Action } from "vuex-module-decorators";
import FileUpload from "@/components/FileUpload/index.vue";
import { AdminModule } from "@/store/modules/admin";
let default_1 = class default_1 extends Vue {
  constructor() {
    super(...arguments);
    this.image = [];
    this.icon = [];
    this.validateRequire = (rule, value, callback) => {
      if (value === "") {
        if (rule.field === "imageURL") {
          this.$message({
            message: "Upload cover image is required",
            type: "error"
          });
        } else {
          this.$message({
            message: rule.field + " is required",
            type: "error"
          });
        }
        callback(new Error(rule.field + " is required"));
      } else {
        callback();
      }
    };
    this.isReadOnly = false;
    this.formData = Object.assign({}, defaultRangeData);
    this.loading = false;
    this.rules = {
      name: [{
        required: true,
        message: "This field is required",
        trigger: "blur"
      }, {
        min: 3,
        max: 150,
        message: "Length should be 3 to 150",
        trigger: "blur"
      }]
    };
  }
  get lang() {
    return AppModule.language;
  }
  activated() {
    if (AdminModule.roles.includes("Read Only")) {
      this.isReadOnly = true;
    }
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, defaultRangeData);
    }
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await getRangeById(id);
      this.formData = data;
      // display icon
      this.icon.push({
        name: data.name,
        url: data.icon
      });
      // display image
      this.image.push({
        name: data.name,
        url: data.image
      });
      // Just for test
      const title = this.formData.name;
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {} // eslint-disable-line  no-empty
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  iconUploaded(file) {
    this.formData.icon = file.response;
  }
  imageUploaded(file) {
    this.formData.image = file.response;
  }
  async saveForm() {
    try {
      this.loading = true;
      if (this.isEdit) {
        await updateRange(this.formData.id, this.formData);
      } else {
        await createRange(this.formData);
      }
      this.loading = false;
      this.$notify({
        title: "Success",
        message: "Range saved",
        type: "success",
        duration: 2000
      });
      router.push("/ranges/list");
    } catch (err) {
      this.loading = false;
    }
  }
};
__decorate([Prop({
  default: false
})], default_1.prototype, "isEdit", void 0);
__decorate([Action], default_1.prototype, "saveForm", null);
default_1 = __decorate([Component({
  name: "RangeDetail",
  components: {
    FileUpload
  }
})], default_1);
export default default_1;