"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterAsyncRoutes = exports.PermissionModule = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _router = require("@/router");
var _store = _interopRequireDefault(require("@/store"));
const hasPermission = (roles, route) => {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role));
  } else {
    return true;
  }
};
const filterAsyncRoutes = (routes, roles) => {
  const res = [];
  routes.forEach(route => {
    const r = {
      ...route
    };
    if (hasPermission(roles, r)) {
      if (r.children) {
        r.children = filterAsyncRoutes(r.children, roles);
      }
      res.push(r);
    }
  });
  return res;
};
exports.filterAsyncRoutes = filterAsyncRoutes;
let Permission = class Permission extends _vuexModuleDecorators.VuexModule {
  constructor() {
    super(...arguments);
    this.routes = [];
    this.dynamicRoutes = [];
  }
  SET_ROUTES(routes) {
    this.routes = _router.constantRoutes.concat(routes);
    this.dynamicRoutes = routes;
  }
  GenerateRoutes(roles) {
    let accessedRoutes;
    if (roles.includes('Super Admin')) {
      accessedRoutes = _router.asyncRoutes;
    } else {
      accessedRoutes = filterAsyncRoutes(_router.asyncRoutes, roles);
    }
    this.SET_ROUTES(accessedRoutes);
  }
};
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Permission.prototype, "SET_ROUTES", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], Permission.prototype, "GenerateRoutes", null);
Permission = (0, _tslib.__decorate)([(0, _vuexModuleDecorators.Module)({
  dynamic: true,
  store: _store.default,
  name: 'permission'
})], Permission);
const PermissionModule = exports.PermissionModule = (0, _vuexModuleDecorators.getModule)(Permission);