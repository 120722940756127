"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _products = require("@/api/products");
var _index = _interopRequireDefault(require("@/components/HelpText/index.vue"));
var _index2 = _interopRequireDefault(require("@/components/Tinymce/index.vue"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.loading = false;
    this.rules = {};
    this.inputTagVisible = false;
    this.inputTagValue = '';
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  activated() {}
  handleProductTypeChange() {
    this.$emit('updated', this.formData);
  }
  async saveForm() {
    this.loading = true;
    try {
      const response = await (0, _products.updateProduct)(this.formData.id, this.formData);
      this.formData = response;
      this.$notify({
        title: 'Success',
        message: 'saved',
        type: 'success',
        duration: 2000
      });
      this.$emit('updated', this.formData);
    } catch (err) {
      this.$notify.error(err.toString());
    } finally {
      this.loading = false;
    }
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  showTagInput() {
    this.inputTagVisible = true;
    this.focusTagInput();
  }
  focusTagInput() {
    this.$nextTick(() => {
      this.$refs.saveTagInput.$refs.input.focus();
    });
  }
  handleTagInputConfirm(event) {
    const inputValue = this.inputTagValue;
    if (inputValue) {
      let tags = [];
      if (this.formData.tags) {
        tags = this.formData.tags.split(',');
      }
      // push if not exist
      if (tags.indexOf(inputValue) === -1) {
        tags.push(inputValue);
      } else {
        this.$message({
          showClose: true,
          message: inputValue + ' already exist!',
          type: 'warning'
        });
      }
      this.formData.tags = tags.join(',');
    }
    this.inputTagValue = '';
    // if entered
    if (event.keyCode && event.keyCode === 13) {
      this.focusTagInput();
    } else {
      this.inputTagVisible = false;
    }
  }
  handleTagClose(tag) {
    const tags = this.formData.tags.split(',');
    this.formData.tags = tags.splice(tags.indexOf(tag), 1).join(',');
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: {}
})], default_1.prototype, "formData", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ProductAdvanceTab',
  components: {
    HelpText: _index.default,
    Tinymce: _index2.default
  }
})], default_1);
var _default = exports.default = default_1;