"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrder = exports.reviseOrder = exports.pendingPayment = exports.orderExport = exports.orderCount = exports.manageRating = exports.manageOrdersProducts = exports.getStatusCount = exports.getOrdersProducts = exports.getOrders = exports.getOrderInfo = exports.getOrderById = exports.getDispatchProducts = exports.getDispatchList = exports.fileTransferHeaders = exports.downloadExcel = exports.deleteOrdersProduct = exports.deleteOrder = exports.defaultOrderData = exports.createOrder = exports.confirmOrder = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultOrderData = exports.defaultOrderData = {
  id: undefined,
  orderReference: '',
  customerIP: '',
  status: '',
  orderedBy: '',
  comments: '',
  total: 0,
  shippingCharges: 0,
  taxedAmount: 0,
  discountAmount: 0,
  amountReceived: 0,
  couponId: 0,
  grandTotal: 0,
  totalProducts: 0,
  totalQuantity: 0,
  orderTimestamp: '',
  invoiceAttachment: null,
  otherAttachment: null,
  dealerId: null,
  dealer: {
    id: null
  },
  dealersUserId: null,
  dealersUser: {
    id: null
  },
  agentId: null,
  agent: {
    id: null
  },
  billingAddressId: 0,
  billingAddress: {
    id: null
  },
  stateId: 0,
  state: {
    id: null
  },
  deliveryAddressId: 0,
  deliveryAddress: {
    id: null
  }
};
const getOrders = params => (0, _request.default)({
  url: '/orders',
  method: 'get',
  params
});
exports.getOrders = getOrders;
const getOrderById = id => (0, _request.default)({
  url: `/orders/${id}`,
  method: 'get'
});
exports.getOrderById = getOrderById;
const updateOrder = (id, data) => (0, _request.default)({
  url: `/orders/${id}`,
  method: 'patch',
  data
});
exports.updateOrder = updateOrder;
const deleteOrder = id => (0, _request.default)({
  url: `/orders/${id}`,
  method: 'delete'
});
exports.deleteOrder = deleteOrder;
const createOrder = data => (0, _request.default)({
  url: '/orders/',
  method: 'post',
  data
});
exports.createOrder = createOrder;
const orderCount = data => (0, _request.default)({
  url: '/orders/count',
  method: 'post',
  data
});
exports.orderCount = orderCount;
const getStatusCount = params => (0, _request.default)({
  url: '/orders/count/status',
  method: 'get',
  params
});
exports.getStatusCount = getStatusCount;
const orderExport = data => (0, _request.default)({
  url: '/orders/export',
  method: 'post',
  data
});
exports.orderExport = orderExport;
const confirmOrder = (orderId = null) => (0, _request.default)({
  url: '/transactions/update-order/' + orderId,
  method: 'get'
});
exports.confirmOrder = confirmOrder;
const getOrderInfo = id => (0, _request.default)({
  url: `/orders/info/${id}`,
  method: 'get'
});
exports.getOrderInfo = getOrderInfo;
const getOrdersProducts = params => (0, _request.default)({
  url: '/orders-products',
  method: 'get',
  params
});
exports.getOrdersProducts = getOrdersProducts;
const manageOrdersProducts = data => (0, _request.default)({
  url: '/orders-products/bulk-add',
  method: 'post',
  data
});
exports.manageOrdersProducts = manageOrdersProducts;
const manageRating = (orderId, data) => (0, _request.default)({
  url: `/orders-products/manage-rating/${orderId}`,
  method: 'patch',
  data
});
exports.manageRating = manageRating;
const pendingPayment = userId => (0, _request.default)({
  url: `/orders/pending-payment/${userId}`,
  method: 'get'
});
exports.pendingPayment = pendingPayment;
const getDispatchList = data => (0, _request.default)({
  url: '/orders-products/stats',
  method: 'post',
  data
});
exports.getDispatchList = getDispatchList;
const getDispatchProducts = id => (0, _request.default)({
  url: `/orders-products/pending-dispatch/${id}`,
  method: 'get'
});
exports.getDispatchProducts = getDispatchProducts;
const deleteOrdersProduct = id => (0, _request.default)({
  url: `/orders-products/${id}`,
  method: 'delete'
});
exports.deleteOrdersProduct = deleteOrdersProduct;
const reviseOrder = data => (0, _request.default)({
  url: '/orders/revise',
  method: 'post',
  data
});
exports.reviseOrder = reviseOrder;
const downloadExcel = data => (0, _request.default)({
  url: '/orders/download-excel',
  method: 'post',
  data,
  responseType: 'blob'
});
exports.downloadExcel = downloadExcel;