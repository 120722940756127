"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _order = require("@/api/order");
var _dealersAddresses = require("@/api/dealers-addresses");
var _products = require("@/api/products");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _utils = require("@/utils");
var _OrderInfoTab = _interopRequireDefault(require("@/views/order/components/OrderInfoTab.vue"));
var _OrderUserTab = _interopRequireDefault(require("@/views/order/components/OrderUserTab.vue"));
var _router = _interopRequireDefault(require("@/router"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.formData = Object.assign({}, _order.defaultOrderData);
    this.loading = false;
    this.addressList = [];
    this.activeStep = 0;
    this.productListLoading = false;
    this.listLoading = false;
    this.products = [];
    this.selectedVariationId = null;
    this.ordersProducts = [];
    this.dealer = null;
    this.note = null;
    this.rules = {
      billingAddressId: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      },
      deliveryAddressId: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      },
      comments: [{
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      }, {
        min: 3,
        max: 150,
        message: 'Length should be 3 to 150',
        trigger: 'blur'
      }]
    };
  }
  get lang() {
    return _app.AppModule.language;
  }
  activated() {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(id);
    } else {
      this.formData = Object.assign({}, _order.defaultOrderData);
    }
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _order.getOrderInfo)(id);
      this.formData = data;
      this.dealer = data.dealer;
      // get saved products
      this.ordersProducts = data.ordersProduct;
      if (this.ordersProducts && this.ordersProducts.length) {
        this.ordersProducts.forEach(item => {
          item.numberOfPieces = item.quantity;
        });
      }
      // Just for test
      const title = 'Order';
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getAddressList() {
    try {
      // get user address list
      this.addressList = await (0, _dealersAddresses.getDealersAddresses)((0, _utils.getQuery)({
        enabled: true,
        dealerId: this.formData.dealerId,
        sort: 'name,ASC',
        filter: {
          enabled: '$eq',
          dealerId: '$eq'
        }
      }));
      // show default selected if length is 1
      if (this.addressList.length === 1) {
        this.formData.deliveryAddressId = this.addressList[0].id;
        this.formData.billingAddressId = this.addressList[0].id;
      }
    } catch (error) {} // eslint-disable-line  no-empty
  }
  // quantity cannot be zero
  checkQuantity(index = 0, row) {
    if (row.numberOfCartons === 0 && row.numberOfBoxes === 0) {
      setTimeout(() => {
        this.$set(this.ordersProducts[index], 'numberOfBoxes', 1);
        // calculate numberOfPieces as per numberOfCartons/numberOfBoxes
        this.calculateNumberOfPieces(index, row);
      }, 500);
    } else if (typeof row.numberOfCartons === 'undefined') {
      setTimeout(() => {
        this.$set(this.ordersProducts[index], 'numberOfCartons', 0);
      }, 500);
    } else if (typeof row.numberOfBoxes === 'undefined') {
      setTimeout(() => {
        this.$set(this.ordersProducts[index], 'numberOfBoxes', 0);
      }, 500);
    }
    // calculate numberOfPieces as per numberOfCartons/numberOfBoxes
    this.calculateNumberOfPieces(index, row);
  }
  calculateNumberOfPieces(index = 0, row) {
    let numberOfPieces = 0;
    if (row.numberOfCartons > 0) {
      numberOfPieces += row.numberOfCartons * row.product.masterCarton;
    }
    if (row.numberOfBoxes > 0) {
      numberOfPieces += row.numberOfBoxes * row.product.piecesPerBox;
    }
    row.numberOfPieces = numberOfPieces;
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.orderReference}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  validateOrder() {
    let showOrderBtn = true;
    if (this.formData.status !== 'in cart') {
      this.note = 'Order is already placed';
      showOrderBtn = false;
    }
    if (+this.formData.grandTotal > parseFloat(this.dealer.creditLimitAvailable)) {
      this.note = 'Order amount exceeds available credit';
      showOrderBtn = false;
    }
    return showOrderBtn;
  }
  async placeOrder() {
    try {
      this.loading = true;
      const datetime = this.$moment().format('YYYY-MM-DDTHH:mm:ss.SSS');
      // revise order for final calculations
      await (0, _order.reviseOrder)({
        id: this.formData.id
      });
      // update order status
      await (0, _order.updateOrder)(this.formData.id, {
        status: 'Order Confirmed',
        userType: 'Agent',
        orderTimestamp: datetime
      });
      await this.fetchData(this.formData.id);
      this.$notify({
        title: 'Success',
        message: 'Order placed',
        type: 'success',
        duration: 2000
      });
      _router.default.push('/order/list');
    } catch (error) {
      this.$notify({
        title: 'Error',
        message: 'Unable to process order',
        type: 'error',
        duration: 2000
      });
    } finally {
      this.loading = false;
    }
  }
  async saveForm() {
    try {
      this.loading = true;
      // check if order reference is set
      if (!this.formData.orderReference) {
        this.formData.orderReference = new Date().getTime().toString();
      }
      if (this.isEdit) {
        // billing address
        if (this.formData.billingAddressId) {
          this.formData.billingAddress = {
            id: this.formData.billingAddressId
          };
        }
        // shipping address
        if (this.formData.deliveryAddressId) {
          this.formData.deliveryAddress = {
            id: this.formData.deliveryAddressId
          };
        }
        await (0, _order.updateOrder)(this.formData.id, this.formData);
      } else {
        await (0, _order.createOrder)(this.formData);
      }
      this.$notify({
        title: 'Success',
        message: 'Address saved',
        type: 'success',
        duration: 2000
      });
      this.loading = false;
      // goto next step
      this.activeStep = 2;
    } catch (err) {
      this.loading = false;
    }
  }
  goToStep(step = 0) {
    this.activeStep = step;
    if (step === 1) {
      this.getAddressList();
    } else if (step === 2) {
      // check if step 1 is completed
      if (this.formData.billingAddressId && this.formData.deliveryAddressId) {
        this.fetchData(this.formData.id);
      } else {
        this.$notify({
          title: 'Warning',
          message: 'Complete step 2 first',
          type: 'warning',
          duration: 2000
        });
        this.activeStep = 1;
      }
    }
  }
  async getProductList(query) {
    try {
      this.productListLoading = true;
      if (query && query.length > 2) {
        this.products = await (0, _products.searchProducts)((0, _utils.getQuery)({
          keyword: query
        }));
      }
      this.productListLoading = false;
    } catch (err) {
      this.productListLoading = false;
      throw err;
    }
  }
  async addVariation() {
    if (!this.selectedVariationId) {
      return;
    }
    // check if selectedVariationId already exists in order
    const index = this.ordersProducts.findIndex(item => item.productsVariationId === this.selectedVariationId);
    if (index > -1) {
      this.$notify({
        title: 'Error',
        message: 'Product already added',
        type: 'error',
        duration: 2000
      });
      return;
    }
    // prepare product object to append to table
    let selected = null;
    this.products.map(product => {
      product.productsVariation.map(item => {
        if (item.id === this.selectedVariationId) {
          // check if enabled
          if (!item.enabled) {
            this.$notify({
              title: 'Warning',
              message: 'Product is currently unavailable',
              type: 'warning',
              duration: 3000
            });
            return false;
          }
          // chekc if stock exists
          if (item.manageStock && parseFloat(item.stockQuantity) === 0) {
            this.$notify({
              title: 'Warning',
              message: 'Product is out of stock',
              type: 'warning',
              duration: 3000
            });
            return false;
          } else {
            selected = {
              product: {
                id: product.id,
                name: product.name,
                piecesPerBox: product.piecesPerBox,
                masterCarton: product.masterCarton,
                schemesCategoryId: product.schemesCategoryId,
                rangeId: product.rangeId,
                categoryId: product.categoryId,
                subCategoryId: product.subCategoryId
              },
              productsVariation: {
                id: item.id,
                name: item.name,
                mrp: item.mrp
              }
            };
          }
        }
      });
    });
    // add product in order
    if (selected) {
      var _selected$product$sch, _selected$product$ran, _selected$product$cat, _selected$product$sub;
      this.ordersProducts.push({
        id: 0,
        orderId: this.formData.id,
        dealerId: this.formData.dealerId,
        dealersUserId: this.formData.dealersUserId,
        schemesCategoryId: (_selected$product$sch = selected.product.schemesCategoryId) !== null && _selected$product$sch !== void 0 ? _selected$product$sch : null,
        rangeId: (_selected$product$ran = selected.product.rangeId) !== null && _selected$product$ran !== void 0 ? _selected$product$ran : null,
        categoryId: (_selected$product$cat = selected.product.categoryId) !== null && _selected$product$cat !== void 0 ? _selected$product$cat : null,
        subCategoryId: (_selected$product$sub = selected.product.subCategoryId) !== null && _selected$product$sub !== void 0 ? _selected$product$sub : null,
        productId: selected.product.id,
        productsVariationId: this.selectedVariationId,
        masterCarton: selected.product.masterCarton,
        piecesPerBox: selected.product.piecesPerBox,
        quantity: selected.product.piecesPerBox,
        numberOfCartons: 0,
        numberOfBoxes: 1,
        numberOfPieces: selected.product.piecesPerBox,
        allowPriceEdit: false,
        mrp: selected.productsVariation.mrp,
        product: selected.product,
        productsVariation: selected.productsVariation
      });
      // After adding the item to the ordersProducts array, focus on the newly added row
      this.$nextTick(() => {
        const table = this.$refs.ordersProductTable;
        if (table) {
          const tbody = table.$el.querySelector('tbody');
          if (tbody) {
            const lastRow = tbody.lastElementChild;
            if (lastRow) {
              const focusableElement = lastRow.querySelector('input, button, select, textarea');
              if (focusableElement) {
                focusableElement.focus();
              } else {
                lastRow.focus();
              }
            }
          }
        }
      });
    }
  }
  async saveProducts() {
    if (this.ordersProducts.length) {
      try {
        this.loading = true;
        const dataToSave = [];
        for (const op of this.ordersProducts) {
          if (op.product) {
            op.cartonQuantity = op.numberOfCartons * op.product.masterCarton;
            op.boxQuantity = op.numberOfBoxes * op.product.piecesPerBox;
            op.quantity = op.cartonQuantity + op.boxQuantity;
          }
          dataToSave.push({
            orderId: this.formData.id,
            dealerId: this.formData.dealerId,
            dealersUserId: this.formData.dealersUserId,
            productId: op.product.id,
            productsVariationId: op.productsVariation.id,
            quantity: op.quantity,
            numberOfCartons: op.numberOfCartons,
            numberOfBoxes: op.numberOfBoxes,
            allowPriceEdit: op.allowPriceEdit,
            mrp: op.mrp,
            userType: 'Agent',
            schemesCategoryId: op.product.schemesCategoryId,
            rangeId: op.product.rangeId,
            categoryId: op.product.categoryId,
            subCategoryId: op.product.subCategoryId
          });
        }
        const res = await (0, _order.manageOrdersProducts)(dataToSave);
        if (res && res.status === 'success') {
          // refresh product list
          await this.fetchData(this.formData.id);
        }
        this.loading = false;
        this.$notify({
          title: 'Success',
          message: 'Products saved',
          type: 'success',
          duration: 2000
        });
      } catch (error) {
        this.loading = false;
      } // eslint-disable-line  no-empty
    } else {
      this.$notify({
        title: 'Error',
        message: 'Please add atleast 1 product',
        type: 'error',
        duration: 2000
      });
    }
  }
  async removeProduct(index) {
    try {
      // give confirmation popup
      const confirm = await this.$confirm('Are you sure you want to remove?', 'Confirmation', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning',
        confirmButtonClass: 'danger'
      });
      if (confirm === 'confirm') {
        this.listLoading = true;
        if (this.ordersProducts[index].id) {
          await (0, _order.deleteOrdersProduct)(this.ordersProducts[index].id);
        }
        // remove row from data
        this.ordersProducts.splice(index, 1);
        // revise order
        // if (this.ordersProducts.length) {
        //   this.saveProducts();
        //   refresh list
        //   this.fetchData(this.formData.id);
        // }
      }
    } catch (error) {
      this.$notify({
        title: 'Error',
        message: 'Unable to delete product',
        type: 'error',
        duration: 2000
      });
    } finally {
      this.listLoading = false;
    }
  }
  getFullAddress(address) {
    const addressArray = [];
    if (address.line1) {
      // replace () with [] if any
      const addressLine1 = address.line1.replace(/\(/g, '[').replace(/\)/g, ']');
      addressArray.push(addressLine1);
    }
    if (address.line2) {
      // replace () with [] if any
      const addressLine2 = address.line2.replace(/\(/g, '[').replace(/\)/g, ']');
      addressArray.push(addressLine2);
    }
    if (address.cityName) {
      addressArray.push(address.cityName);
    }
    if (address.state) {
      addressArray.push(address.state.name);
    }
    if (address.pincode) {
      addressArray.push(address.pincode.pincode);
    }
    return addressArray.join();
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'UserOrderDetail',
  components: {
    OrderInfoTab: _OrderInfoTab.default,
    OrderUserTab: _OrderUserTab.default
  }
})], default_1);
var _default = exports.default = default_1;