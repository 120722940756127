"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _transporterSubMenu = require("./subMenus/transporter-sub-menu");
const orderRouter = {
  path: '/order',
  component: _index.default,
  redirect: 'noredirect',
  name: 'orderManagement.title',
  meta: {
    roles: ['Super Admin', 'Admin', 'Unit Manager', 'Agent', 'Read Only', 'Invoice Manager', 'Sales Manager', 'Order Manager'],
    title: 'orderManagement.title',
    icon: 'shopping'
  },
  children: [{
    path: '/order/list',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/order/list.vue'))),
    name: 'Orders',
    meta: {
      roles: ['Super Admin', 'Admin', 'Unit Manager', 'Agent', 'Read Only', 'Invoice Manager', 'Sales Manager', 'Order Manager'],
      title: 'orderManagement.orders.title',
      icon: 'shopping',
      noCache: true
    }
  }, {
    path: '/order/pending-dispatch',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/order/pending-dispatch.vue'))),
    name: 'Pending Dispatch',
    meta: {
      title: 'orderManagement.orders.dispatch',
      icon: 'shopping',
      noCache: true,
      hidden: true
    }
  }, {
    path: '/order/edit/:id(.*)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/order/manage.vue'))),
    name: 'orderManagement.orders.edit',
    props: {
      isEdit: true
    },
    meta: {
      title: 'orderManagement.orders.edit',
      roles: ['Super Admin', 'Admin', 'Unit Manager', 'Order Manager'],
      noCache: true,
      activeMenu: '/order/list',
      hidden: true
    }
  }, {
    path: '/order/ratings/:id(.*)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/order/ratings.vue'))),
    name: 'orderManagement.orders.manageRating',
    meta: {
      title: 'orderManagement.orders.manageRating',
      roles: ['Super Admin', 'Admin', 'Order Manager'],
      noCache: true,
      activeMenu: '/order/list',
      hidden: true
    }
  }, {
    path: '/order/modify/:id(.*)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/order/manage-order.vue'))),
    name: 'orderManagement.orders.manage',
    props: {
      isEdit: true
    },
    meta: {
      title: 'orderManagement.orders.manage',
      roles: ['Super Admin', 'Admin', 'Unit Manager', 'Agent', 'Order Manager'],
      noCache: true,
      activeMenu: '/order/list',
      hidden: true
    }
  }, {
    path: '/order/:id(.*)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/order/components/OrderInfoDetails.vue'))),
    name: 'orderManagement.orders.info',
    props: {
      isEdit: true
    },
    meta: {
      title: 'orderManagement.orders.info',
      noCache: true,
      activeMenu: '/order/list',
      hidden: true
    }
  }, {
    path: '/order/add',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/order/manage.vue'))),
    name: 'orderManagement.orders.add',
    props: {
      isEdit: false
    },
    meta: {
      title: 'orderManagement.orders.add',
      roles: ['Super Admin', 'Order Manager'],
      noCache: true,
      activeMenu: '/order/list',
      hidden: true
    }
  },
  // ...transactionsSubMenu,
  ..._transporterSubMenu.transportersSubMenu]
};
var _default = exports.default = orderRouter;