"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  route: {
    dashboard: 'Dashboard',
    documentation: 'Documentation',
    guide: 'Guide',
    permission: 'Permission',
    pagePermission: 'Page Permission',
    rolePermission: 'Role Permission',
    directivePermission: 'Directive Permission',
    icons: 'Icons',
    components: 'Components',
    tinymce: 'Tinymce',
    markdown: 'Markdown',
    jsonEditor: 'JSON Editor',
    splitPane: 'SplitPane',
    avatarUpload: 'Avatar Upload',
    dropzone: 'Dropzone',
    sticky: 'Sticky',
    countTo: 'Count To',
    componentMixin: 'Mixin',
    backToTop: 'Back To Top',
    draggableDialog: 'Draggable Dialog',
    draggableKanban: 'Draggable Kanban',
    draggableList: 'Draggable List',
    draggableSelect: 'Draggable Select',
    charts: 'Charts',
    barChart: 'Bar Chart',
    lineChart: 'Line Chart',
    mixedChart: 'Mixed Chart',
    example: 'Example',
    nested: 'Nested Routes',
    menu1: 'Menu 1',
    'menu1-1': 'Menu 1-1',
    'menu1-2': 'Menu 1-2',
    'menu1-2-1': 'Menu 1-2-1',
    'menu1-2-2': 'Menu 1-2-2',
    'menu1-3': 'Menu 1-3',
    menu2: 'Menu 2',
    table: 'Table',
    dynamicTable: 'Dynamic Table',
    draggableTable: 'Draggable Table',
    inlineEditTable: 'Inline Edit Table',
    complexTable: 'Complex Table',
    tab: 'Tab',
    form: 'Form',
    createArticle: 'Create Article',
    editArticle: 'Edit Article',
    articleList: 'Article List',
    errorPages: 'Error Pages',
    page401: '401',
    page404: '404',
    page403: '403',
    errorLog: 'Error Log',
    excel: 'Excel',
    exportExcel: 'Export Excel',
    selectExcel: 'Export Selected',
    mergeHeader: 'Merge Header',
    uploadExcel: 'Upload Excel',
    zip: 'Zip',
    pdf: 'PDF',
    exportZip: 'Export Zip',
    theme: 'Theme',
    clipboard: 'Clipboard',
    i18n: 'I18n',
    externalLink: 'External Link',
    profile: 'Profile',
    adminManagement: {
      title: 'Admin Management',
      admins: {
        title: 'Admins',
        manage: 'Manage Admin'
      },
      roles: {
        title: 'Admin Roles',
        manage: 'Manage Role'
      }
    },
    userManagement: {
      title: 'User Management',
      users: {
        title: 'User',
        add: 'Add User',
        edit: 'Edit User'
      },
      document: {
        title: 'Documents',
        manage: 'Manage Documents'
      },
      usersRoles: {
        title: 'Users Role',
        manage: 'Manage Users Role'
      },
      wallet: {
        title: 'Wallet',
        manage: 'Manage Wallet'
      }
    },
    bannerManagement: {
      title: 'Banner Management',
      banners: {
        title: 'Banners',
        add: 'Add Banner',
        edit: 'Edit Banner'
      }
    },
    schemeManagement: {
      title: 'Schemes & Policies',
      schemes: {
        title: 'Schemes',
        add: 'Add Schemes',
        edit: 'Edit Schemes'
      },
      schemesDiscount: {
        title: 'Scheme Discounts',
        add: 'Add Scheme Discount',
        edit: 'Edit Scheme Discount'
      },
      moqs: {
        title: 'MOQ',
        add: 'Add MOQ',
        edit: 'Edit MOQ'
      },
      moqsDiscount: {
        title: 'MOQ Discounts',
        add: 'Add MOQ Discount',
        edit: 'Edit MOQ Discount'
      },
      policy: {
        title: 'Policies',
        add: 'Add Policy',
        edit: 'Edit Policy'
      },
      policiesDiscount: {
        title: 'Policy Discounts',
        add: 'Add Policy Discount',
        edit: 'Edit Policy Discount'
      },
      inventoryInward: {
        title: 'Inventory In',
        add: 'Add Inventory',
        edit: 'Edit Inventory'
      }
    },
    settingManagement: {
      title: 'Setting Management',
      settings: {
        title: 'Setting',
        add: 'Add Setting',
        edit: 'Edit Setting'
      }
    },
    productManagement: {
      title: 'Product Management',
      brand: {
        title: 'Brand',
        add: 'Add Brand',
        manage: 'Manage Brand'
      },
      attribute: {
        title: 'Attributes',
        add: 'Add Attribute',
        edit: 'Edit Attribute'
      },
      attributevalue: {
        title: 'Attribute Value',
        add: 'Add Attribute Value',
        edit: 'Edit Attribute Value',
        manage: 'Manage Attribute Value'
      },
      product: {
        title: 'Products',
        manage: 'Manage Product',
        import: 'Import Product'
      },
      category: {
        title: 'Categories',
        manage: 'Manage Category'
      },
      range: {
        title: 'Range',
        manage: 'Manage Range'
      },
      schemesCategories: {
        title: 'Schemes Category',
        manage: 'Manage Schemes Category'
      }
    },
    accountManagement: {
      title: 'Account Management',
      transaction: {
        title: 'Transactions',
        edit: 'Edit Transaction',
        add: 'Add Transaction',
        rtgs: 'Approve RTGS'
      },
      ledger: {
        title: 'Ledger',
        edit: 'Edit Ledger',
        add: 'Add Ledger',
        addCnDn: 'Add Credit Note / Debit Note'
      }
    },
    orderManagement: {
      title: 'Order Management',
      orders: {
        title: 'Orders',
        add: 'Add Order',
        edit: 'Edit Order',
        info: 'Order Info',
        manage: 'Manage Order',
        manageRating: 'Manage Ratings',
        dispatch: 'Pending Dispatch'
      },
      // transaction: {
      //   title: 'Transactions',
      //   edit: 'Edit Transaction',
      //   add: 'Add Transaction',
      //   rtgs: 'Approve RTGS'
      // },
      coupons: {
        title: 'Coupons',
        add: 'Add Coupon',
        edit: 'Edit Coupon'
      },
      transporter: {
        title: 'Transporters',
        edit: 'Edit Transporter',
        add: 'Add Transporter'
      },
      ordersTickets: {
        title: 'Support'
      },
      payment: {
        title: 'Payments',
        edit: 'Edit Payment',
        add: 'Add Payment',
        manage: 'Manage Payment'
      },
      dispatch: {
        title: 'Order Dispatch',
        edit: 'Edit Dispatch',
        add: 'Add Dispatch'
      }
    },
    report: {
      title: 'Reports',
      salesReport: {
        title: 'Sales Report'
      },
      itemWiseReport: {
        title: 'Item Wise Report'
      },
      newItemWiseReport: {
        title: 'Item Wise Report(New)'
      },
      hsnSummaryReport: {
        title: 'HSN Summary Report'
      },
      dealerWiseOsOdReport: {
        title: "Outstanding & Overdue Report"
      },
      dealerWiseCashDicount: {
        title: 'Dealer Cash Discounts'
      }
    },
    dealerManagement: {
      title: 'Dealer Management',
      dealer: {
        title: 'Dealers',
        add: 'Add Dealer',
        edit: 'Edit Dealer'
      },
      user: {
        title: 'Dealers Users',
        add: 'Add Dealers User',
        edit: 'Edit Dealers User'
      },
      dealersRoles: {
        title: 'Dealer Roles',
        add: 'Add Dealer Role',
        edit: 'Edit Dealer Role'
      },
      address: {
        title: 'Address',
        add: 'Add Address',
        edit: 'Edit Address'
      },
      agent: {
        title: 'Agents',
        add: 'Add Agent',
        edit: 'Edit Agent',
        manage: 'Manage Agent'
      },
      agentsUser: {
        title: 'Agent Users',
        add: 'Add Agent User',
        edit: 'Edit Agent User',
        manage: 'Manage Agent User'
      },
      company: {
        title: 'Companies',
        add: 'Add Company',
        edit: 'Edit Company',
        manage: 'Manage Company'
      }
    },
    master: {
      title: 'Master',
      tax: {
        title: 'Taxes',
        add: 'Add Tax',
        edit: 'Edit Tax',
        manage: 'Manage'
      },
      cashDiscounts: {
        title: 'Cash Discounts',
        add: 'Add Cash Discount',
        edit: 'Edit Cash Discount',
        manage: 'Manage'
      },
      office: {
        title: 'Office',
        add: 'Add Office',
        edit: 'Edit Office',
        manage: 'Manage'
      },
      measurementunits: {
        title: 'Measurement Unit',
        add: 'Add Measurement Unit',
        edit: 'Edit Measurement Unit',
        manage: 'Manage '
      }
    },
    // follow this
    locations: {
      title: 'Location Management',
      countries: {
        title: 'Countries',
        add: 'Add Country',
        edit: 'Edit Country'
      },
      states: {
        title: 'States',
        add: 'Add State',
        edit: 'Edit State'
      },
      cities: {
        title: 'Cities',
        add: 'Add City',
        edit: 'Edit City'
      },
      pincode: {
        title: 'Pincodes',
        add: 'Add Pincode',
        edit: 'Edit Pincode'
      },
      branch: {
        title: 'Branches',
        add: 'Add Branch',
        edit: 'Edit Branch'
      },
      unit: {
        title: 'Units',
        add: 'Add Unit',
        edit: 'Edit Unit'
      }
    },
    notifications: {
      title: 'Notifications',
      manage: 'Manage Notification'
    },
    inventoryManagement: {
      title: 'Inventory Management',
      dispatch: {
        title: 'Dispatch',
        add: 'Add Dispatch',
        edit: 'Edit Dispatch',
        info: 'Dispatch Info',
        pendingOrder: 'Pending Orders Products',
        outstanding: 'Outstanding',
        overdue: 'Overdue',
        lrDetail: 'LR Detail',
        updateEway: 'Update E-way',
        uploadEwayPdf: 'Upload Eway PDF'
      }
    }
  },
  navbar: {
    logOut: 'Log Out',
    dashboard: 'Dashboard',
    theme: 'Theme',
    size: 'Global Size',
    profile: 'Profile'
  },
  login: {
    title: 'Admin Login',
    logIn: 'Login',
    email: 'Email',
    otp: 'Enter OTP',
    mobile: 'Phone Number',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    any: 'any',
    verifyOtpTitle: 'Verify OTP',
    enterOtp: 'Enter OTP',
    verifyOtp: 'Verify',
    requestOtp: 'Request OTP',
    recover: 'Send Recovery Mail',
    recoverTitle: ' Password Recovery',
    thirdparty: 'Or connect with',
    thirdpartyTips: 'Can not be simulated on local, so please combine you own business simulation! ! !'
  },
  documentation: {
    documentation: 'Documentation'
  },
  permission: {
    createRole: 'New Role',
    editPermission: 'Edit',
    roles: 'Your roles',
    switchRoles: 'Switch roles',
    tips: 'In some cases, using v-permission will have no effect. For example: Element-UI  el-tab or el-table-column and other scenes that dynamically render dom. You can only do this with v-if.',
    delete: 'Delete',
    confirm: 'Confirm',
    cancel: 'Cancel'
  },
  guide: {
    description: 'The guide page is useful for some people who entered the project for the first time. You can briefly introduce the features of the project. Demo is based on ',
    button: 'Show Guide'
  },
  components: {
    documentation: 'Documentation',
    tinymceTips: 'Rich text is a core feature of the management backend, but at the same time it is a place with lots of pits. In the process of selecting rich texts, I also took a lot of detours. The common rich texts on the market have been basically used, and I finally chose Tinymce. See the more detailed rich text comparison and introduction.',
    stickyTips: 'when the page is scrolled to the preset position will be sticky on the top.',
    backToTopTips1: 'When the page is scrolled to the specified position, the Back to Top button appears in the lower right corner',
    backToTopTips2: 'You can customize the style of the button, show / hide, height of appearance, height of the return. If you need a text prompt, you can use element-ui el-tooltip elements externally',
    imageUploadTips: 'Since I was using only the vue@1 version, and it is not compatible with mockjs at the moment, I modified it myself, and if you are going to use it, it is better to use official version.'
  },
  table: {
    dynamicTips1: 'Fixed header, sorted by header order',
    dynamicTips2: 'Not fixed header, sorted by click order',
    dragTips1: 'The default order',
    dragTips2: 'The after dragging order',
    title: 'Title',
    importance: 'Importance',
    type: 'Type',
    remark: 'Remark',
    search: 'Search',
    filter: 'Filter',
    add: 'Add',
    export: 'Export',
    reviewer: 'Reviewer',
    id: 'ID',
    date: 'Date',
    author: 'Author',
    readings: 'Readings',
    status: 'Status',
    actions: 'Actions',
    edit: 'Edit',
    publish: 'Publish',
    draft: 'Draft',
    delete: 'Delete',
    cancel: 'Cancel',
    confirm: 'Confirm',
    createdDate: 'Created',
    deleteConfirmation: 'Do you want to delete, {name}?',
    deleteCanceled: 'Delete canceled',
    sort: {
      id: {
        asc: 'ID Ascending',
        desc: 'ID Descending'
      },
      enable: {
        enabled: 'Enabled',
        disabled: 'Disabled'
      }
    }
  },
  example: {
    warning: "Creating and editing pages cannot be cached by keep-alive because keep-alive's 'include' currently does not support caching based on routes, so now it's cached based on component name. If you want to achieve a similar caching effect, you can use a browser caching scheme such as localStorage. Or don't use keep-alive's 'include' to cache all pages directly. See details"
  },
  errorLog: {
    tips: 'Please click the bug icon in the upper right corner',
    description: 'Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.',
    documentation: 'Document introduction'
  },
  excel: {
    export: 'Export',
    selectedExport: 'Export Selected Items',
    placeholder: 'Please enter the file name (default excel-list)'
  },
  zip: {
    export: 'Export',
    placeholder: 'Please enter the file name (default file)'
  },
  pdf: {
    tips: 'Here we use window.print() to implement the feature of downloading PDF.'
  },
  theme: {
    change: 'Change Theme',
    documentation: 'Theme documentation',
    tips: 'Tips: It is different from the theme-pick on the navbar is two different skinning methods, each with different application scenarios. Refer to the documentation for details.'
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All'
  },
  settings: {
    title: 'Page style setting',
    theme: 'Theme Color',
    showTagsView: 'Open Tags-View',
    showSidebarLogo: 'Sidebar Logo',
    fixedHeader: 'Fixed Header',
    sidebarTextTheme: 'Sidebar Text Theme'
  },
  admin: {
    fullName: 'Full Name',
    email: 'Email',
    contactNumber: 'Mobile Number',
    enabled: 'Enabled',
    alias: 'Alias',
    role: {
      name: 'Name',
      enabled: 'Enabled'
    }
  },
  location: {
    country: {
      enabled: 'Enabled',
      name: 'Name',
      code: 'Code'
    },
    state: {
      enabled: 'Enabled',
      name: 'Name',
      code: 'Code',
      country: 'Country'
    },
    city: {
      enabled: 'Enabled',
      name: 'Name',
      code: 'Code',
      country: 'Country',
      state: 'State'
    },
    branch: {
      enabled: 'Enabled',
      name: 'Name',
      code: 'Code',
      gstNumber: 'GST Number'
    },
    unit: {
      enabled: 'Enabled',
      name: 'Name',
      code: 'Code',
      alias: 'Alias',
      unitType: 'Unit Type',
      sequence: 'Sequence'
    }
  },
  geolocation: {
    prompt: {
      title: 'Geolocation Permission',
      message: 'Kindly allow us to access your current location',
      nonCompatibleBrowser: 'You are using non compatible browser'
    }
  },
  master: {
    title: 'Masters',
    tax: {
      name: 'Tax',
      enabled: 'Enabled'
    },
    office: {
      name: 'Office Name',
      enabled: 'Enabled'
    }
  },
  address: {
    name: 'Name',
    enabled: 'Enabled'
  },
  attribute: {
    name: 'Attribute',
    enabled: 'Enabled'
  },
  attributeValue: {
    name: 'Attribute Value',
    enabled: 'Enabled'
  },
  brand: {
    name: 'Brand',
    enabled: 'Enabled'
  },
  category: {
    name: 'Category',
    enabled: 'Enabled'
  },
  range: {
    name: 'Range',
    enabled: 'Enabled'
  },
  schemesCategory: {
    name: 'Schemes Category',
    enabled: 'Enabled'
  },
  contact: {
    name: 'Contact',
    enabled: 'Enabled'
  },
  order: {
    name: 'Order Reference',
    enabled: 'Enabled',
    userName: 'User Name',
    mobile: 'Mobile Number',
    email: 'Email Address',
    dealer: 'Dealer',
    agent: 'Agent'
  },
  measurementunits: {
    name: 'Measurement Unit',
    enabled: 'Enabled'
  },
  organization: {
    name: 'Organization',
    enabled: 'Enabled'
  },
  pincode: {
    name: 'Name',
    code: 'Pincode',
    enabled: 'Enabled'
  },
  agent: {
    name: 'Agent Name',
    enabled: 'Enabled',
    code: 'Agent Code'
  },
  dealer: {
    name: 'Dealer Name',
    enabled: 'Enabled',
    code: 'Dealer Code',
    status: 'Status'
  },
  coupon: {
    enabled: 'Enabled',
    name: 'Name',
    code: 'Code'
  },
  user: {
    id: 'ID',
    enabled: 'Enabled',
    company: 'Company',
    fullName: 'Full Name',
    email: 'Email',
    mobileNumber: 'Mobile Number',
    walletAction: 'Wallet Action'
  },
  dealersRoles: {
    id: 'ID',
    enabled: 'Enabled',
    name: 'Name'
  },
  product: {
    enabled: 'Enabled',
    featured: 'Featured',
    name: 'Name',
    catalogueCode: 'Catalogue Code',
    category: 'Category',
    attribute: 'Attribute'
  },
  transporter: {
    enabled: 'Enabled',
    name: 'Name',
    user: 'User'
  },
  scheme: {
    enabled: 'Enabled',
    name: 'Name'
  },
  schemesDiscount: {
    enabled: 'Enabled',
    state: 'State',
    agent: 'Agent',
    range: 'Range',
    schemesCategory: 'Schemes Category',
    category: 'Category',
    dealersRole: 'Dealers Role'
  },
  policy: {
    enabled: 'Enabled',
    name: 'Name'
  },
  policiesDiscount: {
    enabled: 'Enabled',
    range: 'Range',
    dealersRole: 'Dealers Role'
  },
  moq: {
    enabled: 'Enabled',
    name: 'Name'
  },
  moqsDiscount: {
    enabled: 'Enabled',
    product: 'Product',
    variation: 'Variation',
    range: 'Range',
    category: 'Category'
  },
  inventoryInward: {
    enabled: 'Enabled',
    product: 'Product',
    variation: 'Variation',
    unit: 'Unit'
  },
  global: {
    form: {
      save: 'Save',
      add: 'Add',
      edit: 'Edit',
      delete: 'Delete',
      reset: 'Reset',
      import: 'Import',
      confirm: 'Confirm'
    },
    common: {
      ok: 'Ok',
      error: 'Error'
    }
  },
  notifications: {
    enabled: 'Enabled',
    title: 'Title',
    message: 'Sub Title',
    state: 'State',
    city: 'City',
    send: 'Send'
  },
  dealersDispatch: {
    enabled: 'Enabled',
    dealer: 'Dealer',
    agent: 'Agent',
    unit: 'Unit',
    branch: 'Branch',
    unitManager: 'Unit Manager',
    transporter: 'Transporter'
  },
  inventoryManagement: {
    title: 'Inventory Management',
    inventoryInward: {
      title: 'Inventory Inward'
    },
    dispatch: {
      title: 'Dispatch',
      product: 'Product',
      dealer: 'Dealer'
    }
  },
  company: {
    enabled: 'Enabled',
    name: 'Name',
    gstNumber: 'GST Number',
    panNumber: 'Pan Number',
    bank: 'Bank',
    address: 'Address'
  },
  ledger: {
    dealer: 'Dealer',
    dispatch: 'Dispatch',
    transaction: 'Transaction',
    amount: 'Amount',
    action: 'Action',
    paymentType: 'Payment Type',
    status: 'Status'
  }
};