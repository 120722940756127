var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [!_vm.isAgent ? _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: _vm.$t("dealersDispatch.unitManager"),
      disabled: _vm.loggedInAdmin && _vm.loggedInAdmin.adminsRoleIds.includes(3) || _vm.isAllSelected
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.adminId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "adminId", $$v);
      },
      expression: "listQuery.adminId"
    }
  }, _vm._l(_vm.unitManagers, function (admin) {
    return _c("el-option", {
      key: admin.id,
      attrs: {
        label: admin.fullName,
        value: admin.id
      }
    });
  }), 1) : _vm._e(), _c("el-select", {
    attrs: {
      filterable: "",
      clearable: "",
      loading: _vm.branchListLoading,
      name: "branchId",
      placeholder: "Select Branch"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.branchId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "branchId", $$v);
      },
      expression: "listQuery.branchId"
    }
  }, _vm._l(_vm.branchList, function (branch) {
    return _c("el-option", {
      key: branch.id,
      attrs: {
        label: branch.code,
        value: branch.id
      }
    });
  }), 1), !_vm.isAgent ? _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      disabled: !_vm.listQuery["branchId"] > 0,
      filterable: "",
      clearable: "",
      placeholder: _vm.$t("dealersDispatch.unit")
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.unitId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "unitId", $$v);
      },
      expression: "listQuery.unitId"
    }
  }, _vm._l(_vm.unitList, function (unit) {
    return _c("el-option", {
      key: unit.id,
      attrs: {
        label: unit.name,
        value: unit.id
      }
    }, [_vm._v(" " + _vm._s(unit.name) + " - " + _vm._s(unit.code) + " ")]);
  }), 1) : _vm._e(), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      clearable: "",
      remote: "",
      loading: _vm.dealerListLoading,
      "loading-text": "fetching dealers..",
      "remote-method": _vm.getDealerList,
      placeholder: _vm.$t("dealersDispatch.dealer")
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.dealerId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "dealerId", $$v);
      },
      expression: "listQuery.dealerId"
    }
  }, _vm._l(_vm.dealerList, function (dealer) {
    return _c("el-option", {
      key: dealer.id,
      attrs: {
        label: dealer.name,
        value: dealer.id
      }
    }, [_c("span", {
      staticStyle: {
        float: "left",
        "font-size": "13px"
      }
    }, [_vm._v(_vm._s(dealer.name) + " - " + _vm._s(dealer.code))]), _c("span", {
      staticStyle: {
        float: "right",
        color: "#8492a6",
        "font-size": "13px"
      }
    }, [_vm._v("   | ID: " + _vm._s(dealer.id))])]);
  }), 1), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      remote: "",
      clearable: "",
      "remote-method": _vm.getProductList,
      loading: _vm.productListLoading,
      placeholder: "Select Product"
    },
    on: {
      change: _vm.handleProductSelection
    },
    model: {
      value: _vm.selectedProduct,
      callback: function ($$v) {
        _vm.selectedProduct = $$v;
      },
      expression: "selectedProduct"
    }
  }, _vm._l(_vm.products, function (product) {
    return _c("el-option-group", {
      key: product.id,
      attrs: {
        label: "Main Product"
      }
    }, [_c("el-option", {
      key: "product-" + product.id,
      attrs: {
        label: product.name,
        value: "product-" + product.id
      }
    }, [_c("span", {
      staticStyle: {
        "font-weight": "bold"
      }
    }, [_vm._v(_vm._s(product.name))])]), product.productType !== "simple" && product.productsVariation && product.productsVariation.length ? [_c("el-option", {
      key: "variations-label-" + product.id,
      attrs: {
        label: "Variations",
        disabled: ""
      }
    }, [_c("span", {
      staticStyle: {
        "font-style": "italic",
        color: "gray"
      }
    }, [_vm._v("Variations:")])]), _vm._l(product.productsVariation, function (item) {
      return _c("el-option", {
        key: item.id,
        attrs: {
          label: product.name + "-" + item.name,
          value: "variation-" + item.id
        }
      }, [_c("span", [_vm._v(_vm._s(item.name))])]);
    })] : _vm._e()], 2);
  }), 1), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      clearable: "",
      placeholder: "Status"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.status,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "status", $$v);
      },
      expression: "listQuery.status"
    }
  }, _vm._l(_vm.statusOptions, function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1), _c("el-input", {
    staticClass: "filter-item",
    attrs: {
      placeholder: "Invoice Number"
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleFilter.apply(null, arguments);
      }
    },
    model: {
      value: _vm.listQuery.entryNumber,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "entryNumber", $$v);
      },
      expression: "listQuery.entryNumber"
    }
  }), _c("el-select", {
    staticClass: "filter-item",
    staticStyle: {
      width: "140px"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.sort,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "sort", $$v);
      },
      expression: "listQuery.sort"
    }
  }, _vm._l(_vm.sortOptions, function (item) {
    return _c("el-option", {
      key: item.key,
      attrs: {
        label: item.label,
        value: item.key
      }
    });
  }), 1), _c("VueCtkDateTimePicker", {
    staticClass: "filter-item",
    attrs: {
      range: "",
      formatted: "DD-MM-YYYY",
      "custom-shortcuts": _vm.shortcuts
    },
    model: {
      value: _vm.dateRange,
      callback: function ($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  }), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-search",
      size: "mini"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")]), _vm.loggedInAdmin.adminsRoleIds.includes(3) ? _c("router-link", {
    staticClass: "margin-horizontal",
    attrs: {
      to: `/orders-dispatches/add`
    }
  }, [_c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "success",
      size: "mini",
      icon: "el-icon-plus"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.add")) + " ")])], 1) : _vm._e(), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      disabled: !_vm.dateRange,
      type: "primary",
      size: "mini",
      icon: "el-icon-document"
    },
    on: {
      click: _vm.downloadFax
    }
  }, [_vm._v(" Generate Fax ")])], 1), _vm.isAllSelected ? _c("div", [_c("el-tag", {
    attrs: {
      type: "success"
    }
  }, [_vm._v(" " + _vm._s("ALL UNITS SELECTED") + " ")])], 1) : _vm._e(), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "summary-method": _vm.getSummaries,
      "show-summary": "",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      sortable: "custom",
      width: "80",
      align: "center",
      label: "ID",
      prop: "id",
      "class-name": _vm.getSortClass("id")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.id) + " "), _c("br")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "80",
      align: "center",
      prop: "entryNumber",
      label: "Lot No"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "120",
      align: "center",
      prop: "invoiceNumber",
      label: "Invoice No"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "200px",
      prop: "dealer",
      label: "Dealer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return scope.row.dealer ? [scope.row.dealer.name ? _c("b", [_c("i", {
          staticClass: "el-icon-suitcase"
        }), _vm._v(" " + _vm._s(scope.row.dealer.name) + " "), _c("br")]) : _vm._e(), scope.row.dealer.city ? _c("i", {
          staticClass: "el-icon-place"
        }, [_vm._v(" " + _vm._s(scope.row.dealer.city) + " "), _c("br")]) : _vm._e(), scope.row.dealer.code ? _c("span", [_vm._v(" (" + _vm._s(scope.row.dealer.code) + ") "), _c("br")]) : _vm._e(), _c("el-tag", {
          attrs: {
            size: "mini",
            type: _vm.statusColorMap[scope.row.status]
          }
        }, [_vm._v(" " + _vm._s(scope.row.status) + " ")])] : undefined;
      }
    }], null, true)
  }), _c("el-table-column", {
    attrs: {
      width: "180",
      prop: "unit",
      label: "Unit / Transporter"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return scope.row.unit ? [_c("i", {
          staticClass: "el-icon-user"
        }), _vm._v(" " + _vm._s(scope.row.admin.fullName) + " "), _c("br"), _c("i", {
          staticClass: "el-icon-school"
        }), _vm._v(" " + _vm._s(scope.row.unit.name) + " (" + _vm._s(scope.row.unit.code) + ") "), _c("br"), _c("i", {
          staticClass: "el-icon-truck"
        }), _vm._v(" " + _vm._s(scope.row.dealersTransporter.transporter.name) + " ")] : undefined;
      }
    }], null, true)
  }), _c("el-table-column", {
    attrs: {
      width: "100",
      align: "center",
      prop: "quantity",
      label: "Total Qty"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "140",
      align: "left",
      label: "Grand Total",
      prop: "grandTotal"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.grandTotal
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "180",
      label: "Eway / E-invoice"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.ewayBillNo ? _c("div", [_vm._v(" Eway Bill No: "), _c("br"), _vm._v(" " + _vm._s(scope.row.ewayBillNo) + " "), scope.row.ewayBillUrl ? _c("el-link", {
          attrs: {
            type: "primary",
            icon: "el-icon-link",
            target: "_blank",
            href: _vm.getFormatedURL(scope.row.ewayBillUrl)
          }
        }) : _vm._e(), scope.row.ewayCancelDate ? _c("el-popover", {
          attrs: {
            placement: "top-start",
            title: "Eway Cancelled Date",
            width: "200",
            trigger: "hover",
            content: _vm._f("moment")(scope.row.ewayCancelDate, "DD-MM-YYYY hh:mm a")
          }
        }, [_c("el-tag", {
          attrs: {
            slot: "reference",
            size: "mini",
            type: "danger"
          },
          slot: "reference"
        }, [_vm._v(" Cancelled ")])], 1) : _vm._e(), _c("hr", {
          staticStyle: {
            color: "#ccc"
          }
        })], 1) : _vm._e(), scope.row.irn ? _c("div", [_vm._v(" E-Invoice ACK No: "), _c("br"), _vm._v(" " + _vm._s(scope.row.ackNumber) + " "), _c("el-link", {
          attrs: {
            type: "primary",
            icon: "el-icon-link",
            target: "_blank",
            href: scope.row.eInvoiceUrl
          }
        }), scope.row.eInvoiceCancelDate ? _c("el-popover", {
          attrs: {
            placement: "top-start",
            title: "E-Invoice Cancelled Date",
            width: "200",
            trigger: "hover",
            content: _vm._f("moment")(scope.row.eInvoiceCancelDate, "DD-MM-YYYY hh:mm a")
          }
        }, [_c("el-tag", {
          attrs: {
            slot: "reference",
            size: "mini",
            type: "danger"
          },
          slot: "reference"
        }, [_vm._v(" Cancelled ")])], 1) : _vm._e()], 1) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: _vm.$t("table.createdDate"),
      width: "180",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm._f("moment")(scope.row.createdTimestamp, "DD-MM-YYYY hh:mm a")) + " ")];
      }
    }])
  }), !_vm.isAgent ? _c("el-table-column", {
    attrs: {
      align: "center",
      label: "Actions",
      width: "300"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: "Info",
            placement: "top"
          }
        }, [_c("router-link", {
          attrs: {
            to: "/orders-dispatches/info/" + scope.row.id
          }
        }, [!_vm.isReadOnly ? _c("el-button", {
          attrs: {
            type: "warning",
            size: "mini",
            icon: "el-icon-info"
          }
        }) : _vm._e()], 1)], 1), _vm._v("   "), scope.row.status === "Approved" ? _c("el-dropdown", {
          attrs: {
            size: "mini",
            "split-button": "",
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.downloadInvoice(scope.row.id);
            }
          }
        }, [scope.row.status === "Approved" ? _c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: "Invoice",
            placement: "top"
          }
        }, [_c("i", {
          staticClass: "el-icon-document"
        })]) : _vm._e(), !_vm.isReadOnly && _vm.isExcludedForUnitRole() ? _c("el-dropdown-menu", {
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [_c("el-dropdown-item", [_c("el-link", {
          attrs: {
            type: "primary",
            disabled: scope.row.ewayBillNo && !scope.row.ewayCancelDate
          },
          on: {
            click: function ($event) {
              return _vm.createEWay(scope.row.id);
            }
          }
        }, [_vm._v(" Create E-way ")])], 1), _c("el-dropdown-item", [_c("el-link", {
          attrs: {
            type: "primary",
            disabled: _vm.showUpdateEwayDialogCondition(scope.row)
          },
          on: {
            click: function ($event) {
              return _vm.goTo("/orders-dispatches/update-eway/" + scope.row.id);
            }
          }
        }, [_vm._v(" Update E-way ")])], 1), _c("el-dropdown-item", [_c("el-link", {
          attrs: {
            type: "primary",
            disabled: !scope.row.ewayBillNo || scope.row.ewayCancelDate
          },
          on: {
            click: function ($event) {
              return _vm.cancelEWay(scope.row.id);
            }
          }
        }, [_vm._v(" Cancel E-way ")])], 1), _c("el-dropdown-item", [_c("el-link", {
          attrs: {
            type: "primary",
            disabled: scope.row.irn ? true : false
          },
          on: {
            click: function ($event) {
              return _vm.createEInvoice(scope.row.id);
            }
          }
        }, [_vm._v(" Create E-invoice ")])], 1), _c("el-dropdown-item", [_c("el-link", {
          attrs: {
            type: "primary",
            disabled: !scope.row.irn
          },
          on: {
            click: function ($event) {
              return _vm.cancelEInvoice(scope.row.id);
            }
          }
        }, [_vm._v(" Cancel E-invoice ")])], 1), _c("el-dropdown-item", [_c("el-link", {
          attrs: {
            type: "primary",
            disabled: !scope.row.ewayBillNo
          },
          on: {
            click: function ($event) {
              return _vm.goTo("/orders-dispatches/upload-eway-pdf/" + scope.row.id);
            }
          }
        }, [_vm._v(" Upload E-way PDF ")])], 1), _c("el-dropdown-item", [_c("el-link", {
          attrs: {
            type: "primary",
            disabled: !scope.row.irn
          },
          on: {
            click: function ($event) {
              return _vm.goTo("/orders-dispatches/lr-detail/" + scope.row.id);
            }
          }
        }, [_vm._v(" LR Detail ")])], 1), _c("el-dropdown-item", [_c("el-link", {
          attrs: {
            type: "primary",
            disabled: !scope.row.ewayBillNo
          },
          on: {
            click: function ($event) {
              return _vm.downloadMergedPdf(scope.row.id);
            }
          }
        }, [_vm._v(" Download Merged PDF ")])], 1), _c("el-dropdown-item", {
          directives: [{
            name: "permission",
            rawName: "v-permission",
            value: [_vm.SUPER_ADMIN],
            expression: "[SUPER_ADMIN]"
          }]
        }, [_c("el-link", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.regenerateInvoiceToS3(scope.row.id);
            }
          }
        }, [_vm._v(" Regenerate Invoice ")])], 1)], 1) : _vm._e()], 1) : _vm._e()];
      }
    }], null, false, 1217441270)
  }) : _vm._e()], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  }), _c("el-dialog", {
    attrs: {
      title: "Order Dispatch Details",
      width: "90%",
      visible: _vm.dialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_vm._v(" Products dispatched "), _c("dispatch-list", {
    key: _vm.dispatchId,
    attrs: {
      "orders-dispatch-id": _vm.dispatchId
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };