"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block inventory-inward-form"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Entry Date",
      prop: "entryDate"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "date",
      placeholder: "Select Date",
      format: "dd-MM-yyyy",
      "picker-options": {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    },
    model: {
      value: _vm.formData.entryDate,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "entryDate", $$v);
      },
      expression: "formData.entryDate"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Unit Manager",
      prop: "adminId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      placeholder: "Select Unit Manager",
      disabled: _vm.isEdit || _vm.loggedInAdmin.adminsRoleIds.includes(3)
    },
    on: {
      change: _vm.fetchUnitList
    },
    model: {
      value: _vm.formData.adminId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "adminId", $$v);
      },
      expression: "formData.adminId"
    }
  }, _vm._l(_vm.unitManagers, function (admin) {
    return _c("el-option", {
      key: admin.id,
      attrs: {
        label: admin.fullName,
        value: admin.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Unit",
      prop: "unitId"
    }
  }, [_c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: "Select Unit",
      disabled: _vm.isEdit
    },
    on: {
      change: _vm.resetTable
    },
    model: {
      value: _vm.formData.unitId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "unitId", $$v);
      },
      expression: "formData.unitId"
    }
  }, _vm._l(_vm.unitList, function (unit) {
    return _c("el-option", {
      key: unit.id,
      attrs: {
        label: unit.name,
        value: unit.id
      }
    }, [_vm._v(" " + _vm._s(unit.name) + " - " + _vm._s(unit.code) + " ")]);
  }), 1)], 1)], 1)], 1), _vm.formData.unitId ? _c("el-table", {
    staticStyle: {
      width: "100%",
      "margin-top": "20px"
    },
    attrs: {
      data: _vm.inventoryInwardsLogs,
      border: "",
      "span-method": _vm.doProductRowSpan
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Product",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-select", {
          attrs: {
            filterable: "",
            name: "productId",
            placeholder: "Search Product",
            disabled: _vm.isEdit || scope.row.productId,
            remote: "",
            "reserve-keyword": "",
            "remote-method": _vm.fetchProductList
          },
          on: {
            change: function ($event) {
              return _vm.productSelected(scope.row, scope.$index);
            }
          },
          model: {
            value: scope.row.productId,
            callback: function ($$v) {
              _vm.$set(scope.row, "productId", $$v);
            },
            expression: "scope.row.productId"
          }
        }, _vm._l(_vm.productList, function (product) {
          return _c("el-option", {
            key: product.id,
            attrs: {
              label: product.name,
              value: product.id
            }
          }, [_vm._v(" " + _vm._s(product.catalogueCode) + " - " + _vm._s(product.name) + " ")]);
        }), 1)];
      }
    }], null, false, 2834833867)
  }), _c("el-table-column", {
    attrs: {
      label: "Color",
      align: "center",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return scope.row.productsVariation ? [_vm._v(" " + _vm._s(scope.row.productsVariation.name) + " ")] : undefined;
      }
    }], null, true)
  }), _c("el-table-column", {
    attrs: {
      label: "Current Stock",
      align: "center",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input-number", {
          attrs: {
            min: 0,
            size: "mini",
            disabled: true,
            controls: false,
            placeholder: "Current Stock"
          },
          model: {
            value: scope.row.currentStock,
            callback: function ($$v) {
              _vm.$set(scope.row, "currentStock", $$v);
            },
            expression: "scope.row.currentStock"
          }
        })];
      }
    }], null, false, 3312560189)
  }), _c("el-table-column", {
    attrs: {
      label: "Loose Quantity",
      align: "center",
      width: "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input-number", {
          attrs: {
            size: "mini",
            disabled: _vm.isEdit,
            min: 0,
            step: 1,
            "controls-position": "right",
            placeholder: "Loose Quantity"
          },
          on: {
            change: function ($event) {
              return _vm.quantityChanged(scope.row);
            }
          },
          model: {
            value: scope.row.looseQuantity,
            callback: function ($$v) {
              _vm.$set(scope.row, "looseQuantity", $$v);
            },
            expression: "scope.row.looseQuantity"
          }
        })];
      }
    }], null, false, 1247443757)
  }), _c("el-table-column", {
    attrs: {
      label: "Carton Quantity",
      align: "center",
      width: "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input-number", {
          attrs: {
            size: "mini",
            disabled: _vm.isEdit,
            min: 0,
            step: 1,
            "controls-position": "right",
            placeholder: "Carton Quantity"
          },
          on: {
            change: function ($event) {
              return _vm.quantityChanged(scope.row);
            }
          },
          model: {
            value: scope.row.cartonQuantity,
            callback: function ($$v) {
              _vm.$set(scope.row, "cartonQuantity", $$v);
            },
            expression: "scope.row.cartonQuantity"
          }
        })];
      }
    }], null, false, 3042786381)
  }), _c("el-table-column", {
    attrs: {
      label: "Quantity",
      align: "center",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input-number", {
          attrs: {
            size: "mini",
            min: 0,
            controls: false,
            disabled: true,
            placeholder: "Quantity"
          },
          model: {
            value: scope.row.quantity,
            callback: function ($$v) {
              _vm.$set(scope.row, "quantity", $$v);
            },
            expression: "scope.row.quantity"
          }
        })];
      }
    }], null, false, 556578653)
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-button", {
          directives: [{
            name: "waves",
            rawName: "v-waves"
          }],
          attrs: {
            size: "mini",
            type: "danger",
            icon: "el-icon-delete",
            disabled: _vm.isEdit
          },
          on: {
            click: function ($event) {
              return _vm.deleteProduct(scope.$index, scope.row);
            }
          }
        })];
      }
    }], null, false, 1279528706)
  }, [_c("template", {
    slot: "header"
  }, [_c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      size: "mini",
      type: "primary",
      icon: "el-icon-plus",
      disabled: _vm.isEdit || !_vm.formData.unitId || !_vm.formData.adminId
    },
    on: {
      click: _vm.addProduct
    }
  })], 1)], 2)], 1) : _vm._e(), _c("el-divider"), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;