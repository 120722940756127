"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _admin = require("@/store/modules/admin");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.otpForm = {
      otp: "",
      mobileNumber: "",
      hash: ""
    };
    this.otpRules = {
      otp: [{
        required: true,
        message: "Please enter OTP",
        trigger: "blur"
      }]
    };
    this.loading = false;
  }
  verifyOtp() {
    this.$refs.otpForm.validate(async valid => {
      if (valid) {
        this.loading = true;
        try {
          // Verify the OTP by calling the verifyOtp method in AdminModule
          await _admin.AdminModule.verifyOtp({
            mobileNumber: this.$route.query.mobileNumber.toString(),
            hash: this.$route.query.hash.toString(),
            otp: this.otpForm.otp
          });
          // Navigate to the dashboard after successful OTP verification
          this.$router.push({
            path: "/dashboard"
          });
        } catch (error) {
          this.loading = false;
        }
      } else {
        return false;
      }
    });
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "VerifyOtp"
})], default_1);
var _default = exports.default = default_1;