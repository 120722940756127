"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Enabled",
      prop: "enabled"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.formData.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "enabled", $$v);
      },
      expression: "formData.enabled"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Product Type",
      prop: "productType"
    }
  }, [_c("el-select", {
    attrs: {
      disabled: _vm.isEdit,
      placeholder: "Select",
      name: "productType",
      required: ""
    },
    on: {
      change: _vm.emitFormData
    },
    model: {
      value: _vm.formData.productType,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "productType", $$v);
      },
      expression: "formData.productType"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Simple",
      value: "simple"
    }
  }), _c("el-option", {
    attrs: {
      label: "Variable",
      value: "variable"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Name",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      name: "name",
      required: "",
      placeholder: "Name",
      maxlength: "50",
      minlength: "3",
      "show-word-limit": ""
    },
    model: {
      value: _vm.formData.name,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1), _vm.formData.productType === "simple" && _vm.formData.productsVariation.length ? [_c("el-form-item", {
    attrs: {
      label: "Price"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("div", {
    staticClass: "sub-title"
  }, [_vm._v(" MRP ")]), _c("el-input-number", {
    attrs: {
      name: "mrp",
      size: "mini",
      min: 0,
      precision: 2
    },
    model: {
      value: _vm.formData.productsVariation[0].mrp,
      callback: function ($$v) {
        _vm.$set(_vm.formData.productsVariation[0], "mrp", $$v);
      },
      expression: "formData.productsVariation[0].mrp"
    }
  }), _c("help-text", {
    attrs: {
      content: ""
    }
  })], 1)], 1)], 1)] : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "Featured",
      prop: "featured"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.formData.featured,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "featured", $$v);
      },
      expression: "formData.featured"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Scheme Category",
      prop: "schemesCategoryId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Scheme Category",
      clearable: ""
    },
    on: {
      clear: function ($event) {
        _vm.formData.schemesCategoryId = null;
      }
    },
    model: {
      value: _vm.formData.schemesCategoryId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "schemesCategoryId", $$v);
      },
      expression: "formData.schemesCategoryId"
    }
  }, _vm._l(_vm.schemesCategories, function (scheme) {
    return _c("el-option-group", {
      key: scheme.id,
      attrs: {
        label: scheme.name
      }
    }, _vm._l(scheme.schemesCategories, function (schemesCategory) {
      return _c("el-option", {
        key: schemesCategory.id,
        attrs: {
          label: schemesCategory.name,
          value: schemesCategory.id
        }
      });
    }), 1);
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Range",
      prop: "rangeId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Range"
    },
    model: {
      value: _vm.formData.rangeId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "rangeId", $$v);
      },
      expression: "formData.rangeId"
    }
  }, _vm._l(_vm.ranges, function (range) {
    return _c("el-option", {
      key: range.id,
      attrs: {
        label: range.name,
        value: range.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Pieces Per Box",
      prop: "piecesPerBox"
    }
  }, [_c("el-input-number", {
    attrs: {
      size: "mini",
      min: 1,
      step: 1
    },
    model: {
      value: _vm.formData.piecesPerBox,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "piecesPerBox", $$v);
      },
      expression: "formData.piecesPerBox"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Master Carton",
      prop: "masterCarton"
    }
  }, [_c("el-input-number", {
    attrs: {
      size: "mini",
      min: 1,
      step: 1
    },
    model: {
      value: _vm.formData.masterCarton,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "masterCarton", $$v);
      },
      expression: "formData.masterCarton"
    }
  }), _c("help-text", {
    attrs: {
      content: ""
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Taxable",
      prop: "isTaxable"
    }
  }, [_c("el-switch", {
    attrs: {
      "inactive-text": "Tax inclusive"
    },
    model: {
      value: _vm.formData.isTaxable,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "isTaxable", $$v);
      },
      expression: "formData.isTaxable"
    }
  })], 1), _vm.formData.isTaxable ? _c("el-form-item", {
    attrs: {
      label: "Tax",
      prop: "taxId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select",
      name: "taxId",
      required: ""
    },
    model: {
      value: _vm.formData.taxId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "taxId", $$v);
      },
      expression: "formData.taxId"
    }
  }, _vm._l(_vm.taxes, function (tax) {
    return _c("el-option", {
      key: tax.id,
      attrs: {
        label: tax.name,
        value: tax.id
      }
    });
  }), 1)], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "Hsn Code",
      prop: "hsnCode"
    }
  }, [_c("el-input", {
    attrs: {
      name: "hsnCode"
    },
    model: {
      value: _vm.formData.hsnCode,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "hsnCode", $$v);
      },
      expression: "formData.hsnCode"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Catalogue Code",
      prop: "catalogueCode"
    }
  }, [_c("el-input", {
    attrs: {
      name: "catalogueCode"
    },
    model: {
      value: _vm.formData.catalogueCode,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "catalogueCode", $$v);
      },
      expression: "formData.catalogueCode"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      loading: _vm.loading,
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")])], 1)], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;