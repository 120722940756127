import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import { getDealersAddresses } from "@/api/dealers-addresses";
import Pagination from "@/components/Pagination/index.vue";
import { getQuery } from "@/utils/index";
import Enabled from "@/components/Enabled/index.vue";
import { AdminModule } from "@/store/modules/admin";
import { isExcludedToAccess } from "@/common";
let default_1 = class extends Vue {
  constructor() {
    super(...arguments);
    this.isReadOnly = false;
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.dealerId = 0;
    this.listQuery = {
      page: 1,
      limit: 10,
      name: undefined,
      dealerId: 0,
      enabled: true,
      sort: "id,ASC",
      filter: {
        name: "$startsL",
        enabled: "$eq",
        dealerId: "$eq"
      }
    };
    this.sortOptions = [{
      label: "ID Ascending",
      key: "id,ASC"
    }, {
      label: "ID Descending",
      key: "id,DESC"
    }];
    this.enableTypeOptions = [{
      key: true,
      value: "Enable"
    }, {
      key: false,
      value: "Disabled"
    }];
  }
  activated() {
    if (AdminModule.roles.includes("Read Only")) {
      this.isReadOnly = true;
    }
    this.dealerId = parseInt(this.$route.params && this.$route.params.dealerId);
    this.listQuery = {
      ...this.listQuery,
      ...this.$route.query
    };
    this.getList();
  }
  hasNoAccess(requiredRoles) {
    return isExcludedToAccess(requiredRoles);
  }
  async getList() {
    try {
      this.listLoading = true;
      this.listQuery.dealerId = this.dealerId;
      const data = await getDealersAddresses(getQuery(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    this.handleFilter();
  }
  getAddress(address) {
    let fullAaddress = "";
    if (address.line1) {
      fullAaddress += "Line 1: " + address.line1 + "<br />";
    }
    if (address.line2) {
      fullAaddress += "Line 2: " + address.line2 + "<br />";
    }
    if (address.city) {
      fullAaddress += "City: " + address.cityName + "<br />";
    }
    if (address.state) {
      fullAaddress += "State: " + address.state.name + "<br />";
    }
    if (address.pincode) {
      fullAaddress += "Pincode: " + address.pincode.pincode + "<br />";
    }
    if (address.gstNumber) {
      fullAaddress += "GST: " + address.gstNumber + "<br />";
    }
    if (address.panNumber) {
      fullAaddress += "PAN: " + address.panNumber + "<br />";
    }
    return fullAaddress;
  }
};
default_1 = __decorate([Component({
  name: "DealersAddressList",
  components: {
    Pagination,
    Enabled
  }
})], default_1);
export default default_1;