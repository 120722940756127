"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'people': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M104.2 95.3A38.6 38.6 0 01117 128h-10.7c.2-1.5.4-3 .4-4.5 0-9-4.4-17-11.5-23.2a73.5 73.5 0 01-62.4 0 30.7 30.7 0 00-11.5 23.2c0 1.6.2 3 .4 4.5H11a35.4 35.4 0 01-.3-4.5c0-10.8 5-20.7 13.1-28.3A50.7 50.7 0 010 53.6C0 24 28.7 0 64 0s64 24 64 53.6c0 16.8-9.3 31.8-23.8 41.7zM64 36.9c-29.5 0-53.3-10.1-53.3 15.3s23.8 46 53.3 46c29.5 0 53.3-20.6 53.3-46S93.5 37 64 37zm24.9 25.6c-4 0-7.1-2.7-7.1-6 0-3.2 3.2-5.9 7-5.9 4 0 7.2 2.7 7.2 6s-3.2 6-7.1 6zM85.3 79c0 4-9.5 7.4-21.3 7.4S42.7 83 42.7 79c0-1 .6-2 1.8-3 3.3 2.6 10.8 4.5 19.5 4.5s16.2-1.9 19.5-4.5c1.2 1 1.8 2 1.8 3zM39.1 62.5c-4 0-7.1-2.7-7.1-6 0-3.2 3.2-5.9 7.1-5.9 4 0 7.1 2.7 7.1 6s-3.2 6-7 6z"/>'
  }
});