"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'table': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M0 0h128v31.2H0V0zm0 38h38.4v41.6H0V38zm0 48.5h38.4v41.4H0V86.5zM44.8 38h38.4v41.5H44.8V38zm0 48.4h38.4v41.4H44.8V86.5zM89.6 38H128v41.5H89.6zm0 48.4H128v41.4H89.6z"/><path pid="1" d="M0 0h128v31.2H0V0zm0 38h38.4v41.6H0V38zm0 48.5h38.4v41.4H0V86.5zM44.8 38h38.4v41.5H44.8V38zm0 48.4h38.4v41.4H44.8V86.5zM89.6 38H128v41.5H89.6zm0 48.4H128v41.4H89.6z"/>'
  }
});