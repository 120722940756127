"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _order = require("@/api/order");
var _dealers = require("@/api/dealers");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _index4 = _interopRequireDefault(require("@/components/Inr/index.vue"));
var _admin = require("@/store/modules/admin");
var _router = _interopRequireDefault(require("@/router"));
var _OrderProductTab = _interopRequireDefault(require("./components/OrderProductTab.vue"));
var _dealersUsers = require("@/api/dealers-users");
var _states = require("@/api/locations/states");
var _cities = require("@/api/locations/cities");
var _products = require("@/api/products");
var _common = require("@/common");
var _branchAgentService = require("@/services/branch-agent-service");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.dealerListLoading = false;
    this.agentListLoading = false;
    this.stateListLoading = false;
    this.branchListLoading = false;
    this.branchList = [];
    this.cityListLoading = false;
    this.isAgent = false;
    this.isReadOnly = false;
    this.isInvoiceManager = false;
    this.isSalesManager = false;
    this.downloadLoading = false;
    this.dateRange = "";
    this.startDate = "";
    this.endDate = "";
    this.dialogFormVisible = false;
    this.dealerList = [];
    this.dealersUserList = [];
    this.agentList = [];
    this.stateList = [];
    this.cityList = [];
    this.orderExists = false;
    this.orderId = null;
    this.orderInfoData = null;
    this.dialogVisible = false;
    this.holdInfo = null;
    this.dealerId = null;
    this.dealersUserId = null;
    this.loggedInAdmin = null;
    this.agentId = null;
    this.productListLoading = false;
    this.products = [];
    this.selectedProduct = null;
    this.listQuery = {
      page: 1,
      limit: 10,
      orderReference: undefined,
      dealerId: undefined,
      agentId: undefined,
      "billingAddress.cityId": undefined,
      "billingAddress.stateId": undefined,
      "ordersProductsDispatch.ordersProduct.productsVariationId": undefined,
      "ordersProductsDispatch.ordersProduct.productId": undefined,
      sort: "orderTimestamp,DESC",
      status: "Order Confirmed",
      orderTimestamp: undefined,
      branchId: undefined,
      join: "ordersProduct.ordersProductsDiscount",
      filter: {
        orderReference: "$startsL",
        status: "$eq",
        dealerId: "$eq",
        agentId: "$eq",
        branchId: "$in",
        orderTimestamp: "between",
        "billingAddress.cityId": "$eq",
        "billingAddress.stateId": "$eq",
        "ordersProductsDispatch.ordersProduct.productsVariationId": "$eq",
        "ordersProductsDispatch.ordersProduct.productId": "$eq"
      }
    };
    this.sortOptions = [{
      label: "ID Ascending",
      key: "orderTimestamp,ASC"
    }, {
      label: "ID Descending",
      key: "orderTimestamp,DESC"
    }];
    this.enableTypeOptions = [{
      key: true,
      value: "Enable"
    }, {
      key: false,
      value: "Disabled"
    }];
    this.totalColumn = {
      total: "Total",
      taxedAmount: "Taxed Amount",
      // freightCharges: 'Freight Charges',
      grandTotal: "Grand Total",
      amountReceived: "Amount Received"
    };
    this.statusColorMap = {
      "Order Placed": "primary",
      "Order Confirmed": "primary",
      "Partial Payment": "warning",
      "RTGS Payment": "warning",
      "Order Delivered": "success",
      "Order Cancelled": "danger",
      "Order Partial Cancelled": "danger",
      "Order Dispatched": "info",
      "Partial Dispatch": "info",
      "Delivery Confirmed": "success",
      "Not Delivered": "danger"
    };
    this.statusOptions = [
    // { label: "Order Placed", key: "Order Placed" },
    {
      label: "Order Confirmed",
      key: "Order Confirmed"
    },
    // { label: "Partial Payment", key: "Partial Payment" },
    // { label: "RTGS Payment", key: "RTGS Payment" },
    {
      label: "Order Dispatched",
      key: "Order Dispatched"
    }, {
      label: "Partial Dispatch",
      key: "Partial Dispatch"
    }, {
      label: "Order Delivered",
      key: "Order Delivered"
    },
    // { label: "Delivery Confirmed", key: "Delivery Confirmed" },
    {
      label: "Not Delivered",
      key: "Not Delivered"
    }, {
      label: "Order Cancelled",
      key: "Order Cancelled"
    }, {
      label: "Order Partial Cancelled",
      key: "Order Partial Cancelled"
    }];
    this.reviseOrderFlag = false;
    this.selectedOrderIds = [];
  }
  _isSuperAdmin() {
    return (0, _common.isSuperAdmin)();
  }
  async activated() {
    try {
      this.loggedInAdmin = _admin.AdminModule;
      if ((0, _common.isReadOnly)()) {
        this.isReadOnly = true;
      }
      if ((0, _common.isInvoiceManager)()) {
        this.isInvoiceManager = true;
      }
      if (_admin.AdminModule.roles.includes("Sales Manager")) {
        this.isSalesManager = true;
      }
      // Assign branchId using BranchAgentService
      this.listQuery.branchId = await _branchAgentService.BranchAgentService.assignBranchId();
      // Check if branchId is null and throw an error
      if (this.listQuery.branchId === null) {
        throw new Error("You are not assigned to a branch.");
      }
      // Fetch agents for the assigned branchId
      this.agentList = await _branchAgentService.BranchAgentService.fetchAgents(this.listQuery.branchId);
      // Fetch branches for non-super admin users
      this.branchList = await _branchAgentService.BranchAgentService.fetchBranches(this.listQuery.branchId);
      // check if logged in user is Agent/Admin
      if (_admin.AdminModule.userType === "Agent") {
        this.isAgent = true;
        this.listQuery.agentId = _admin.AdminModule.agentId;
      }
      if (this.$route.query.status) {
        this.listQuery.status = this.$route.query.status;
      }
      if (this.$route.query.startDate && this.$route.query.endDate) {
        this.dateRange = {
          start: this.$route.query.startDate,
          end: this.$route.query.endDate
        };
      }
      this.listQuery = {
        ...this.listQuery,
        ...this.$route.query
      };
      // fetch states
      await this.getStateList();
      await this.getList();
    } catch (err) {
      if (err instanceof Error) {
        this.$notify.error({
          title: "Error",
          message: err.message
        });
      }
      this.listLoading = false;
    }
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _order.getOrders)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getDealerList(query = null) {
    try {
      const isNumeric = /^[0-9]+$/.test(query);
      // search only if length is greater than 1 if query is id else 2
      if (!query || (isNumeric ? query.length < 1 : query.length < 2)) {
        this.dealerList = [];
        return;
      }
      const searchParams = {
        keyword: query,
        searchBy: isNumeric ? "dealerId" : "name",
        branchId: this.listQuery.branchId !== undefined ? this.listQuery.branchId : _admin.AdminModule.branchIds
      };
      this.dealerList = await (0, _dealers.searchDealers)(searchParams);
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async getProductList(query) {
    try {
      this.productListLoading = true;
      if (query && query.length > 2) {
        this.products = await (0, _products.searchProducts)((0, _index2.getQuery)({
          keyword: query
        }));
      }
      this.productListLoading = false;
    } catch (err) {
      this.productListLoading = false;
      throw err;
    }
  }
  async validateDealer() {
    try {
      let message = null;
      const dealer = await (0, _dealers.getDealerById)(this.dealerId);
      // set agent ID
      this.agentId = dealer.agentId;
      if (dealer.status !== "Approved") {
        message = "Dealer not approved";
      } else if (+dealer.creditLimitUsed >= +dealer.creditLimit) {
        message = "Dealer has reached maximum credit limit";
      }
      if (message) {
        this.$message({
          type: "warning",
          message,
          showClose: true
        });
        this.dealerId = null;
        return false;
      } else {
        this.fetchDealersUser();
      }
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async fetchDealersUser() {
    try {
      this.dealersUserList = [];
      if (this.dealerId) {
        this.dealersUserList = await (0, _dealersUsers.getDealersUsers)((0, _index2.getQuery)({
          dealerId: this.dealerId,
          filter: {
            dealerId: "$eq"
          }
        }));
        if (this.dealersUserList.length === 1) {
          this.dealersUserId = this.dealersUserList[0].id;
        }
      }
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async getStateList(countryId = 1) {
    try {
      this.stateListLoading = true;
      this.stateList = await (0, _states.getStates)((0, _index2.getQuery)({
        enabled: true,
        countryId,
        sort: "name,ASC",
        filter: {
          enabled: "$eq",
          countryId: "$eq"
        }
      }));
      this.stateListLoading = false;
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async getCityList(stateId = 0) {
    try {
      this.cityListLoading = true;
      this.cityList = await (0, _cities.getCities)((0, _index2.getQuery)({
        enabled: true,
        stateId,
        sort: "name,ASC",
        filter: {
          enabled: "$eq",
          stateId: "$eq"
        }
      }));
      this.cityListLoading = false;
    } catch (error) {} // eslint-disable-line  no-empty
  }
  async loadProductComponent(order) {
    this.dialogVisible = true;
    this.orderInfoData = order;
  }
  handleProductSelection(value) {
    if (value) {
      if (value.startsWith("product-")) {
        this.listQuery["ordersProductsDispatch.ordersProduct.productId"] = +value.replace("product-", "");
        this.listQuery["ordersProductsDispatch.ordersProduct.productsVariationId"] = undefined;
      } else if (value.startsWith("variation-")) {
        this.listQuery["ordersProductsDispatch.ordersProduct.productId"] = undefined;
        this.listQuery["ordersProductsDispatch.ordersProduct.productsVariationId"] = +value.replace("variation-", "");
      }
    } else {
      // Clear the product filters when value is null or empty
      this.listQuery["ordersProductsDispatch.ordersProduct.productId"] = undefined;
      this.listQuery["ordersProductsDispatch.ordersProduct.productsVariationId"] = undefined;
    }
    this.handleFilter();
  }
  async handleFilter() {
    var _AdminModule$branchId;
    if (this.dateRange) {
      this.startDate = this.dateRange.start;
      this.endDate = this.dateRange.end;
      this.listQuery.orderTimestamp = [this.startDate, this.endDate].join(",");
    } else {
      this.listQuery.orderTimestamp = "";
    }
    // fetch city based on state selected
    if (this.listQuery["billingAddress.stateId"]) {
      this.getCityList(this.listQuery["billingAddress.stateId"]);
    } else {
      // empty city
      this.cityList = [];
      // reset city filter
      this.listQuery["billingAddress.cityId"] = undefined;
    }
    this.listQuery.page = 1;
    // handle branch and agent code
    // Fetch agents for a single branch
    if ((!(0, _common.isSuperAdmin)() || !(0, _common.isAdmin)()) && ((_AdminModule$branchId = _admin.AdminModule.branchIds) === null || _AdminModule$branchId === void 0 ? void 0 : _AdminModule$branchId.length) === 1) {
      this.listQuery.branchId = _admin.AdminModule.branchIds[0];
    }
    if (this.listQuery.branchId) {
      // Fetch agents and branches for non-superadmin/admin users
      this.agentList = await _branchAgentService.BranchAgentService.fetchAgents(this.listQuery.branchId);
      await _branchAgentService.BranchAgentService.fetchBranches(this.listQuery.branchId);
      // Fetch the list after agents and branches are loaded
      this.getList();
    } else {
      this.getList();
    }
  }
  sortChange(data) {
    this.handleFilter();
  }
  async dateval() {
    this.getList();
  }
  async fetchHoldInfo(dealerId = 0) {
    const data = await (0, _dealers.getDealerHoldInfo)(dealerId);
    if (data) {
      this.holdInfo = "Held By: " + data.holdByAdmin.fullName + "<br />Held Date: " + this.$moment(data.holdTimestamp, "YYYY-MM-DD").format("DD-MM-YYYY") + "<br /> Reason: " + data.holdReason;
    }
  }
  async searchUsers(query = "") {
    try {
      this.orderExists = false;
      const isNumeric = /^[0-9]+$/.test(query);
      // search only if length is greater than 1 if query is id else 2
      if (!query || (isNumeric ? query.length < 1 : query.length < 2)) {
        this.dealerList = [];
        return;
      }
      this.dealerList = await (0, _dealers.searchDealers)({
        keyword: query,
        searchBy: isNumeric ? "dealerId" : "name"
      });
    } catch (error) {
      this.$notify({
        title: "Error",
        type: "error",
        message: error.toString()
      });
    }
  }
  async checkInCartOrder(dealerId = 0, dealersUserId = 0) {
    if (dealerId) {
      this.orderId = null;
      const inCartOrder = await (0, _order.getOrders)((0, _index2.getQuery)({
        dealerId,
        dealersUserId,
        sort: "id,DESC",
        status: "in cart",
        limit: 1,
        filter: {
          dealerId: "$eq",
          dealersUserId: "$eq",
          status: "$eq"
        }
      }));
      if (inCartOrder.data.length) {
        this.orderId = inCartOrder.data[0].id;
        this.orderExists = true;
      } else {
        // create new order
        const order = await (0, _order.createOrder)({
          dealerId,
          dealersUserId: this.dealersUserId,
          agentId: this.listQuery.agentId || this.agentId
        });
        this.orderId = order.id;
        this.createOrder();
      }
    } else {
      this.$message({
        type: "warning",
        message: "Please select user"
      });
    }
  }
  async createOrder() {
    this.dialogFormVisible = false;
    _router.default.push({
      path: "/order/modify/" + this.orderId
    });
  }
  async resetOrder() {
    this.dialogFormVisible = true;
    this.orderExists = false;
    this.dealerList = [];
    this.dealersUserList = [];
    this.dealerId = null;
    this.dealersUserId = null;
  }
  async handleExcelDownload() {
    this.downloadLoading = false;
    try {
      const query = this.listQuery;
      let startDate = "";
      let endDate = "";
      if (this.listQuery.startDate && this.listQuery.endDate) {
        startDate = this.listQuery.startDate;
        endDate = this.listQuery.endDate;
      }
      // let datas: any = await this.getListWithoutPagination();
      // let data = datas.data;
      const blob = await (0, _order.downloadExcel)({
        query,
        startDate,
        endDate
      });
      // Create a blob URL for the Excel file
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "orders.xlsx");
      document.body.appendChild(link);
      // Simulate click to trigger download
      link.click();
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading Excel:", error);
      this.$message.error("Error downloading Excel");
    } finally {
      // Reset loading state
      this.downloadLoading = false;
    }
  }
  async handleDownload() {
    const orderFilter = Object.assign({}, this.listQuery);
    delete orderFilter.limit;
    delete orderFilter.page;
    this.downloadLoading = true;
    const tHeader = ["Order Time", "Order Reference", "Status", "User Name", "Mobile Number", "Organization", "GST Number", "PAN Number", "Broker", "Billing Address", "Shipping Address", "Product", "Quantity", "Rate", "Total Amount", "Tax Amount",
    // 'Freight Charge',
    "Grand Total", "Payment", "Balance Payment", "Delivery Option", "VRL", "Nominated Transport Details"];
    const filterVal = ["orderTimestamp", "orderReference", "status", "user.fullName", "user.mobileNumber", "user.organization.name", "user.organization.gstNumber", "user.organization.panNumber", "broker.name", "address", "shippingAddress", "products", "quantity", "rate", "totalAmount", "taxAmount",
    // 'freightCharges',
    "grandTotal", "amountReceived", "balance", "deliveryOption", "vrlDeliveryLocation.name", "nominatedTransportDetails"];
    if (this.dateRange) {
      this.startDate = this.dateRange.start;
      this.endDate = this.dateRange.end;
      var exportData = await (0, _order.orderExport)({
        startDate: this.startDate,
        endDate: this.endDate
      });
      const data = (0, _index2.formatJson)(filterVal, exportData);
      (0, _excel.exportJson2Excel)(tHeader, data, this.$tc("route.orderManagement.orders.title"));
      this.downloadLoading = false;
    } else {
      this.$message({
        type: "info",
        message: "Please select a date range"
      });
      this.downloadLoading = false;
    }
  }
  async confirmOrder(order = null, event) {
    if (order) {
      try {
        order.status = "loading";
        const data = await (0, _order.confirmOrder)(order.id);
        this.getList();
      } catch (error) {
        order.status = "in cart";
      }
    }
  }
  dispatchOrder(orderId) {
    _router.default.push({
      path: "/orders-dispatches/list/" + orderId
    });
  }
  async _reviseOrder(orderId = 0) {
    try {
      this.reviseOrderFlag = true;
      const confirm = await this.$confirm("Are you sure to recalculate this order?", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        confirmButtonClass: "el-button--success",
        cancelButtonClass: "el-button--danger",
        type: "warning"
      });
      // check if confirm
      if (confirm === "confirm") {
        const data = await (0, _order.reviseOrder)({
          id: orderId,
          saveDiscounts: true,
          skipReturn: true
        });
        this.$message({
          type: "success",
          message: "Order recalculated successfully"
        });
        await this.getList();
      }
    } catch (error) {
      this.$message({
        type: "error",
        message: error.toString()
      });
    } finally {
      this.reviseOrderFlag = false;
    }
  }
  handleSelectionChange(selectedOrders) {
    // empty Selection
    this.selectedOrderIds = [];
    // add ids
    this.selectedOrderIds = selectedOrders.map(order => order.id);
  }
  async showSelectedProductInfo() {
    // get orders-products-dispatches for selected order ids
    const ordersProduct = await (0, _order.getOrdersProducts)((0, _index2.getQuery)({
      orderId: this.selectedOrderIds.join(","),
      sort: ["schemesCategoryId,DESC", "productId,DESC"],
      filter: {
        orderId: "$in"
      }
    }));
    const schemesCategoryIndexMapping = {};
    const schemesCategoryWiseData = [];
    // loop over
    ordersProduct.forEach(ordersProduct => {
      // get schemesCategoryId
      const schemesCategoryId = ordersProduct.schemesCategoryId || 0;
      // check if it doesn't exist
      if (!(schemesCategoryId in schemesCategoryIndexMapping)) {
        var _ordersProduct$scheme;
        // push
        const newIndex = schemesCategoryWiseData.push({
          id: "sc-" + schemesCategoryId,
          product: {
            name: ((_ordersProduct$scheme = ordersProduct.schemesCategory) === null || _ordersProduct$scheme === void 0 ? void 0 : _ordersProduct$scheme.name) || "NA"
          },
          productsVariation: null,
          mrp: null,
          ordersProductsDiscount: [],
          nettRate: null,
          quantity: 0,
          quantityDispatched: 0,
          numberOfCartons: 0,
          subTotal: 0,
          taxedAmount: 0,
          children: []
        }) - 1;
        // add to mapping
        schemesCategoryIndexMapping[schemesCategoryId] = newIndex;
      }
      // get index
      const index = schemesCategoryIndexMapping[schemesCategoryId];
      // change for  quantity
      let quantity = +ordersProduct.quantity;
      // check if order is Order Partial Cancelled
      if (ordersProduct.order.status === "Order Partial Cancelled") {
        quantity = ordersProduct.quantityDispatched;
      }
      // increase
      schemesCategoryWiseData[index].quantity += quantity;
      schemesCategoryWiseData[index].quantityDispatched += +ordersProduct.quantityDispatched;
      schemesCategoryWiseData[index].numberOfCartons += +(quantity / ordersProduct.product.masterCarton).toFixed(2);
      schemesCategoryWiseData[index].subTotal += +ordersProduct.subTotal;
      schemesCategoryWiseData[index].taxedAmount += +ordersProduct.taxedAmount;
      // add to child
      schemesCategoryWiseData[index].children.push(ordersProduct);
    });
    this.orderInfoData = {
      ordersProduct: schemesCategoryWiseData
    };
    this.dialogVisible = true;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "OrderList",
  components: {
    Pagination: _index.default,
    Enabled: _index3.default,
    Inr: _index4.default,
    OrderProductTab: _OrderProductTab.default
  }
})], default_1);
var _default = exports.default = default_1;