"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _admins = require("@/api/admins");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _roles = require("@/api/roles");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _admin = require("@/store/modules/admin");
var _common = require("@/common");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.rolesList = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.loggedInAdmin = null;
    this.listQuery = {
      page: 1,
      limit: 10,
      fullName: undefined,
      emailAddress: undefined,
      mobileNumber: undefined,
      adminsRoleId: undefined,
      enabled: true,
      sort: "id,ASC",
      filter: {
        fullName: "$contL",
        emailAddress: "$contL",
        mobileNumber: "starts",
        enabled: "$eq",
        adminsRoleId: "$in"
      }
    };
    this.sortOptions = [{
      label: "ID Ascending",
      key: "id,ASC"
    }, {
      label: "ID Descending",
      key: "id,DESC"
    }];
    this.enableTypeOptions = [{
      key: true,
      value: "Enable"
    }, {
      key: false,
      value: "Disabled"
    }];
  }
  activated() {
    this.loggedInAdmin = _admin.AdminModule;
    this.getAdminRoles().then(() => {
      if (!this.listQuery.adminsRoleId || this.listQuery.adminsRoleId.length === 0) {
        delete this.listQuery.adminsRoleId; // Omit if no role IDs are present
      }
      this.getList();
    });
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _admins.getAdmins)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  _isSuperAdmin() {
    return (0, _common.isSuperAdmin)();
  }
  async getAdminRoles() {
    try {
      this.listLoading = true;
      this.rolesList = await (0, _roles.getRoles)((0, _index2.getQuery)({
        enabled: true,
        sort: "name,ASC",
        filter: {
          enabled: "$eq"
        }
      }));
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  getAllRoleIds() {
    return this.rolesList.map(role => role.id);
  }
  handleFilter() {
    // Check if adminsRoleId is empty or undefined
    if (!this.listQuery.adminsRoleId || this.listQuery.adminsRoleId.length === 0) {
      // Remove adminsRoleId from the query if no values are selected
      delete this.listQuery.adminsRoleId;
    } else {
      // Ensure it's an array when adminsRoleId has values
      this.listQuery.adminsRoleId = this.listQuery.adminsRoleId;
    }
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop}, + (order === "ascending" ? "ASC" : "DESC")`;
      this.handleFilter();
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? "ascending" : sort === `${key},DESC` ? "descending" : "";
  }
  handleDownload() {
    this.downloadLoading = true;
    const tHeader = ["ID", "Full Name", "Email", "Mobile Number", "Status", "timestamp"];
    const filterVal = ["id", "fullName", "emailAddress", "mobileNumber", "enabled", "createdTimestamp"];
    const data = (0, _index2.formatJson)(filterVal, this.list);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc("route.adminManagement.admins.title"));
    this.downloadLoading = false;
  }
  // canEdit(admin: IAdminData = null) {
  //   // null check
  //   if (!admin) {
  //     return false;
  //   }
  //   // check if editing self
  //   if (admin.id === this.loggedInAdmin.id) {
  //     return false;
  //   }
  //   // check if logged in adminsRoleId is greater or equal to the adminsRoleId of the admin being edited
  //   if (this.loggedInAdmin.adminsRoleId <= admin.adminsRoleId) {
  //     return true;
  //   }
  //   // disallow editing
  //   return false;
  // }
  canEdit(admin = null) {
    // null check
    if (!admin) {
      return false;
    }
    // check if editing self
    if (admin.id === this.loggedInAdmin.id) {
      return false;
    }
    // check if logged in adminsRoleIds contains a roleId that is less than or equal to any roleId in admin.adminsRole
    if (this.loggedInAdmin.adminsRoleIds.some(roleId => admin.adminsRole.some(adminRole => roleId <= adminRole.id))) {
      return true;
    }
    // disallow editing
    return false;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "AdminList",
  components: {
    Pagination: _index.default,
    Enabled: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;