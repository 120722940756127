"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _dealersUsers = require("@/api/dealers-users");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _index = _interopRequireDefault(require("@/components/FileUpload/index.vue"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.avatar = [];
    this.validateRequire = (rule, value, callback) => {
      if (value === '') {
        if (rule.field === 'imageURL') {
          this.$message({
            message: 'Upload cover image is required',
            type: 'error'
          });
        } else {
          this.$message({
            message: rule.field + ' is required',
            type: 'error'
          });
        }
        callback(new Error(rule.field + ' is required'));
      } else {
        callback();
      }
    };
    this.formData = Object.assign({}, _dealersUsers.defaultDealersUserData);
    this.loading = false;
    this.rules = {
      fullName: [{
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      }, {
        min: 3,
        max: 150,
        message: 'Length should be 3 to 150',
        trigger: 'blur'
      }],
      // emailAddress: [
      //   { required: true, message: "This field is required", trigger: "blur" },
      //   {
      //     type: "email",
      //     required: true,
      //     message: "Please enter valid email",
      //     trigger: "change",
      //   },
      // ],
      mobileNumber: [{
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      }, {
        pattern: /^\d+$/,
        message: 'Please enter numbers only',
        trigger: 'blur'
      }, {
        min: 10,
        max: 10,
        message: 'Length should be 10 only',
        trigger: ['blur']
      }],
      password: [{
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      }, {
        min: 6,
        message: 'Length should be of minimum 8 characters',
        trigger: ['blur']
      }],
      dealerId: [{
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      }]
    };
  }
  get lang() {
    return _app.AppModule.language;
  }
  activated() {
    if (this.isEdit) {
      const id = parseInt(this.$route.params && this.$route.params.id);
      this.fetchData(id);
    } else {
      this.formData = Object.assign({}, _dealersUsers.defaultDealersUserData);
      this.formData.dealerId = parseInt(this.$route.params && this.$route.params.dealerId);
    }
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _dealersUsers.getDealersUserById)(id);
      this.formData = data;
      // set avatar
      this.avatar.push({
        name: data.name,
        url: data.avatar
      });
      const title = this.formData.fullName;
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {} // eslint-disable-line  no-empty
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  fileUploaded(file) {
    this.formData.avatar = file.response;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      this.formData.dealer = {
        id: this.formData.dealerId
      };
      if (this.isEdit) {
        // update user
        await (0, _dealersUsers.updateDealersUser)(this.formData.id, this.formData);
      } else {
        await (0, _dealersUsers.register)(this.formData);
      }
      this.loading = false;
      this.$notify({
        title: 'Success',
        message: 'Dealers User added successfully',
        type: 'success',
        duration: 2000
      });
      _router.default.push({
        path: '/dealers-users/list/' + this.formData.dealerId
      });
    } catch (err) {
      if (err instanceof Error) {
        this.$notify.error({
          title: 'Error',
          message: err.message
        });
      }
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'DealersUserDetail',
  components: {
    FileUpload: _index.default
  }
})], default_1);
var _default = exports.default = default_1;