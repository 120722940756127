import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
import { defaultCashDiscountsData, getCashDiscountById, updateCashDiscount, createCashDiscount } from "@/api/master/cash-discounts";
import { AppModule } from "@/store/modules/app";
import { TagsViewModule } from "@/store/modules/tags-view";
import router from "@/router";
import { Action } from "vuex-module-decorators";
let default_1 = class default_1 extends Vue {
  constructor() {
    super(...arguments);
    this.countryList = [];
    this.validateRequire = (rule, value, callback) => {
      if (value === "") {
        if (rule.field === "imageURL") {
          this.$message({
            message: "Upload cover image is required",
            type: "error"
          });
        } else {
          this.$message({
            message: rule.field + " is required",
            type: "error"
          });
        }
        callback(new Error(rule.field + " is required"));
      } else {
        callback();
      }
    };
    this.formData = Object.assign({}, defaultCashDiscountsData);
    this.loading = false;
    this.rules = {
      name: [{
        required: true,
        message: "Please enter name",
        trigger: "blur"
      }, {
        min: 3,
        max: 150,
        message: "Length should be 3 to 150",
        trigger: "blur"
      }],
      rate: [{
        required: true,
        message: "Please choose a rate ",
        trigger: "blur"
      }, {
        required: true,
        // message: 'Please enter valid email',
        trigger: "change"
      }]
    };
  }
  get lang() {
    return AppModule.language;
  }
  activated() {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, defaultCashDiscountsData);
    }
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await getCashDiscountById(id);
      this.formData = data;
    } catch (err) {} // eslint-disable-line  no-empty
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title = null) {
    document.title = `Manage Cash Discount`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  handleDuration(value) {
    if (value) {
      this.formData.endDuration = parseFloat(value) + 14;
    }
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      if (this.isEdit) {
        await updateCashDiscount(this.formData.id, this.formData);
      } else {
        await createCashDiscount(this.formData);
      }
      this.loading = false;
      this.$notify({
        title: "Success",
        message: "Cash Discount saved successfully",
        type: "success",
        duration: 2000
      });
      router.push("/cash-discounts/list");
    } catch (err) {
      this.loading = false;
    }
  }
};
__decorate([Prop({
  default: false
})], default_1.prototype, "isEdit", void 0);
__decorate([Action], default_1.prototype, "saveForm", null);
default_1 = __decorate([Component({
  name: "CashDiscountsDetail",
  components: {}
})], default_1);
export default default_1;