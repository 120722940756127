"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      clearable: "",
      remote: "",
      loading: _vm.dealerListLoading,
      "loading-text": "fetching dealers..",
      "remote-method": _vm.getDealerList,
      placeholder: _vm.$t("dealersDispatch.dealer")
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.id,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "id", $$v);
      },
      expression: "listQuery.id"
    }
  }, _vm._l(_vm.dealerList, function (dealer) {
    return _c("el-option", {
      key: dealer.id,
      attrs: {
        label: dealer.name,
        value: dealer.id
      }
    }, [_c("span", {
      staticStyle: {
        float: "left",
        "font-size": "13px"
      }
    }, [_vm._v(_vm._s(dealer.name) + " - " + _vm._s(dealer.code))]), _c("span", {
      staticStyle: {
        float: "right",
        color: "#8492a6",
        "font-size": "13px"
      }
    }, [_vm._v("   | ID: " + _vm._s(dealer.id))])]);
  }), 1), _c("el-input", {
    staticClass: "filter-item",
    attrs: {
      placeholder: _vm.$t("dealer.code")
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleFilter.apply(null, arguments);
      }
    },
    model: {
      value: _vm.listQuery.code,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "code", $$v);
      },
      expression: "listQuery.code"
    }
  }), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      placeholder: _vm.$t("dealer.status"),
      clearable: ""
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.status,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "status", $$v);
      },
      expression: "listQuery.status"
    }
  }, _vm._l(["Pending", "Approved", "Blocked", "Hold"], function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1), _c("el-select", {
    attrs: {
      filterable: "",
      clearable: "",
      loading: _vm.branchListLoading,
      name: "branchId",
      placeholder: "Select Branch"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.branchId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "branchId", $$v);
      },
      expression: "listQuery.branchId"
    }
  }, _vm._l(_vm.branchList, function (branch) {
    return _c("el-option", {
      key: branch.id,
      attrs: {
        label: branch.code,
        value: branch.id
      }
    }, [_c("span", {
      staticStyle: {
        float: "left"
      }
    }, [_vm._v(_vm._s(branch.code))])]);
  }), 1), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: "Select State"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery["address.stateId"],
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "address.stateId", $$v);
      },
      expression: "listQuery['address.stateId']"
    }
  }, _vm._l(_vm.stateList, function (state) {
    return _c("el-option", {
      key: state.id,
      attrs: {
        label: state.name,
        value: state.id
      }
    });
  }), 1), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      disabled: !_vm.listQuery["address.stateId"],
      filterable: "",
      clearable: "",
      placeholder: "Select City"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery["address.cityId"],
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "address.cityId", $$v);
      },
      expression: "listQuery['address.cityId']"
    }
  }, _vm._l(_vm.cityList, function (city) {
    return _c("el-option", {
      key: city.id,
      attrs: {
        label: city.name,
        value: city.id
      }
    });
  }), 1), !_vm.isAgent ? _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: "Select Agent"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.agentId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "agentId", $$v);
      },
      expression: "listQuery.agentId"
    }
  }, _vm._l(_vm.agentList, function (agent) {
    return _c("el-option", {
      key: agent.id,
      attrs: {
        label: agent.name,
        value: agent.id
      }
    });
  }), 1) : _vm._e(), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      placeholder: _vm.$t("dealer.enabled"),
      clearable: ""
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "enabled", $$v);
      },
      expression: "listQuery.enabled"
    }
  }, _vm._l(_vm.enableTypeOptions, function (item) {
    return _c("el-option", {
      key: item.key,
      attrs: {
        label: item.value,
        value: item.key
      }
    });
  }), 1), _c("el-select", {
    staticClass: "filter-item",
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.sort,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "sort", $$v);
      },
      expression: "listQuery.sort"
    }
  }, _vm._l(_vm.sortOptions, function (item) {
    return _c("el-option", {
      key: item.key,
      attrs: {
        label: item.label,
        value: item.key
      }
    });
  }), 1), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "info",
      size: "mini",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")]), !_vm.isAgent && _vm.hasNoAccess([_vm.UNIT_MANAGER, _vm.INVOICE_MANAGER]) ? _c("router-link", {
    staticClass: "margin-horizontal",
    attrs: {
      to: "/dealers/add/"
    }
  }, [!_vm.isReadOnly ? _c("el-button", {
    staticClass: "filter-item",
    attrs: {
      type: "warning",
      size: "mini",
      icon: "el-icon-plus"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.add")) + " ")]) : _vm._e()], 1) : _vm._e(), !_vm.isReadOnly ? _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-document"
    },
    on: {
      click: _vm.handleExcelDownload
    }
  }, [_vm._v(" Export as Excel ")]) : _vm._e()], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "50",
      align: "center",
      label: "ID",
      prop: "id"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "200px",
      label: "Name",
      prop: "name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("enabled", {
          attrs: {
            url: "/dealers/" + scope.row.id
          },
          model: {
            value: scope.row.enabled,
            callback: function ($$v) {
              _vm.$set(scope.row, "enabled", $$v);
            },
            expression: "scope.row.enabled"
          }
        }), _vm._v(" " + _vm._s(scope.row.name) + " "), scope.row.code ? _c("i", [_vm._v("(" + _vm._s(scope.row.code) + ")")]) : _vm._e(), _vm._v(" "), _c("br"), scope.row.city ? _c("i", {
          staticClass: "el-icon-place"
        }, [_vm._v(" " + _vm._s(scope.row.city) + " ")]) : _vm._e(), _c("br"), scope.row.discount ? _c("i", {
          staticClass: "el-icon-discount"
        }, [_vm._v(" " + _vm._s(scope.row.discount) + " % ")]) : _vm._e()];
      }
    }])
  }), !_vm.isAgent ? _c("el-table-column", {
    attrs: {
      width: "160",
      label: "Agent",
      prop: "agent.name"
    }
  }) : _vm._e(), !_vm.isAgent ? _c("el-table-column", {
    attrs: {
      width: "160",
      label: "Role",
      prop: "dealersRole.name"
    }
  }) : _vm._e(), !_vm.isAgent ? _c("el-table-column", {
    attrs: {
      width: "90",
      label: "Branch Code",
      prop: "branch.code",
      align: "center"
    }
  }) : _vm._e(), _c("el-table-column", {
    attrs: {
      width: "100",
      align: "center",
      label: "Status",
      prop: "status"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini",
            type: _vm.statusColorMap[scope.row.status]
          }
        }, [_vm._v(" " + _vm._s(scope.row.status) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "180",
      align: "right",
      label: "Credits"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" Allocated: "), _c("inr", {
          attrs: {
            number: scope.row.creditLimit
          }
        }), _vm._v(" "), _c("br"), _vm._v(" Available: "), _c("inr", {
          attrs: {
            number: scope.row.creditLimitAvailable
          }
        }), _vm._v(" "), _c("br"), _vm._v(" Used: "), _c("inr", {
          attrs: {
            number: scope.row.creditLimitUsed
          }
        }), _vm._v(" "), _c("br")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: _vm.$t("table.createdDate"),
      width: "180",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm._f("moment")(scope.row.createdTimestamp, "DD-MM-YYYY hh:mm a")) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "Actions",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [!_vm.isAgent && _vm.hasNoAccess([_vm.UNIT_MANAGER, _vm.INVOICE_MANAGER]) ? _c("router-link", {
          attrs: {
            to: "/dealers/edit/" + scope.row.id
          }
        }, [!_vm.isReadOnly ? _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-edit"
          }
        }) : _vm._e()], 1) : _vm._e(), _vm._v("   "), _c("router-link", {
          attrs: {
            to: "/dealers-users/list/" + scope.row.id
          }
        }, [_c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: "Users"
          }
        }, [!_vm.isReadOnly ? _c("el-button", {
          attrs: {
            type: "warning",
            size: "mini",
            icon: "el-icon-s-custom"
          }
        }) : _vm._e()], 1)], 1), _vm._v("   "), !_vm.isAgent ? _c("router-link", {
          attrs: {
            to: "/dealers-addresses/list/" + scope.row.id
          }
        }, [_c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: "Addresses"
          }
        }, [!_vm.isReadOnly ? _c("el-button", {
          attrs: {
            type: "info",
            size: "mini",
            icon: "el-icon-location"
          }
        }) : _vm._e()], 1)], 1) : _vm._e(), !_vm.isAgent ? _c("router-link", {
          attrs: {
            to: "/dealers-transporters/list/" + scope.row.id
          }
        }, [_c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: "Transporters"
          }
        }, [!_vm.isReadOnly ? _c("el-button", {
          attrs: {
            type: "success",
            size: "mini",
            icon: "el-icon-truck"
          }
        }) : _vm._e()], 1)], 1) : _vm._e()];
      }
    }])
  })], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;