var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Enabled",
      prop: "enabled"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.formData.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "enabled", $$v);
      },
      expression: "formData.enabled"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Start Duration",
      prop: "startDuration"
    }
  }, [_c("el-input", {
    attrs: {
      name: "startDuration",
      required: "",
      placeholder: "Enter Start Duration"
    },
    on: {
      change: _vm.handleDuration
    },
    model: {
      value: _vm.formData.startDuration,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "startDuration", $$v);
      },
      expression: "formData.startDuration"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "End Duration",
      prop: "endDuration"
    }
  }, [_c("el-input", {
    attrs: {
      name: "endDuration",
      required: "",
      placeholder: "Enter End Duration",
      disabled: ""
    },
    model: {
      value: _vm.formData.endDuration,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "endDuration", $$v);
      },
      expression: "formData.endDuration"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Cash Discount",
      prop: "discount"
    }
  }, [_c("el-input-number", {
    attrs: {
      name: "discount",
      required: "",
      precision: 2,
      step: 1,
      max: 100
    },
    model: {
      value: _vm.formData.discount,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "discount", $$v);
      },
      expression: "formData.discount"
    }
  }), _c("span", [_vm._v(" (%)")])], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };