"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateDealersRole = exports.getDealersRoles = exports.getDealersRoleById = exports.deleteDealersRole = exports.defaultDealersRoleData = exports.createDealersRole = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultDealersRoleData = exports.defaultDealersRoleData = {
  id: 0,
  enabled: true,
  name: ''
};
const getDealersRoles = params => (0, _request.default)({
  url: '/dealers-roles',
  method: 'get',
  params
});
exports.getDealersRoles = getDealersRoles;
const getDealersRoleById = id => (0, _request.default)({
  url: `/dealers-roles/${id}`,
  method: 'get'
});
exports.getDealersRoleById = getDealersRoleById;
const updateDealersRole = (id, data) => (0, _request.default)({
  url: `/dealers-roles/${id}`,
  method: 'patch',
  data
});
exports.updateDealersRole = updateDealersRole;
const deleteDealersRole = id => (0, _request.default)({
  url: `/dealers-roles/${id}`,
  method: 'delete'
});
exports.deleteDealersRole = deleteDealersRole;
const createDealersRole = data => (0, _request.default)({
  url: '/dealers-roles',
  method: 'post',
  data
});
exports.createDealersRole = createDealersRole;