"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _inventoryInwards = require("@/api/inventory-inwards");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _admin = require("@/store/modules/admin");
var _units = require("@/api/locations/units");
var _admins = require("@/api/admins");
var _products = require("@/api/products");
var _InventoryRemove = _interopRequireDefault(require("./components/InventoryRemove.vue"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.isReadOnly = false;
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.productIdRowsCount = {};
    this.listLoading = false;
    this.downloadLoading = false;
    this.productList = [];
    this.dialogVisible = false;
    this.inventoryInwardId = 0;
    this.inventoryInwardData = null;
    this.listQuery = {
      page: 1,
      limit: 20,
      unitId: undefined,
      // adminId: undefined,
      productId: undefined,
      sort: "productId,ASC",
      filter: {
        unitId: "$eq",
        // adminId: "$eq",
        productId: "$eq"
      }
    };
    this.loggedInAdmin = null;
    this.unitManagers = [];
    this.unitList = [];
  }
  activated() {
    this.loggedInAdmin = _admin.AdminModule;
    if (_admin.AdminModule.roles.includes("Read Only")) {
      this.isReadOnly = true;
    }
    this.$nextTick(() => {
      // get unit managers
      this.fetchUnitManagers();
    });
    // get all inventory
    // this.getList();
  }
  async fetchUnitManagers() {
    let managerUnitId;
    // if adding & logged in user is unit manager
    // if (this.loggedInAdmin.adminsRoleId === 3) {
    if (this.loggedInAdmin.adminsRoleIds.includes(3)) {
      managerUnitId = this.loggedInAdmin.id;
      // set to form data
      this.listQuery.adminId = this.loggedInAdmin.id;
      // fetch units as per manager
      this.fetchUnitList();
    }
    let result = await (0, _admins.getAdmins)((0, _index2.getQuery)({
      id: managerUnitId,
      enabled: true,
      adminsRoleId: 3,
      filter: {
        id: "$eq",
        enabled: "$eq",
        adminsRoleId: "$in"
      }
    }));
    this.unitManagers = result.data;
  }
  async fetchUnitList() {
    try {
      // empty check adminId
      if (!this.listQuery.adminId) {
        // unset unit ID
        this.listQuery.unitId = undefined;
        // empty unit list
        this.unitList = [];
        // empty inventory list
        this.list = [];
      } else {
        this.unitList = await (0, _units.getUnits)((0, _index2.getQuery)({
          enable: true,
          "admin.id": this.listQuery.adminId,
          sort: "name,ASC",
          filter: {
            enabled: "$eq",
            "admin.id": "$eq"
          },
          select: ["id", "name", "code"].join(",")
        }));
      }
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async getList() {
    try {
      this.listLoading = true;
      // get data
      const data = await (0, _inventoryInwards.getInventoryInwards)((0, _index2.getQuery)(this.listQuery));
      const inventoryInwards = data.data;
      // order by product id
      inventoryInwards.sort((a, b) => a.productId - b.productId);
      /* find out product id rows count & assign to find product id row */
      this.productIdRowsCount = {};
      inventoryInwards.forEach(item => {
        if (this.productIdRowsCount[item.productId]) {
          this.productIdRowsCount[item.productId] += 1;
        } else {
          this.productIdRowsCount[item.productId] = 1;
        }
      });
      // set to list
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async fetchProductList(query) {
    try {
      if (query.length > 2) {
        this.productList = await (0, _products.searchProducts)((0, _index2.getQuery)({
          keyword: query
        }));
      }
    } catch (err) {} // eslint-disable-line  no-empty
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  toggleDialog(data = null) {
    this.dialogVisible = true;
    this.inventoryInwardId = data.id;
    this.inventoryInwardData = data;
  }
  handleDialog(showDialog) {
    this.dialogVisible = !showDialog;
    this.getList();
  }
  sortChange(data) {
    this.handleFilter();
  }
  handleDownload() {
    this.downloadLoading = true;
    const tHeader = ["id", "fullname", "email", "contact", "role"];
    const filterVal = ["id", "fullName"];
    const data = (0, _index2.formatJson)(filterVal, this.list);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc("route.userManagement.users.title"));
    this.downloadLoading = false;
  }
  doProductRowSpan(args) {
    const {
      row,
      column,
      rowIndex,
      columnIndex
    } = args;
    if (columnIndex === 0) {
      // check row - 1 has same product id
      if (rowIndex > 0) {
        const prevRow = this.list[rowIndex - 1];
        if (prevRow.productId === row.productId) {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
      return {
        rowspan: this.productIdRowsCount[row.productId],
        colspan: 1
      };
    }
    return 0;
  }
  async downloadData() {
    this.listLoading = true;
    try {
      const params = {};
      if (this.listQuery.unitId) {
        params.unitId = this.listQuery.unitId;
      }
      if (this.listQuery.productId) {
        params.productId = this.listQuery.productId;
      }
      if (Object.keys(params).length) {
        const data = await (0, _inventoryInwards.downloadInventoryInwardPdf)((0, _index2.getQuery)(params));
        window.open(data.url, "_blank");
      } else {
        this.$message({
          message: "Please select atleast one filter",
          type: "warning"
        });
      }
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "InventoryInwardList",
  components: {
    Pagination: _index.default,
    Enabled: _index3.default,
    InventoryRemove: _InventoryRemove.default
  }
})], default_1);
var _default = exports.default = default_1;