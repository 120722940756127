"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _schemes = require("@/api/schemes");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _admin = require("@/store/modules/admin");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.isReadOnly = false;
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.listQuery = {
      page: 1,
      limit: 10,
      enabled: true,
      sort: "id,DESC",
      filter: {
        enabled: "$eq"
      }
    };
    this.sortOptions = [{
      label: "ID Ascending",
      key: "id,ASC"
    }, {
      label: "ID Descending",
      key: "id,DESC"
    }];
    this.enableTypeOptions = [{
      key: true,
      value: "Enable"
    }, {
      key: false,
      value: "Disabled"
    }];
  }
  activated() {
    if (_admin.AdminModule.roles.includes("Read Only")) {
      this.isReadOnly = true;
    }
    this.getList();
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _schemes.getSchemes)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    this.handleFilter();
  }
  getDateRangeColor(data) {
    if (data.validFrom && data.validTill) {
      const startDate = this.$moment(data.startDate);
      const endDate = this.$moment(data.endDate);
      const now = this.$moment();
      // check if not yet started
      if (now > endDate) {
        return "danger";
      } else if (now >= startDate && now <= endDate) {
        return "success";
      } else if (startDate > now) {
        return "primary";
      }
    }
    return "info";
  }
  handleDownload() {
    this.downloadLoading = true;
    const tHeader = ["id", "fullname", "email", "contact", "role"];
    const filterVal = ["id", "fullName"];
    const data = (0, _index2.formatJson)(filterVal, this.list);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc("route.userManagement.users.title"));
    this.downloadLoading = false;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "SchemeList",
  components: {
    Pagination: _index.default,
    Enabled: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;