"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _vueCountTo = _interopRequireDefault(require("vue-count-to"));
var _dealers = require("@/api/dealers");
var _agents = require("@/api/agents");
var _products = require("@/api/products");
var _order = require("@/api/order");
var _ordersTickets = require("@/api/orders-tickets");
var _index = require("@/utils/index");
var _admin = require("@/store/modules/admin");
var _ordersDispatches = require("@/api/orders-dispatches");
var _index2 = _interopRequireDefault(require("@/components/Inr/index.vue"));
var _common = require("@/common");
var _dashboard = require("@/api/dashboard");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.supportTicketCount = [];
    this.orderStatus = [{
      status: "Order Confirmed",
      color: "blue",
      count: 0
    }, {
      status: "Partial Dispatch",
      color: "info",
      count: 0
    }, {
      status: "Order Dispatched",
      color: "info",
      count: 0
    }, {
      status: "Order Delivered",
      color: "success",
      count: 0
    }, {
      status: "Order Cancelled",
      color: "",
      count: 0
    }, {
      status: "Order Partial Cancelled",
      color: "",
      count: 0
    }];
    this.dispatchStatus = [{
      status: "Pending",
      color: "warning",
      count: 0
    }, {
      status: "Request Approval",
      color: "warning",
      count: 0
    }, {
      status: "Approved",
      color: "success",
      count: 0
    }, {
      status: "Rejected",
      color: "danger",
      count: 0
    }];
    this.dealerCount = 0;
    this.agentCount = 0;
    this.productscount = 0;
    this.orderscount = 0;
    this.totalOutstandingAmount = 0;
    this.totalAmountReceived = 0;
    this.totalFySales = 0;
    this.totalFyPayment = 0;
    this.currentRecievableAmount = 0;
    this.totalPendingOrders = 0;
    this.currentMonthSales = 0;
    this.currentMonthPayments = 0;
    this.previousMonthSales = 0;
    this.previousMonthPayments = 0;
    this.dailyOrderValue = 0;
    this.topProducts = [];
    this.topDealers = [];
    this.dateRange = "";
    this.superAdmin = false;
    this.isAgent = false;
    this.agentId = undefined;
    this.currentDateRange = "";
    this.overdueAmount30days = 0;
    this.overdueAmount60days = 0;
    this.overdueAmount90days = 0;
    this.overdueAmount120days = 0;
    this.currentMonthOrderValue = 0;
    this.currentMonthOrderCount = 0;
    this.currentMonthDate = "";
    this.previousMonthDate = "";
  }
  activated() {
    try {
      if (_admin.AdminModule.roles.includes("Super Admin")) {
        this.superAdmin = true;
      }
      if (_admin.AdminModule.userType === "Agent") {
        this.isAgent = true;
        this.agentId = _admin.AdminModule.agentId;
      }
      if (_admin.AdminModule.branchIds.length === 0) {
        throw new Error("You are not assigned to a branch.");
      }
      this.currentMonthDate = {
        start: this.$moment().startOf("month").format("YYYY-MM-DD 00:00") + " am",
        end: this.$moment().endOf("month").format("YYYY-MM-DD 11:59") + " pm"
      };
      this.previousMonthDate = {
        start: this.$moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD 00:00") + " am",
        end: this.$moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD 11:59") + " pm"
      };
      this.currentDateRange = {
        start: this.$moment().startOf("day").format("YYYY-MM-DD hh:mm") + " am",
        end: this.$moment().endOf("day").format("YYYY-MM-DD hh:mm") + " pm"
      };
      this.getDealerCount();
      this.getProductCount();
      this.getAgentCount();
      this.getOrderStatusCount();
      this.getDispatchStatusCount();
      this.fetchTotalOutstanding();
      this.fetchTotalCollection();
      this.fetchTotalFySales();
      this.fetchTotalFyPayment();
      this.fetchCurrentRecievable();
      this.fetchTotalPendingOrders();
      this.fetchCurrentMonthSales();
      this.fetchCurrentMonthPayments();
      this.fetchPreviousMonthPayments();
      this.fetchPreviousMonthSales();
      this.fetchDailyOrderValue();
      this.fetchSellingTopProducts();
      this.fetchTopDealers();
      this.fetchOverdueByDays();
      this.fetchCurrentMonthOrderValue();
      // this.getSupportTicketCount();
    } catch (err) {
      if (err instanceof Error) {
        this.$notify.error({
          title: "Error",
          message: err.message
        });
      }
    }
  }
  // private async getOrderStatusCount() {
  //   const data: any = await getStatusCount({ enabled: true });
  //   this.orderStatus.forEach((item: any) => {
  //     item.count = data[item.status] * 1;
  //   });
  // }
  async getOrderStatusCount() {
    try {
      // Get branchId from AdminModule
      const branchId = _admin.AdminModule.branchIds;
      // Build the query object
      const query = {
        enabled: true
      };
      // Add branchId to query if it's defined
      if (branchId !== undefined && branchId !== null) {
        query.branchId = branchId;
      }
      // Pass the query to getStatusCount
      const data = await (0, _order.getStatusCount)(query);
      // Update the order status counts
      this.orderStatus.forEach(item => {
        item.count = data[item.status] * 1;
      });
    } catch (error) {
      this.$notify({
        title: "Error",
        type: "error",
        message: error.toString()
      });
    }
  }
  async getDispatchStatusCount() {
    try {
      // Get branchId from AdminModule
      const branchId = _admin.AdminModule.branchIds;
      // Build the query object
      const query = {
        enabled: true
      };
      // Add branchId to query if it's defined
      if (branchId !== undefined && branchId !== null) {
        query.branchId = branchId;
      }
      // Pass the query to getStatusCount
      const data = await (0, _ordersDispatches.getDispatchCount)(query);
      this.dispatchStatus.forEach(item => {
        item.count = data[item.status] * 1;
      });
    } catch (error) {
      this.$notify({
        title: "Error",
        type: "error",
        message: error.toString()
      });
    }
  }
  async fetchTotalOutstanding() {
    const params = {};
    if (this.dateRange) {
      params.startDate = this.dateRange.start;
      params.endDate = this.dateRange.end;
    }
    const data = await (0, _dashboard.totalOutstanding)(params);
    this.totalOutstandingAmount = +data.outstanding;
  }
  async fetchTotalCollection() {
    const params = {
      limit: 5
    };
    if (this.dateRange) {
      params.startDate = this.dateRange.start;
      params.endDate = this.dateRange.end;
    }
    const data = await (0, _dashboard.totalCollection)(params);
    this.totalAmountReceived = +data.amountReceived;
  }
  async fetchTotalFySales() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await (0, _dashboard.totalFySales)(query);
    this.totalFySales = +data.sum;
  }
  async fetchTotalFyPayment() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await (0, _dashboard.totalFyPayment)(query);
    this.totalFyPayment = +data;
  }
  async fetchCurrentRecievable() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await (0, _dashboard.totalCurrentRecievable)(query);
    this.currentRecievableAmount = data;
  }
  async fetchTotalPendingOrders() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await (0, _dashboard.totalPendingOrders)(query);
    this.totalPendingOrders = +data;
  }
  async fetchCurrentMonthSales() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await (0, _dashboard.getCurrentMonthSales)(query);
    this.currentMonthSales = +data.sum;
  }
  async fetchCurrentMonthPayments() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await (0, _dashboard.getCurrentMonthPayments)(query);
    this.currentMonthPayments = +data;
  }
  async fetchPreviousMonthSales() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await (0, _dashboard.getPreviousMonthSales)(query);
    this.previousMonthSales = +data.previousMonthSales;
  }
  async fetchPreviousMonthPayments() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await (0, _dashboard.getPreviousMonthPayments)(query);
    this.previousMonthPayments = +data;
  }
  async fetchDailyOrderValue() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await (0, _dashboard.getDailyOrderValue)(query);
    this.dailyOrderValue = +data.total;
  }
  async fetchCurrentMonthOrderValue() {
    const query = {
      agentId: undefined
    };
    if (this.agentId) {
      query.agentId = this.agentId;
    }
    const data = await (0, _dashboard.getCurrentMonthOrderValue)(query);
    if (data) {
      this.currentMonthOrderValue = +data.total.currentMonthOrderValue;
      this.currentMonthOrderCount = +data.count;
    }
  }
  async fetchOverdueByDays() {
    let params = {
      days: 30,
      dealerWise: false
    };
    const data30 = await (0, _dashboard.getOverdueByDays)(params);
    this.overdueAmount30days = +data30.overdueDealers[0].overdueAmount.toFixed(2);
    params = {
      days: 60,
      dealerWise: false
    };
    const data60 = await (0, _dashboard.getOverdueByDays)(params);
    this.overdueAmount60days = +data60.overdueDealers[0].overdueAmount.toFixed(2);
    params = {
      days: 90,
      dealerWise: false
    };
    const data90 = await (0, _dashboard.getOverdueByDays)(params);
    this.overdueAmount90days = +data90.overdueDealers[0].overdueAmount.toFixed(2);
    params = {
      days: 120,
      dealerWise: false
    };
    const data120 = await (0, _dashboard.getOverdueByDays)(params);
    this.overdueAmount120days = +data120.overdueDealers[0].overdueAmount.toFixed(2);
  }
  async fetchSellingTopProducts() {
    const params = {
      limit: 5
    };
    if (this.dateRange) {
      params.startDate = this.dateRange.start;
      params.endDate = this.dateRange.end;
    }
    const data = await (0, _dashboard.topSellingProducts)(params);
    this.topProducts = data.data;
  }
  async fetchTopDealers() {
    const params = {
      limit: 5
    };
    if (this.dateRange) {
      params.startDate = this.dateRange.start;
      params.endDate = this.dateRange.end;
    }
    const data = await (0, _dashboard.topDealers)(params);
    this.topDealers = data.data;
  }
  async getSupportTicketCount() {
    this.supportTicketCount = await (0, _ordersTickets.ordersTicketsCount)({});
  }
  async getDealerCount() {
    try {
      const branchId = _admin.AdminModule.branchIds;
      // Build the query conditionally based on branchId
      const query = {
        enabled: true,
        branchId: undefined
      };
      // Add branchId to query if it's defined
      if ((branchId !== undefined || branchId !== null) && branchId.length > 0) {
        query.branchId = branchId;
      }
      this.dealerCount = await (0, _dealers.dealersCount)((0, _index.getQuery)(query));
    } catch (error) {
      this.$notify({
        title: "Error",
        type: "error",
        message: error.toString()
      });
    }
  }
  async getProductCount() {
    try {
      this.productscount = await (0, _products.productCount)((0, _index.getQuery)({
        enabled: true,
        filter: {
          enabled: "eq"
        }
      }));
    } catch (error) {
      this.$notify({
        title: "Error",
        type: "error",
        message: error.toString()
      });
    }
  }
  async getAgentCount() {
    const branchId = _admin.AdminModule.branchIds;
    // Build the query conditionally based on branchId
    const query = {
      enabled: true,
      branchId: undefined
    };
    // Add branchId to query if it's defined
    if (branchId !== undefined || branchId !== null) {
      query.branchId = branchId;
    }
    if (this.isSuperAdmin()) {
      this.agentCount = await (0, _agents.agentsCount)((0, _index.getQuery)(query));
    } else {
      this.agentCount = await (0, _agents.getAgentBranchWiseCount)((0, _index.getQuery)(query));
    }
  }
  getCurrentMonthDateRange() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    // Start date: first day of the current month at 12:00 AM
    const startDate = new Date(year, month, 1, 0, 0, 0);
    // End date: last day of the current month at 11:59 PM
    const endDate = new Date(year, month + 1, 0, 23, 59, 59);
    // Formatting the dates as "YYYY-MM-DD hh:mm AM/PM"
    const formatDate = date => {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        hour12: true
      };
      const dateStr = date.toLocaleString("en-US", options).replace(",", "");
      // Replace "/" with "-" in the date part
      return dateStr.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$1-$2");
    };
    return {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
    };
  }
  generateReportLink() {
    const currentDateRange = this.getCurrentMonthDateRange();
    const startDate = currentDateRange.startDate;
    const endDate = currentDateRange.endDate;
    return `/report/salesReport/list?startDate=${startDate}&endDate=${endDate}`;
  }
  async dateval() {
    await this.fetchSellingTopProducts();
    await this.fetchTopDealers();
    await this.fetchTotalOutstanding();
    await this.fetchTotalCollection();
  }
  isSuperAdmin() {
    return _admin.AdminModule.roles.indexOf("Super Admin") > -1;
  }
  _isAdmin() {
    return (0, _common.isAdmin)();
  }
  hasNoAccess(requiredRoles) {
    return (0, _common.isExcludedToAccess)(requiredRoles);
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "PanelGroup",
  components: {
    CountTo: _vueCountTo.default,
    Inr: _index2.default
  }
})], default_1);
var _default = exports.default = default_1;