"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateRole = exports.getRoutes = exports.getRoles = exports.getRoleById = exports.deleteRole = exports.defaultAdminRoleData = exports.createRole = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultAdminRoleData = exports.defaultAdminRoleData = {
  id: 0,
  enabled: true,
  name: ''
};
const getRoles = params => (0, _request.default)({
  url: '/admins-roles',
  method: 'get',
  params
});
exports.getRoles = getRoles;
const getRoleById = id => (0, _request.default)({
  url: `/admins-roles/${id}`,
  method: 'get'
});
exports.getRoleById = getRoleById;
const updateRole = (id, data) => (0, _request.default)({
  url: `/admins-roles/${id}`,
  method: 'patch',
  data
});
exports.updateRole = updateRole;
const deleteRole = id => (0, _request.default)({
  url: `/admins-roles/${id}`,
  method: 'delete'
});
exports.deleteRole = deleteRole;
const createRole = data => (0, _request.default)({
  url: '/admins-roles/',
  method: 'post',
  data
});
exports.createRole = createRole;
const getRoutes = params => (0, _request.default)({
  url: '/routes',
  method: 'get',
  params
});
exports.getRoutes = getRoutes;