"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      clearable: "",
      name: "branchId",
      placeholder: "Select Branch"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.branchId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "branchId", $$v);
      },
      expression: "listQuery.branchId"
    }
  }, _vm._l(_vm.branchList, function (branch) {
    return _c("el-option", {
      key: branch.id,
      attrs: {
        label: branch.code,
        value: branch.id
      }
    });
  }), 1), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      clearable: "",
      remote: "",
      loading: _vm.dealerListLoading,
      "loading-text": "fetching dealers..",
      "remote-method": _vm.getDealerList,
      placeholder: _vm.$t("dealersDispatch.dealer")
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.dealerId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "dealerId", $$v);
      },
      expression: "listQuery.dealerId"
    }
  }, _vm._l(_vm.dealerList, function (dealer) {
    return _c("el-option", {
      key: dealer.id,
      attrs: {
        label: dealer.name,
        value: dealer.id
      }
    }, [_c("span", {
      staticStyle: {
        float: "left",
        "font-size": "13px"
      }
    }, [_vm._v(_vm._s(dealer.name) + " - " + _vm._s(dealer.code))]), _c("span", {
      staticStyle: {
        float: "right",
        color: "#8492a6",
        "font-size": "13px"
      }
    }, [_vm._v("   | ID: " + _vm._s(dealer.id))])]);
  }), 1), !_vm.isAgent ? _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: _vm.$t("dealersDispatch.agent")
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.agentId,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "agentId", $$v);
      },
      expression: "listQuery.agentId"
    }
  }, _vm._l(_vm.agentList, function (agent) {
    return _c("el-option", {
      key: agent.id,
      attrs: {
        label: agent.name,
        value: agent.id
      }
    });
  }), 1) : _vm._e(), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      clearable: "",
      remote: "",
      loading: _vm.cityListLoading,
      "loading-text": "fetching cities..",
      "remote-method": _vm.getCityList,
      placeholder: "Search City"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.cityName,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "cityName", $$v);
      },
      expression: "listQuery.cityName"
    }
  }, _vm._l(_vm.cityList, function (city) {
    return _c("el-option", {
      key: city.id,
      attrs: {
        label: city.name,
        value: city.name
      }
    }, [_c("span", {
      staticStyle: {
        float: "left",
        "font-size": "13px"
      }
    }, [_vm._v(_vm._s(city.name))])]);
  }), 1), _c("el-select", {
    staticClass: "filter-item",
    attrs: {
      filterable: "",
      remote: "",
      clearable: "",
      "remote-method": _vm.getProductList,
      loading: _vm.productListLoading,
      placeholder: "Select Product"
    },
    on: {
      change: _vm.handleProductSelection
    },
    model: {
      value: _vm.selectedProduct,
      callback: function ($$v) {
        _vm.selectedProduct = $$v;
      },
      expression: "selectedProduct"
    }
  }, _vm._l(_vm.products, function (product) {
    return _c("el-option-group", {
      key: product.id,
      attrs: {
        label: "Main Product"
      }
    }, [_c("el-option", {
      key: "product-" + product.id,
      attrs: {
        label: product.name,
        value: "product-" + product.id
      }
    }, [_c("span", {
      staticStyle: {
        "font-weight": "bold"
      }
    }, [_vm._v(_vm._s(product.name))])]), product.productType !== "simple" && product.productsVariation && product.productsVariation.length ? [_c("el-option", {
      key: "variations-label-" + product.id,
      attrs: {
        label: "Variations",
        disabled: ""
      }
    }, [_c("span", {
      staticStyle: {
        "font-style": "italic",
        color: "gray"
      }
    }, [_vm._v("Variations:")])]), _vm._l(product.productsVariation, function (item) {
      return _c("el-option", {
        key: item.id,
        attrs: {
          label: product.name + "-" + item.name,
          value: "variation-" + item.id
        }
      }, [_c("span", [_vm._v(_vm._s(item.name))])]);
    })] : _vm._e()], 2);
  }), 1), _c("VueCtkDateTimePicker", {
    staticClass: "filter-item",
    attrs: {
      range: "",
      formatted: "DD-MM-YYYY"
    },
    model: {
      value: _vm.dateRange,
      callback: function ($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  }), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-search",
      size: "mini"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-document"
    },
    on: {
      click: _vm.handleExcelDownload
    }
  }, [_vm._v(" Export as Excel ")])], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    ref: "table",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "highlight-current-row": "",
      height: "500"
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      sortable: "custom",
      width: "80",
      align: "center",
      label: "ID",
      prop: "id",
      "class-name": _vm.getSortClass("id")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.id) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "150px",
      prop: "dealerName",
      label: "Dealer",
      sortable: "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("b", [_vm._v(_vm._s(scope.row.ordersProduct.order.dealer.name))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "150",
      align: "center",
      prop: "dealer.city",
      label: "City",
      sortable: "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.ordersProduct.order.dealer.city) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "150px",
      prop: "agent",
      label: "Agent",
      sortable: "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("b", [_vm._v(_vm._s(scope.row.ordersProduct.order.dealer.agent.name))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "150",
      align: "center",
      prop: "invoiceNumber",
      label: "Invoice Number",
      sortable: "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.ordersDispatch.invoiceNumber) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "150px",
      align: "center",
      prop: "createdTimestamp",
      label: "Invoice Date",
      sortable: "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm._f("moment")(scope.row.createdTimestamp, "DD-MM-YYYY")) + " "), _c("br"), _vm._v(" " + _vm._s(_vm._f("moment")(scope.row.createdTimestamp, "hh:mm a")) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "productName",
      label: "Item",
      width: "250",
      sortable: "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("enabled", {
          staticClass: "noPaddingTopBottom",
          attrs: {
            disabled: true
          },
          model: {
            value: scope.row.ordersProduct.product.enabled,
            callback: function ($$v) {
              _vm.$set(scope.row.ordersProduct.product, "enabled", $$v);
            },
            expression: "scope.row.ordersProduct.product.enabled"
          }
        }), _c("b", [_vm._v(_vm._s(scope.row.ordersProduct.product.name))]), _vm._v(" "), _c("br"), scope.row.ordersProduct.product.name !== scope.row.ordersProduct.productsVariation.name ? _c("small", [_vm._v("(" + _vm._s(scope.row.ordersProduct.productsVariation.name) + ")")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "150",
      align: "center",
      prop: "quantity",
      label: "Quantity(PCS)",
      sortable: "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.quantity) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "150",
      align: "center",
      prop: "quantity",
      label: "Cartons"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s((scope.row.quantity / scope.row.ordersProduct.product.masterCarton).toFixed(1)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "150",
      align: "center",
      prop: "listRate",
      label: "List Rate",
      sortable: "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.ordersProduct.mrp
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "150",
      align: "center",
      prop: "discount",
      label: "Policy Discount"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return _vm._l(scope.row.ordersProduct.ordersProductsDiscount, function (discount) {
          return _c("div", {
            key: discount.id
          }, [discount.discountType === "Policy" ? _c("div", [discount.percentDiscount ? _c("div", [_vm._v(" " + _vm._s(discount.percentDiscount) + "% ")]) : _vm._e()]) : _vm._e()]);
        });
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "150",
      align: "center",
      prop: "moqsDiscount",
      label: "MOQ Discount(%)"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return _vm._l(scope.row.ordersProduct.ordersProductsDiscount, function (discount) {
          return _c("div", {
            key: discount.id
          }, [discount.discountType === "Moq" ? _c("div", [_vm._v(" " + _vm._s(discount.percentDiscount) + " % ")]) : _vm._e()]);
        });
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "170",
      align: "center",
      prop: "schemesDiscount",
      label: "Schemes Discount(%)"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return _vm._l(scope.row.ordersProduct.ordersProductsDiscount, function (discount) {
          return _c("div", {
            key: discount.id
          }, [discount.discountType === "Scheme" ? _c("div", [_vm._v(" " + _vm._s(discount.percentDiscount) + " % ")]) : _vm._e()]);
        });
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "120",
      align: "center",
      prop: "nettRate",
      label: "Net Rate",
      sortable: "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: scope.row.ordersProduct.nettRate
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "140",
      align: "left",
      label: "Amount",
      prop: "subTotal",
      sortable: "custom",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("inr", {
          attrs: {
            number: parseFloat(scope.row.quantity) * parseFloat(scope.row.ordersProduct.nettRate)
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "100",
      align: "center",
      prop: "totalCarton",
      label: "Total Carton",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(Math.round(scope.row.totalCarton)) + " ")];
      }
    }])
  })], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;