import Layout from '@/layout/index.vue';
import { salesReportSubMenu } from './subMenus/report-sub-menu';
import { itemWiseReportSubMenu } from './subMenus/item-wise-report-sub-menu';
import { newItemWiseReportSubMenu } from './subMenus/new-item-wise-report-sub-menu';
import { dealerWiseOsOdReportSubMenu } from './subMenus/dealer-wise-os-od-report';
import { dealerWiseCashDiscountSubMenu } from './subMenus/dealer-wise-cash-discount';
const reportRouter = {
  path: '/report',
  component: Layout,
  redirect: 'noredirect',
  name: 'Reports',
  meta: {
    roles: ['Super Admin', 'Admin', 'Account Manager', 'Read Only'],
    title: 'report.title',
    icon: 'list'
  },
  children: [...salesReportSubMenu, ...itemWiseReportSubMenu, ...newItemWiseReportSubMenu, ...dealerWiseOsOdReportSubMenu, ...dealerWiseCashDiscountSubMenu] // ...hsnSummaryReportSubMenu]
};
export default reportRouter;