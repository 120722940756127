"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isValidURL = exports.isValidMobile = exports.isValidEmail = exports.isExternal = exports.isArray = void 0;
const isValidEmail = str => {
  const validateEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validateEmail.test(str);
};
exports.isValidEmail = isValidEmail;
const isExternal = path => /^(https?:|mailto:|tel:)/.test(path);
exports.isExternal = isExternal;
const isArray = arg => {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }
  return Array.isArray(arg);
};
exports.isArray = isArray;
const isValidURL = url => {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
};
exports.isValidURL = isValidURL;
const isValidMobile = mobileNumber => {
  const mobileNumberRegex = /^[0-9]\d{9}$/;
  return typeof mobileNumber === 'string' && mobileNumberRegex.test(mobileNumber);
};
exports.isValidMobile = isValidMobile;