"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updatePolicy = exports.getPolicyById = exports.getPolicies = exports.deletePolicy = exports.defaultPolicyData = exports.createPolicy = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultPolicyData = exports.defaultPolicyData = {
  id: 0,
  enabled: true,
  name: '',
  startDate: '',
  endDate: '',
  image: null
};
const getPolicies = params => (0, _request.default)({
  url: '/policies',
  method: 'get',
  params
});
exports.getPolicies = getPolicies;
const getPolicyById = id => (0, _request.default)({
  url: `/policies/${id}`,
  method: 'get'
});
exports.getPolicyById = getPolicyById;
const updatePolicy = (id, data) => (0, _request.default)({
  url: `/policies/${id}`,
  method: 'patch',
  data
});
exports.updatePolicy = updatePolicy;
const deletePolicy = id => (0, _request.default)({
  url: `/policies/${id}`,
  method: 'delete'
});
exports.deletePolicy = deletePolicy;
const createPolicy = data => (0, _request.default)({
  url: '/policies',
  method: 'post',
  data
});
exports.createPolicy = createPolicy;