"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateBanner = exports.getBanners = exports.getBannerById = exports.fileTransferHeaders = exports.deleteBanner = exports.defaultBannerData = exports.createBanner = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultBannerData = exports.defaultBannerData = {
  id: 0,
  enabled: true,
  name: '',
  desktopImage: '',
  mobileImage: '',
  description: '',
  priority: 0,
  validFrom: undefined,
  validTill: undefined,
  link: undefined,
  page: undefined,
  params: undefined,
  categoryId: null,
  category: {
    id: undefined
  },
  productId: null,
  product: {
    id: undefined
  }
};
const getBanners = params => (0, _request.default)({
  url: '/banners',
  method: 'get',
  params
});
exports.getBanners = getBanners;
const getBannerById = id => (0, _request.default)({
  url: `/banners/${id}`,
  method: 'get'
});
exports.getBannerById = getBannerById;
const updateBanner = (id, data) => (0, _request.default)({
  url: `/banners/${id}`,
  method: 'patch',
  data
});
exports.updateBanner = updateBanner;
const deleteBanner = id => (0, _request.default)({
  url: `/banners/${id}`,
  method: 'delete'
});
exports.deleteBanner = deleteBanner;
const createBanner = data => (0, _request.default)({
  url: '/banners/',
  method: 'post',
  data
});
exports.createBanner = createBanner;