"use strict";

var _interopRequireDefault = require("D:/eiosys-work/eiosys-frontend/Cona-Admin/Cona-Electrical-Admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _transporters = require("@/api/transporters");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _index4 = _interopRequireDefault(require("@/components/Inr/index.vue"));
var _admin = require("@/store/modules/admin");
var _cities = require("@/api/locations/cities");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.isReadOnly = false;
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.cityList = [];
    this.cityListLoading = false;
    this.listLoading = true;
    this.downloadLoading = false;
    this.listQuery = {
      enabled: true,
      page: 1,
      limit: 10,
      sort: 'id,ASC',
      name: undefined,
      gstNumber: undefined,
      cityId: undefined,
      filter: {
        name: '$contL',
        gstNumber: '$contL',
        cityId: '$eq',
        enabled: '$eq'
      }
    };
    this.sortOptions = [{
      label: 'ID Ascending',
      key: 'id,ASC'
    }, {
      label: 'ID Descending',
      key: 'id,DESC'
    }];
    this.enableTypeOptions = [{
      key: true,
      value: 'Enable'
    }, {
      key: false,
      value: 'Disabled'
    }];
  }
  activated() {
    if (_admin.AdminModule.roles.includes('Read Only')) {
      this.isReadOnly = true;
    }
    this.getList();
    this.getCityList();
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _transporters.getTransporters)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getCityList(query = null) {
    try {
      if (query && query.length > 1) {
        this.cityListLoading = true;
        this.cityList = await (0, _cities.getAllCities)((0, _index2.getQuery)({
          name: query,
          filter: {
            name: '$contL'
          }
        }));
      }
      this.cityListLoading = false;
    } catch (error) {
      this.cityListLoading = false;
    }
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    this.handleFilter();
  }
  handleDownload() {
    this.downloadLoading = true;
    const tHeader = ['id', 'fullname', 'email', 'contact', 'role'];
    const filterVal = ['id', 'fullName'];
    const data = (0, _index2.formatJson)(filterVal, this.list);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc('route.userManagement.users.title'));
    this.downloadLoading = false;
  }
  async handleExcelDownload() {
    this.downloadLoading = false;
    try {
      const queryWithoutPagination = {
        ...this.listQuery
      };
      delete queryWithoutPagination.page;
      delete queryWithoutPagination.limit;
      const blob = await (0, _transporters.downloadExcel)(queryWithoutPagination);
      // Create a blob URL for the Excel file
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'transporters.xlsx');
      document.body.appendChild(link);
      // Simulate click to trigger download
      link.click();
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading Excel:', error);
      this.$message.error('Error downloading Excel');
    } finally {
      // Reset loading state
      this.downloadLoading = false;
    }
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'BrandList',
  components: {
    Pagination: _index.default,
    Enabled: _index3.default,
    Inr: _index4.default
  }
})], default_1);
var _default = exports.default = default_1;